import React from "react";
import Title from "../../components/typography/title/Title";
import SimpleButton from "../../components/simpleButton/SimpleButton";
import Container from "../../components/container/Container";
import { Col, Row } from "antd";
import blueLineImage from "../../assets/images/bluelines.png";
import CountdownTimer from "../../components/countdownTimer/CountdownTimer";
// import { createCountdownBar } from "countdownbar";

const OfferBanner = (props) => {
  const { data } = props;

  // const countdownBarInstance = createCountdownBar({
  //   container: "#countdown-bar-container",
  //   time: 720 * 60 * 60 * 1000,
  // });

  const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  return (
    <div
      className="offerBanner"
      style={{ backgroundImage: `url(${data.image})` }}
    >
      <Container>
        <Row>
          <Col md={{ span: 12, offset: 12 }}>
            <Title color={"white"} size={"25"} tag={"h4"} weight={700}>
              {data.preTitle}
            </Title>
            <div
              className="offer-box"
              style={{ backgroundImage: `url(${blueLineImage})` }}
            >
              <Title color={"white"} size={"117"} tag={"h2"} weight={700}>
                {data.title}
              </Title>
            </div>
            <Title color={"white"} size={"25"} tag={"h4"} weight={700}>
              {data.postTitle}
            </Title>

            {/* <div id="countdown-bar-container"></div> */}
            <CountdownTimer targetDate={dateTimeAfterThreeDays} />

            <SimpleButton
              to={data.link}
              size={"small"}
              bgColor={"white"}
              color={"black"}
            >
              <Title size={"18"} tag={"h6"} weight={700}>
                Shop Now
              </Title>
            </SimpleButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OfferBanner;
