import { get, post } from "../../utils/httpService";

const SERVICE_URLS = {
  postLogin: () => `/client/login`,
  getUser: () => `/user/me`,
  postSignUp: () => `/client/register`,
  postUpdate: () => `/update-default-password`,
  postChangePassword: () => `/forgot-password`,
  postResetPassword: () => `/forgot-password-update`,
};

export const postLogin = (body, params) => {
  return post(SERVICE_URLS.postLogin(), body, params);
};

export const getUser = (body, params) => {
  return get(SERVICE_URLS.getUser(), body, params);
};

export const postSignUp = (body, params) => {
  return post(SERVICE_URLS.postSignUp(), body, params);
};

export const postUpdate = (body, params) => {
  return post(SERVICE_URLS.postUpdate(), body, params);
};

export const postChangePassword = (body, params) => {
  return post(SERVICE_URLS.postChangePassword(), body, params);
};

export const postResetPassword = (body, params) => {
  return post(SERVICE_URLS.postResetPassword(), body, params);
};
