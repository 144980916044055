import React, { useState, useEffect } from "react";
import "./ProductColor.css";
import { Button } from "antd";
import sign from "../assets/noparking.png";

const hexToRgba = (hex, alpha = 1) => {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);

  // 6 digits
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return { r, g, b, a: alpha };
};


const ImagePaletteBox = ({ onColorChange, onClose, setSingleColor, handleColorReset }) => {
  const [currentColor, setCurrentColor] = useState(null);
  const [preDefinedColors, setPreDefinedColors] = useState([]);

  useEffect(() => {
    fetch('https://backend.inkox.com/api/inkcolors')
      .then((response) => response.json())
      .then((data) => {
        // Flatten the hex color array from the API response
        const allColors = data.map((item) => item.color_hex.split(',')).flat().map(color => color.trim());

        // Convert hex colors to RGBA
        const rgbaColors = allColors.map(hex => hexToRgba(hex));

        // Update the state with the RGBA colors
        setPreDefinedColors(rgbaColors);
      })
      .catch((error) => {
        console.error('Error fetching outline colors:', error);
      });
  }, []);

  const handleColorChange = (color) => {
    setCurrentColor(color);
    onColorChange(color);
    setSingleColor(color);
  };

  const rgbToHex = (color) => {
    if (!color || color.r === undefined || color.g === undefined || color.b === undefined) {
      return "#000000"; // Return a default color if undefined
    }
    const { r, g, b } = color;
    const toHex = (value) => {
      const hex = value.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  return (
    <div className="color-picker">
      <label>Select Image Color</label>
      <div className="color-grid">
        {preDefinedColors.map((color, index) => (
          <div
            key={index}
            className={`color-box ${
              currentColor && rgbToHex(color) === rgbToHex(currentColor)
                ? "selected"
                : ""
            }`}
            style={{
              backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            }}
            onClick={() => handleColorChange(color)}
          >
            {currentColor && rgbToHex(color) === rgbToHex(currentColor) && (
              <span className="tick-mark">&#10003;</span>
            )}
            {color.a === 0 && (
              <img src={sign} style={{ height: "14px", width: "11px" }} />
            )}
          </div>
        ))}
      </div>
      <span><Button className="px-5 mt-3 me-3" onClick={onClose}>Done</Button></span>
      <span><Button className="px-5 mt-3 me-3" onClick={handleColorReset}>Reset</Button></span>
    </div>
  );
};

export default ImagePaletteBox;
