import React from "react";
import Title from "../typography/title/Title";

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? "countdown danger" : "countdown"}>
      <Title size={"46"} tag={"h3"} weight={700}>
        {value < 10 ? `0${value}` : value}
      </Title>
      <Title size={"18"} tag={"h6"} weight={700}>
        {type}
      </Title>
    </div>
  );
};

export default DateTimeDisplay;
