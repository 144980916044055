import React from "react";

const Title = (props) => {
  const { size, weight, color, tag, children, customClass } = props;

  const filterChildren =
    typeof children == "object"
      ? children.join(",").replace(/,/g, "").split()
      : children;

  const renderTitle = () => {
    switch (tag) {
      case "h1":
        return (
          <h1
            className={`title ${
              customClass && customClass
            } fs-${size} text-${color} fw-${weight}`}
            dangerouslySetInnerHTML={{ __html: filterChildren }}
          />
        );
      case "h2":
        return (
          <h2
            className={`title ${
              customClass && customClass
            } fs-${size} text-${color} fw-${weight}`}
            dangerouslySetInnerHTML={{ __html: filterChildren }}
          />
        );
      case "h3":
        return (
          <h3
            className={`title ${
              customClass && customClass
            } fs-${size} text-${color} fw-${weight}`}
            dangerouslySetInnerHTML={{ __html: filterChildren }}
          />
        );
      case "h4":
        return (
          <h4
            className={`title ${
              customClass && customClass
            } fs-${size} text-${color} fw-${weight}`}
            dangerouslySetInnerHTML={{ __html: filterChildren }}
          />
        );
      case "h5":
        return (
          <h5
            className={`title ${
              customClass && customClass
            } fs-${size} text-${color} fw-${weight}`}
            dangerouslySetInnerHTML={{ __html: filterChildren }}
          />
        );
      case "h6":
        return (
          <h6
            className={`title ${
              customClass && customClass
            } fs-${size} text-${color} fw-${weight}`}
            dangerouslySetInnerHTML={{ __html: filterChildren }}
          />
        );
      default:
        return (
          <h1
            className={`title ${
              customClass && customClass
            } fs-${size} text-${color} fw-${weight}`}
            dangerouslySetInnerHTML={{ __html: filterChildren }}
          />
        );
    }
  };
  return renderTitle();
};

export default Title;
