import React, { useEffect, useState } from 'react';
import './ProductColor.css'; // Import CSS file for styling

const OutlineSettings = ({
  outlineWidth,
  onOutlineWidthChange,
  onOutlineColorChange,
  onClose,
  outlineColor,
}) => {
  const [localOutlineWidth, setLocalOutlineWidth] = useState(outlineWidth);
  const [preDefinedColors, setPreDefinedColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState(outlineColor);

  useEffect(() => {
    // Fetch colors from the new API and transform them into the required format
    fetch('https://backend.inkox.com/api/inkcolors')
      .then((response) => response.json())
      .then((data) => {
        // Transform the data to match the expected 'inkColors' array format
        const colors = data.flatMap(item => 
          item.color_hex.split(',').map(color => color.trim())
        );
        setPreDefinedColors(colors);
      })
      .catch((error) => {
        console.error('Error fetching the color data:', error);
      });
  }, []);

  const handleOutlineColorChange = (color) => {
    setSelectedColor(color);
    onOutlineColorChange(color);
    if (color === 'none') {
      // If "None" is selected, set outline width to 0
      handleOutlineWidthChange(0);
    }
  };

  const handleOutlineWidthChange = (width) => {
    setLocalOutlineWidth(width);
    onOutlineWidthChange(width);
  };

  return (
    <div className="outline-settings">
      <div className='inner'>
        <label>Outline Width:</label>
      </div>
      <div className='inner'>
        <input
          className='range-bar'
          type="range"
          min="0"
          max="10"
          value={localOutlineWidth}
          onChange={(e) => handleOutlineWidthChange(parseInt(e.target.value))}
          disabled={selectedColor === 'none'} // Disable width slider if 'None' is selected
        />
      </div>
      <div className='inner'>
        <label>Select Outline Color:</label>
      </div>
      <div className='inner'>
        <div className="color-grid">
          {/* Predefined Colors */}
          {preDefinedColors.map((color) => (
            <div
              key={color}
              className={`color-box ${color === selectedColor ? 'selected' : ''}`}
              style={{ backgroundColor: color }}
              onClick={() => handleOutlineColorChange(color)}
            />
          ))}
        </div>
      </div>
      <div className='inner'>
        <button
          className={`donebutton none-button ${selectedColor === 'none' ? 'selected' : ''}`}
          onClick={() => handleOutlineColorChange('none')}
        >
          None
        </button>
        <button onClick={onClose} className='donebutton'>Done</button>
      </div>
    </div>
  );
};

export default OutlineSettings;
