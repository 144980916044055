import React from "react";
import Title from "../typography/title/Title";
import Description from "../typography/description/Description";

const BrandCard = (props) => {
  const { data } = props;
  return (
    <div className="brandCard">
      <div className="card-image">
        <img src={data?.image} alt="card-img" />
      </div>
      <div className="card-content">
        {data?.title && (
          <Title customClass={"card-title"} size={"17"} tag={"h5"} weight={700}>
            {data?.title}
          </Title>
        )}
        {data?.description && <Description>{data?.description}</Description>}
      </div>
    </div>
  );
};

export default BrandCard;
