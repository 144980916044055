class Point {
    constructor(x = 0, y = 0) {
        if (typeof x !== 'number' || typeof y !== 'number') {
            throw new Error("Invalid points");
        }
        this._props = [x || 0, y || 0];
        this[0] = x || 0;
        this[1] = y || 0;
    }

    set x(value) {
        this[0] = value;
        this._props[0] = value;
    }

    set y(value) {
        this[1] = value;
        this._props[1] = value;
    }

    get x() {
        return this._props[0];
    }

    get y() {
        return this._props[1];
    }
}

class Rectangle {
    constructor(x, y, width, height) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.top = y;
        this.bottom = y + height;
        this.left = x;
        this.right = x + width;
    }
}

class Parameters {
    constructor(options) {
        this.type = options.type || 0;
        this.bend = options.bend || 0;
        this.distortV = options.distortV || 0;
        this.distortH = options.distortH || 0;
        this.vertical = options.vertical || false;
    }
}

class Warp {
    constructor(t, s) {
        this.source = s;
        this.A = new Point(s.left, s.top);
        this.B = new Point(s.right, s.top);
        this.C = new Point(s.right, s.bottom);
        this.D = new Point(s.left, s.bottom);
        this.Aleft = new Point(s.left, s.top + s.height / 3);
        this.Atop = new Point(s.left + s.width / 3, s.top);
        this.Btop = new Point(s.right - s.width / 3, s.top);
        this.Bright = new Point(s.right, s.top + s.height / 3);
        this.Cright = new Point(s.right, s.bottom - s.height / 3);
        this.Cbottom = new Point(s.right - s.width / 3, s.bottom);
        this.Dbottom = new Point(s.left + s.width / 3, s.bottom);
        this.Dleft = new Point(s.left, s.bottom - s.height / 3);
        this.ABx = 0;
        this.ABy = 0;
        this.CDx = 0;
        this.CDy = 0;
        this.BCx = 0;
        this.BCy = 0;
        this.ADx = 0;
        this.ADy = 0;
        this.STEPS = 10;
        this.distortX = 0;
        this.distortY1 = 0;
        this.distortY2 = 0;
        this.T1 = 1 / 3;
        this.T2 = 2 / 3;
        this.kAB = 0;
        this.kBC = 0;
        this.kCD = 0;
        this.kAD = 0;
        this.applyParameters(t);
    }

    applyParameters(t) {
        const s = this.source;
        const i = this._parameters = new Parameters(t);
        this.angle = i.bend * Math.PI / 2;
        this.sin = Math.sin(this.angle);
        this.cos = Math.cos(this.angle);
        this.kt = 1 + Math.pow(i.bend, 4);
        this.distortX = 0.5 * i.distortV * s.width;
        const h = 0.5 * i.distortH * s.height;
        this.distortY1 = h * (1 - i.distortV);
        this.distortY2 = h * (1 + i.distortV);
        this.kAB = 1 - i.distortV;
        this.kBC = 1 + i.distortH;
        this.kCD = 1 + i.distortV;
        this.kAD = 1 - i.distortH;
        this.setAnchors();
        switch (i.type) {
            case "WARP_ARC":
                this.setArc();
                break;
            case "WARP_ARC_LOWER":
                this.setArcLower();
                break;
            case "WARP_ARC_UPPER":
                this.setArcUpper();
                break;
            case "WARP_ARCH":
                this.setArch();
                break;
            case "WARP_BULGE":
                this.setBulge();
                break;
            case "WARP_FLAG":
                this.setFlag();
                break;
            case "WARP_FISH":
                this.setFish();
                break;
            case "WARP_RISE":
                this.setRise();
                break;
            case "WARP_INFLATE":
                this.setInflate();
                break;
            case "WARP_SQUEEZE":
                this.setSqueeze();
                break;
            case "WARP_WAVE_LOWER":
                this.setWaveLower();
                break;
            case "WARP_WAVE_UPPER":
                this.setWaveUpper();
                break;
        }
    }

    setAnchors() {
        this.A.x = this.source.left + this.distortX;
        this.B.x = this.source.right - this.distortX;
        this.C.x = this.source.right + this.distortX;
        this.D.x = this.source.left - this.distortX;
        this.A.y = this.source.top + this.distortY1;
        this.B.y = this.source.top - this.distortY1;
        this.C.y = this.source.bottom + this.distortY2;
        this.D.y = this.source.bottom - this.distortY2;
    }

    setControls() {
        this.Atop.x = this.A.x + this.ABx;
        this.Atop.y = this.A.y + this.ABy;
        this.Btop.x = this.B.x - this.ABx;
        this.Btop.y = this.B.y - this.ABy;
        this.Dbottom.x = this.D.x + this.CDx;
        this.Dbottom.y = this.D.y + this.CDy;
        this.Cbottom.x = this.C.x - this.CDx;
        this.Cbottom.y = this.C.y - this.CDy;
        this.Bright.x = this.B.x + this.BCx;
        this.Bright.y = this.B.y + this.BCy;
        this.Cright.x = this.C.x - this.BCx;
        this.Cright.y = this.C.y - this.BCy;
        this.Aleft.x = this.A.x + this.ADx;
        this.Aleft.y = this.A.y + this.ADy;
        this.Dleft.x = this.D.x - this.ADx;
        this.Dleft.y = this.D.y - this.ADy;
    }

    changeCubic(t, s, i, h) {
        const e = this._changePoint(t);
        const o = this._changePoint(h);
        const r = this.getPointOn(1 / 3, t, h, s, i);
        const n = this.getPointOn(2 / 3, t, h, s, i);
        const a = this._changePoint(r);
        const y = this._changePoint(n);
        return this.getNewSegment(e, o, a, y);
    }

    changePoint(t) {
        const s = this._changePoint(t);
        return [s.x, s.y];
    }

    _changePoint(t) {
        const s = this.A;
        const i = this.Atop;
        const e = this.Aleft;
        const o = this.B;
        const r = this.Btop;
        const n = this.Bright;
        const a = this.C;
        const y = this.Cright;
        const x = this.Cbottom;
        const c = this.D;
        const A = this.Dbottom;
        const C = this.Dleft;
        const d = (t.x - this.source.x) / this.source.width;
        const b = (t.y - this.source.y) / this.source.height;
        const p = 1 - b;
        const B = this.getPointOn(b, s, c, e, C);
        const l = this.getPointOn(b, o, a, n, y);
        const D = new Point(i.x * p + A.x * b, i.y * p + A.y * b);
        const m = new Point(r.x * p + x.x * b, r.y * p + x.y * b);
        const k = this.getPointOn(d, B, l, D, m);
        return new Point(k.x, k.y);
    }

    getPointOn(t, s, i, e, o) {
        const r = new Point();
        const n = t * t * t;
        const a = t * t;
        r.x = n * (i.x + 3 * (e.x - o.x) - s.x) + 3 * a * (s.x - 2 * e.x + o.x) + 3 * t * (e.x - s.x) + s.x;
        r.y = n * (i.y + 3 * (e.y - o.y) - s.y) + 3 * a * (s.y - 2 * e.y + o.y) + 3 * t * (e.y - s.y) + s.y;
        return r;
    }

    setArc() {
        const t = this._parameters;
        const s = this.source;
        const i = 1 - t.distortV;
        if (t.bend > 0) {
            this.A.x -= i * (s.height - this.distortY1) * this.sin;
            this.B.x += i * (s.height + this.distortY1) * this.sin;
            this.A.y += (s.height - this.distortY1) * (1 - this.cos);
            this.B.y += (s.height + this.distortY1) * (1 - this.cos);
            this.C.x -= this.distortY2 * this.sin;
            this.D.x -= this.distortY2 * this.sin;
            this.C.y += this.distortY2 * (this.cos - 1);
            this.D.y -= this.distortY2 * (this.cos - 1);
        } else if (t.bend < 0) {
            this.D.x += i * (s.height - this.distortY2) * this.sin;
            this.C.x -= i * (s.height + this.distortY2) * this.sin;
            this.D.y += (s.height - this.distortY2) * (this.cos - 1);
            this.C.y += (s.height + this.distortY2) * (this.cos - 1);
            this.A.x += this.distortY1 * this.sin;
            this.B.x += this.distortY1 * this.sin;
            this.A.y -= this.distortY1 * (1 - this.cos);
            this.B.y += this.distortY1 * (1 - this.cos);
        }
        this.calculateArms();
        this.setControls();
        this.Atop.x += this.ABx * (this.cos * this.kt - 1);
        this.Atop.y -= this.ABx * this.sin * this.kt;
        this.Btop.x += this.ABx * (1 - this.cos * this.kt);
        this.Btop.y -= this.ABx * this.sin * this.kt;
        this.Dbottom.x += this.CDx * (this.cos * this.kt - 1);
        this.Dbottom.y -= this.CDx * this.sin * this.kt;
        this.Cbottom.x += this.CDx * (1 - this.cos * this.kt);
        this.Cbottom.y -= this.CDx * this.sin * this.kt;
    }

    setFish() {
        const t = this._parameters;
        this.calculateArms();
        this.setControls();
        this.Atop.y -= 2 * t.bend * this.kAB * (2 * this.ADy + this.BCy);
        this.Btop.y += 2 * t.bend * this.kAB * (this.ADy + 2 * this.BCy);
        this.Dbottom.y += 2 * t.bend * this.kCD * (2 * this.ADy + this.BCy);
        this.Cbottom.y -= 2 * t.bend * this.kCD * (this.ADy + 2 * this.BCy);
    }

    setFlag() {
        this.calculateArms();
        this.setControls();
        const t = this._parameters;
        this.Atop.y += 3 * t.bend * this.kAB * (this.ADy + this.BCy);
        this.Btop.y -= 3 * t.bend * this.kAB * (this.ADy + this.BCy);
        this.Dbottom.y += 3 * t.bend * this.kCD * (this.ADy + this.BCy);
        this.Cbottom.y -= 3 * t.bend * this.kCD * (this.ADy + this.BCy);
    }

    setBulge() {
        this.calculateArms();
        this.setControls();
        const parameters = this._parameters;
        this.Atop.x += this.ABx * (this.cos * this.kt - 1);
        this.Atop.y -= this.ABx * this.sin * this.kt;
        this.Btop.x += this.ABx * (1 - this.cos * this.kt);
        this.Btop.y -= this.ABx * this.sin * this.kt;
        this.Dbottom.x += this.CDx * (this.cos * this.kt - 1);
        this.Dbottom.y += this.CDx * this.sin * this.kt;
        this.Cbottom.x += this.CDx * (1 - this.cos * this.kt);
        this.Cbottom.y += this.CDx * this.sin * this.kt;
    }

    setArch() {
        this.calculateArms();
        this.setControls();
        const parameters = this._parameters;
        this.Atop.x += this.ABx * (this.cos * this.kt - 1);
        this.Atop.y -= this.ABx * this.sin * this.kt;
        this.Btop.x += this.ABx * (1 - this.cos * this.kt);
        this.Btop.y -= this.ABx * this.sin * this.kt;
        this.Dbottom.x += this.CDx * (this.cos * this.kt - 1);
        this.Dbottom.y -= this.CDx * this.sin * this.kt;
        this.Cbottom.x += this.CDx * (1 - this.cos * this.kt);
        this.Cbottom.y -= this.CDx * this.sin * this.kt;
    }

setArcLower() {
    this.calculateArms();
    this.setControls();
    const parameters = this._parameters;
    this.Dbottom.x += this.CDx * (this.cos * this.kt - 1);
    this.Dbottom.y += this.CDx * this.sin * this.kt;
    this.Cbottom.x += this.CDx * (1 - this.cos * this.kt);
    this.Cbottom.y += this.CDx * this.sin * this.kt;
}

setArcUpper() {
    this.calculateArms();
    this.setControls();
    const parameters = this._parameters;
    this.Atop.x += this.ABx * (this.cos * this.kt - 1);
    this.Atop.y -= this.ABx * this.sin * this.kt;
    this.Btop.x += this.ABx * (1 - this.cos * this.kt);
    this.Btop.y -= this.ABx * this.sin * this.kt;
}
    setSqueeze() {
        this.calculateArms();
        this.setControls();
        const t = this._parameters;
        this.Atop.y -= t.bend * this.kAB * this.ADy;
        this.Btop.y -= t.bend * this.kAB * this.BCy;
        this.Dbottom.y += t.bend * this.kCD * this.ADy;
        this.Cbottom.y += t.bend * this.kCD * this.BCy;
        this.Bright.x -= t.bend * this.kBC * this.ABx;
        this.Cright.x -= t.bend * this.kBC * this.CDx;
        this.Aleft.x += t.bend * this.kAD * this.ABx;
        this.Dleft.x += t.bend * this.kAD * this.CDx;
    }

    setInflate() {
        this.calculateArms();
        this.setControls();
        const t = this._parameters;
        const s = (this.ADy + this.BCy) / 2;
        const i = (this.ABx + this.CDx) / 2;
        this.Atop.y -= t.bend * this.kAB * s;
        this.Btop.y -= t.bend * this.kAB * s;
        this.Dbottom.y += t.bend * this.kCD * s;
        this.Cbottom.y += t.bend * this.kCD * s;
        this.Bright.x += t.bend * this.kBC * i;
        this.Cright.x += t.bend * this.kBC * i;
        this.Aleft.x -= t.bend * this.kAD * i;
        this.Dleft.x -= t.bend * this.kAD * i;
    }

    setRise() {
        const t = this._parameters;
        this.A.y += t.bend * this.kAB * this.source.height;
        this.D.y += t.bend * this.kCD * this.source.height;
        this.B.y -= t.bend * this.kAB * this.source.height;
        this.C.y -= t.bend * this.kCD * this.source.height;
        this.calculateArms();
        this.setControls();
        const s = this.ADy + this.BCy;
        this.Atop.y += t.bend * this.kAB * s;
        this.Btop.y -= t.bend * this.kAB * s;
        this.Dbottom.y += t.bend * this.kCD * s;
        this.Cbottom.y -= t.bend * this.kCD * s;
    }

    setWaveUpper() {
        this.calculateArms();
        this.setControls();
        const t = this._parameters;
        this.Atop.y += 3 * t.bend * this.kAB * (this.ADy + this.BCy);
        this.Btop.y -= 3 * t.bend * this.kAB * (this.ADy + this.BCy);
    }

    setWaveLower() {
        this.calculateArms();
        this.setControls();
        const t = this._parameters;
        this.Dbottom.y += 3 * t.bend * this.kCD * (this.ADy + this.BCy);
        this.Cbottom.y -= 3 * t.bend * this.kCD * (this.ADy + this.BCy);
    }

    calculateArms() {
        this.ABx = (this.B.x - this.A.x) / 3;
        this.ABy = (this.B.y - this.A.y) / 3;
        this.CDx = (this.C.x - this.D.x) / 3;
        this.CDy = (this.C.y - this.D.y) / 3;
        this.BCx = (this.C.x - this.B.x) / 3;
        this.BCy = (this.C.y - this.B.y) / 3;
        this.ADx = (this.D.x - this.A.x) / 3;
        this.ADy = (this.D.y - this.A.y) / 3;
    }

    getNewSegment(t, s, i, e) {
        const o = this.T1;
        const r = o * o * o;
        const n = 1 - o;
        const a = n * n * n;
        const y = this.T2;
        const x = y * y * y;
        const c = 1 - y;
        const A = c * c * c;
        const C = 3 * o * n;
        const d = {};
        d.x = (i.x - a * t.x - r * s.x) / C;
        d.y = (i.y - a * t.y - r * s.y) / C;
        const b = 3 * y * c;
        const p = {};
        p.x = (e.x - A * t.x - x * s.x) / b;
        p.y = (e.y - A * t.y - x * s.y) / b;
        const B = {};
        B.x = (y * d.x - o * p.x) / (n * p.x - c * d.x);
        B.y = (y * d.y - o * p.y) / (n * p.y - c * d.y);
        if (isNaN(B.x)) B.x = 0;
        if (isNaN(B.y)) B.y = 0;
        const l = {};
        const D = {};
        D.x = d.x / (n * B.x + o);
        D.y = d.y / (n * B.y + o);
        l.x = B.x * D.x;
        l.y = B.y * D.y;
        const m = new Point(l.x, l.y);
        const k = new Point(D.x, D.y);
        return [m.x, m.y, k.x, k.y, s.x, s.y];
    }
}

class Path {
    constructor(t) {
        this.data = [];
        this.commands = [];
        this.parseString(t);
    }

    parseString(t) {
        const s = [];
        const i = [];
        t.match(/[A-Za-z][0-9\.\s\,\-]+\d/g).forEach(t => {
            const h = t[0];
            const e = t.match(/[\d\.\-]+/g);
            s.push(h);
            i.push(...e.map(parseFloat));
        });
        this.commands = s;
        this.data = i;
    }

    scale(t, s = 0) {
        if (s === 0) s = t;
        const i = this.data;
        for (let h = 0; h < i.length; h += 2) {
            i[h] *= t;
            i[h + 1] *= s;
        }
    }

    warp(t) {
        const s = new Warp(t, this.calcRectangle());
        const i = this.commands;
        const o = this.data;
        let n = [];
        let a = new Point();
        let y = new Point();
        let c = new Point();
        let A = new Point();
        let C = 0;
        let d = new Point();
        for (let t = 0; t < i.length; t++) {
            switch (i[t]) {
                case "L":
                    i[t] = "C";
                    a = new Point((2 * A[0] + o[C]) / 3, (2 * A[1] + o[C + 1]) / 3);
                    y = new Point((A[0] + 2 * o[C]) / 3, (A[1] + 2 * o[C + 1]) / 3);
                    d = new Point(o[C], o[C + 1]);
                    n = n.concat(s.changeCubic(A, a, y, d));
                    A = d;
                    C += 2;
                    break;
                case "Q":
                    i[t] = "C";
                    a = new Point((2 * o[C] + A.x) / 3, (2 * o[C + 1] + A.y) / 3);
                    y = new Point((2 * o[C] + o[C + 2]) / 3, (2 * o[C + 1] + o[C + 3]) / 3);
                    d = new Point(o[C + 2], o[C + 3]);
                    n = n.concat(s.changeCubic(A, a, y, d));
                    A = d;
                    C += 4;
                    break;
                case "M":
                    A = c = new Point(o[C], o[C + 1]);
                    n = n.concat(s.changePoint(A));
                    C += 2;
                    break;
                case "C":
                    n = n.concat(s.changeCubic(A, new Point(o[C], o[C + 1]), new Point(o[C + 2], o[C + 3]), new Point(o[C + 4], o[C + 5])));
                    A = new Point(o[C + 4], o[C + 5]);
                    C += 6;
                    break;
                case "Z":
                    A = c;
                    break;
            }
        }
        this.data = n;
    }

    output() {
        const t = [];
        const s = this.data.slice();
        for (let i = 0; i < this.commands.length; i++) {
            const h = this.commands[i];
            let e = 0;
            switch (h) {
                case "M":
                case "L":
                    e = 2;
                    break;
                case "Q":
                    e = 4;
                    break;
                case "C":
                    e = 6;
                    break;
            }
            t.push(h + s.splice(0, e).join(" "));
        }
        return t.join(" ");
    }


    calcRectangle() {
        const t = this.commands;
        const s = this.data;
        const e = {
            xMin: Infinity,
            yMin: Infinity,
            xMax: -Infinity,
            yMax: -Infinity
        };
        let x = 0;
        for (let c = 0; c < t.length; c++) {
            let i, h;
            switch (t[c]) {
                case "M":
                case "L":
                    i = s[x];
                    h = s[x + 1];
                    x += 2;
                    break;
                case "Q":
                    i = s[x + 2];
                    h = s[x + 3];
                    x += 4;
                    break;
                case "C":
                    i = s[x + 4];
                    h = s[x + 5];
                    x += 6;
                    break;
            }
            e.xMin = Math.min(i, e.xMin);
            e.xMax = Math.max(i, e.xMax);
            e.yMin = Math.min(h, e.yMin);
            e.yMax = Math.max(h, e.yMax);
        }
        return e.xMax === -Infinity ? null : new Rectangle(e.xMin, e.yMin, e.xMax - e.xMin, e.yMax - e.yMin);
    }
}

export default Path;