import React from "react";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import ProfileForm from "../components/forms/profileForm/ProfileForm";

const UpdateProfile = () => {
  return (
    <LayoutWrapper>
      <ProfileForm />
    </LayoutWrapper>
  );
};

export default UpdateProfile;
