import React, {useState} from "react";
import "./agreement.css"; // Importing the updated CSS
import Image from './assets/image.jpg';
import Breadcrumb from "./Breadcrumb.js";
import Sidebar from "./Sidebar.js";
import "./App.css";
import LayoutWrapper from "../../layout/layoutWrapper/LayoutWrapper.jsx";
import { Link } from "react-router-dom";
function Agreement() {
    const [openSections, setOpenSections] = useState(["about", "agreement", "policy", "solution"]);

    const toggleSection = (section) => {
      setOpenSections((prevOpenSections) => {
        if (prevOpenSections.includes(section)) {
          return prevOpenSections.filter((s) => s !== section);
        } else {
          return [...prevOpenSections, section];
        }
      });
    };
  
    const renderIcon = (isOpen) => (
      <svg
        className="mui-icon"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ExpandMoreIcon"
        style={{
          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
        }}
      >
        <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </svg>
    );
  
    return (
        <LayoutWrapper>
        <div className="main-div-other-pages">
              <div className="container">
             <Breadcrumb />
            <div className="inkox-container">
            <Sidebar
              openSections={openSections}
              toggleSection={toggleSection}
              renderIcon={renderIcon}
            />
                {/* Main Content */}
                <div className="inkox-main-content">
                    <h1 style={{ color: "#444444 !important", fontSize: "24px" }}>User Agreement & Policies for INKOX.com</h1>

                    <h3>What This Agreement Covers</h3>
                    <p>
                        This Agreement oversees your use of the INKOX.com site and the associated services ("Service"). The Service enables customers with an appropriate relationship with institutions of higher learning, corporations, small businesses, schools, or other organizations to design and purchase custom merchandise. By utilizing the Site and the Service, you are agreeing to these terms and conditions and commit to using the Site and Service strictly as outlined in this Agreement.
                    </p>

                    {/* <div className="inkox-image-section">
                        <img
                            src={Image}
                            alt="Customer Support"
                            className="inkox-support-image"
                        />
                    </div> */}

                    <h3>Intellectual Property Rights</h3>
                    <p>
                        INKOX.com respects and adheres to the lawful use of intellectual property and expects the same from its users. All content on the INKOX.com site, including images, designs, icons, and photographs, are protected intellectual properties owned, controlled, or licensed by INKOX.com.
                    </p>
                    <p>
                        INKOX.com holds the trademark for INKOX; other trademarks accessible on this Site, such as logos and slogans, are used under license from third parties ("Licensed Marks"). You agree to use these Licensed Marks only in ways directly related to such third parties and comply with any restrictions they impose.
                    </p>
                    <p>
                        Generally, products designed on our site are not meant for commercial resale. If you intend to resell such products, you must first verify the legality of doing so with INKOX.com. Additionally, we retain the rights to display, modify, or reuse designs submitted by users.
                    </p>
                    <p>
                        We reserve the right to refuse any order we deem inappropriate or offensive, including those that are libelous, obscene, or discriminatory.
                    </p>

                    <h3>Content Standards and Privacy</h3>
                    <p>Please review our detailed Content Standards and Privacy Policy.</p>

                    <h3>Usage Rights</h3>
                    <p>To understand your rights concerning the use of content on our site, please review our Usage Rights Policy.</p>

                    <h3>Ethical Manufacturing</h3>
                    <p>
                        INKOX.com commits to high standards for manufacturing conditions and expects the same from our suppliers. We assess vendors through referrals, reputation, and occasional inspections.
                    </p>

                    <h3>Payment and Order Policies</h3>
                    <p>
                        INKOX.com accepts payments via credit card, debit card, or electronic check (ACH) securely online or over the phone. Qualified customers may also pay via purchase order, with invoices payable by check or card.
                    </p>

                    <h3>Shipping and Returns</h3>
                    <p>
                        Our orders typically arrive within two weeks. Customers needing faster delivery can opt for rush delivery with additional charges. If you are dissatisfied with your order due to flawed items or subpar decoration quality, or if the final product significantly differs from your submitted design, returns and refunds are available.
                    </p>

                    <h3>Cancellation Policy</h3>
                    <p>
                        Changes to your order or cancellations are permissible only before production begins. Once production has started, we cannot accept changes or cancellations.
                    </p>

                    <h3>Limitation of Liability</h3>
                    <p>
                        INKOX.com is not liable for errors or delays that are not directly under our control, such as those caused by internet providers or software issues. We also disclaim responsibility for incorrect user instructions or failed transactions.
                    </p>

                    <h3>Intellectual Property Claims</h3>
                    <p>
                        INKOX respects the intellectual property rights of others. If you believe your rights are being infringed upon via our site, please contact Ashley Brown at:
                    </p>

                    <ul style={{lineHeight: "23px"}}>
                        <li style={{fontSize:"14px"}}><strong>Email:</strong> <a href="mailto:ip-notices@inkox.com">ip-notices@inkox.com</a></li><br />
                        <li style={{fontSize:"14px"}}><strong>Address:</strong> 2910 District Ave., Fairfax, VA 22031</li><br />
                        <li style={{fontSize:"14px"}}><strong>Tel:</strong> <a href="tel:703-891-2205">703-891-2205</a></li>
                    </ul>

                    <h3>Indemnity</h3>
                    <p>
                        You agree to defend, indemnify, and hold harmless INKOX.com and its affiliates from any claims, damages, or expenses arising from your use of the site or your breach of this Agreement.
                    </p>

                    <h3>Governing Law</h3>
                    <p>
                        This Agreement is governed by the laws of the Commonwealth of Virginia and applicable federal law.
                    </p>

                    <h4>Updated as of April 8, 2024</h4>
                </div>
            </div>

            <p className="inkox-divider" style={{ borderBottom: "1px solid #aaa" }}></p>

            <div className="inkox-browsebut" style={{ textAlign: "center" }}>
            <Link className="inkox-primary-button" to={`/products`}>Browse All of Our Products</Link>
                <button className="inkox-primary-button">Start Designing in the Lab</button>
            </div>
            </div>
        </div>
        </LayoutWrapper>
    );
}

export default Agreement;
