import React, { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import Container from "../../components/container/Container";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Col, Input, Row } from "antd";

const Breadcrumb = ({ search }) => {
  const params = useParams();
  const { Search } = Input;
  const category = params.category;
  const subcategory = params.subcategory;
  const name = params.name;
  const routes = [
    { path: "/", breadcrumb: "" },
    { path: "products", breadcrumb: "All Products" },
    { path: "/products/:category", breadcrumb: `${category}` },
    { path: "/products/:category/:subcategory", breadcrumb: `${subcategory}` },
    { path: "/products/:category/:subcategory/:name", breadcrumb: `${name}` },
  ];

  const onSearch = (value) => console.log(value);

  const options = {
    disableDefaults: true,
  };

  const breadcrumbs = useBreadcrumbs(routes, options);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const links = document.querySelectorAll(".breadcrumb-link");
      if (links?.length > 0) {
        const lastLink = links[links.length - 1];
        lastLink.classList.add("disabled");
      }
    }
  }, []);

  return (
    <div className="breadcrumb">
      <Container>
        <Row gutter={[20, 20]} justify={"space-between"} align={"middle"}>
          <Col xs={24} sm={24} md={24} lg={12}>
            {breadcrumbs.map(({ match, breadcrumb }, index) => {
              breadcrumb = breadcrumb.props.children.replace("-", " ");
              return (
                <>
                  <NavLink
                    className={"breadcrumb-link"}
                    key={match.pathname}
                    to={match.pathname}
                  >
                    {breadcrumb}
                  </NavLink>
                  {breadcrumbs.length > 1 &&
                    index < breadcrumbs.length - 1 &&
                    "-"}
                </>
              );
            })}
          </Col>
          {search && (
            <Col xs={24} sm={24} md={24} lg={8}>
              <Search
                className="custom-search"
                placeholder="search"
                onSearch={onSearch}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Breadcrumb;
