// Breadcrumb.js
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./breadcrumb.css";

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Mapping of path segments to display names
  const breadcrumbNameMap = {
    "": "Help Center", // root path
    about: "About",
    explore: "Explore the Fun",
    passion: "Inked with Passion",
    agreement: "User Agreement & Policies",
    story: "Our Story",
    policy: "Privacy Policy",
    solution: "Business Solutions"
  };

  return (
    <nav className="breadcrumb-nav">
      <Link to="/" className="breadcrumb-link">Help Center</Link>
      {pathnames.map((value, index) => {
        // Construct the path up to the current segment
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        
        // Retrieve the display name from the map, or fallback to a capitalized version of the segment
        const name = breadcrumbNameMap[value] || value.charAt(0).toUpperCase() + value.slice(1);

        return isLast ? (
          <span key={to} className="breadcrumb-link active">{name}</span>
        ) : (
          <Link key={to} to={to} className="breadcrumb-link">
            {name}
          </Link>
        );
      })}
    </nav>
  );
};

export default Breadcrumb;
