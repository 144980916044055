import { Col, Row } from "antd";
import React from "react";
import Container from "../../components/container/Container";
import Title from "../../components/typography/title/Title";
import Description from "../../components/typography/description/Description";

const TipOfTheDay = ({ data, bgColor }) => {
  return (
    <div
      className="tipOfTheDay"
      style={{ backgroundImage: `url(${data.image})` }}
    >
      <Container>
        <Row className="content-row" justify={"center"}>
          <Col
            xs={24}
            md={5}
            lg={6}
            style={bgColor && { backgroundColor: bgColor }}
          >
            <Title size={"42"} tag={"h2"} weight={700}>
              {data.title}
            </Title>
          </Col>
          <Col
            xs={24}
            md={10}
            lg={9}
            style={bgColor && { backgroundColor: bgColor }}
          >
            <Description customClass={"fs-14 fw-700"}>
              {data.description}
            </Description>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TipOfTheDay;
