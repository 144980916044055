import React, { useEffect } from 'react';
import { fabric } from 'fabric';
import useIconLoader from './UtilitiesBoundingBox';
import useCanvasEventHandlers from './CustomControlBoundingBox';
import { applyCustomControls } from './CustomControlBoundingBox';

const CustomBoundingBox = ({ controlSettings, canvas }) => {
  const icons = useIconLoader();
  const { hoverRectRef, pressedIconRef, handleMouseOver, handleMouseOut, handleMouseDown, handleMouseUp } = useCanvasEventHandlers(
    canvas,
    icons,
    controlSettings
  );

  useEffect(() => {
    if (icons.deleteIcon && icons.duplicateIcon && icons.resizeIcon) {
      applyCustomControls(canvas, icons, controlSettings, hoverRectRef, pressedIconRef);
      canvas.on('object:added', () => applyCustomControls(canvas, icons, controlSettings, hoverRectRef, pressedIconRef));
      canvas.on('selection:created', () => applyCustomControls(canvas, icons, controlSettings, hoverRectRef, pressedIconRef));
      canvas.on('selection:updated', () => applyCustomControls(canvas, icons, controlSettings, hoverRectRef, pressedIconRef));
      canvas.renderAll();

      return () => {
        canvas.off('object:added');
        canvas.off('selection:created');
        canvas.off('selection:updated');
      };
    }
  }, [icons, controlSettings, canvas]);

  return null;
};

export default CustomBoundingBox;