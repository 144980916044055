import React from "react";
import "./ProductColor.css"; // Create a CSS file for styling if needed

// Import images for each warp type
import warpArcImage from "../assets/arc.png";
import arcbulge from "../assets/arcbulge.png";
import arcfish from "../assets/arcfish.png";
import arch from "../assets/arch.png";
import arcinflate from "../assets/arcinflate.png";
import arclower from "../assets/arclower.png";
import arcrise from "../assets/arcrise.png";
import arcsqueeze from "../assets/arcsqueeze.png";
import arcupper from "../assets/arcupper.png";
import arcwave from "../assets/arcwave.png";
import arcwavelower from "../assets/arcwavelower.png";
import arcwaveupper from "../assets/arcwaveupper.png";
// Import other images for remaining warp types

const ArcTypeSelector = ({
  selectedArcType,
  onSelectArcType,
  onClose,
  bend,
  handleBendChange,
  horizontalDistort,
  handleHorizontalDistortChange,
  verticalDistort,
  handleVerticalDistortChange
}) => {
  // Map warp types to their corresponding images
  const arcTypeImages = {
    WARP_ARC: warpArcImage,
    WARP_ARC_LOWER: arclower,
    WARP_ARC_UPPER: arcupper,
    WARP_ARCH: arch,
    WARP_BULGE: arcbulge,
    WARP_FLAG: arcwave,      // Assuming "flag" uses arcwave image, adjust if needed
    WARP_FISH: arcfish,
    WARP_RISE: arcrise,
    WARP_INFLATE: arcinflate,
    WARP_SQUEEZE: arcsqueeze,
    WARP_WAVE_LOWER: arcwavelower,
    WARP_WAVE_UPPER: arcwaveupper,
  };

  const arcTypes = [
    "WARP_ARC",
    "WARP_ARC_LOWER",
    "WARP_ARC_UPPER",
    "WARP_ARCH",
    "WARP_BULGE",
    "WARP_FLAG",
    "WARP_FISH",
    "WARP_RISE",
    "WARP_INFLATE",
    "WARP_SQUEEZE",
    "WARP_WAVE_LOWER",
    "WARP_WAVE_UPPER",
  ];

  return (
    <div className="warp-type-container">
      <br />
      <label htmlFor="warp_type" className="selectarc">Select Arc Type:</label>
      <div id="warp_type" className="warp-type-options">
        {arcTypes.map((type) => (
          <div
            key={type}
            className={`warp-type-option ${selectedArcType === type ? "selected" : ""}`}
            onClick={() => onSelectArcType({ target: { value: type } })}
          >
            <input
              type="radio"
              id={type}
              name="warp_type"
              value={type}
              checked={selectedArcType === type}
              onChange={selectedArcType}
              style={{ display: "none" }}
            />
            <label htmlFor={type} style={{height: "auto", width:'100%'}}>
              {/* Display image for each warp type */}
              <img
                src={arcTypeImages[type]}
                alt={type}
                style={{ width: "100%", height: "auto", opacity:'.7' }}
              />
             
            </label>
          </div>
        ))}
      </div>

      <div className="inner">
        <label htmlFor="bend_control" className="labFont">Bend</label>
        <input className="range-bar"
          type="range"
          min="-100"
          step="50"
          value={bend}
          max="100"
          id="bend_control"
          onChange={handleBendChange}
        />
        <button className="resetbtn"
          value="bend_control"
          onClick={() => handleBendChange({ target: { value: 0 } })}
        >
          Reset
        </button>
      </div>

      <div className="inner distort-remove-cls">
        <label htmlFor="horizontal_control" className="labFont">Horizontal Distort</label>
        <input className="range-bar"
          type="range"
          min="-100"
          step="50"
          value={horizontalDistort}
          max="100"
          id="horizontal_control"
          onChange={handleHorizontalDistortChange}
        />
        <button className="resetbtn"
          value="horizontal_control"
          onClick={() => handleHorizontalDistortChange({ target: { value: 0 } })}
        >
          Reset
        </button>
      </div>

      <div className="inner distort-remove-cls">
        <label htmlFor="vertical_control" className="labFont">Vertical Distort</label>
        <input className="range-bar"
          type="range"
          min="-100"
          step="50"
          value={verticalDistort}
          max="100"
          id="vertical_control"
          onChange={handleVerticalDistortChange}
        />
        <button className="resetbtn"
          value="vertical_control"
          onClick={() => handleVerticalDistortChange({ target: { value: 0 } })}
        >
          Reset
        </button>
      </div>

      <div className="innerdone">
        <button onClick={onClose} className="button-to-text display-none-mobile">Done</button>
      </div>
    </div>
  );
};

export default ArcTypeSelector;
