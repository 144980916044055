import React, { useEffect } from 'react';
import { fabric } from 'fabric';

const DuplicateLogicComponent = ({ canvas }) => {
  useEffect(() => {
    const copyObjects = () => {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        window._clipboard = activeObject;
      }
    };

    const cutObjects = () => {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        window._clipboard = activeObject;
        canvas.remove(activeObject);
        canvas.discardActiveObject();
        canvas.requestRenderAll();
        canvas.fire('object:modified'); // Trigger price recalculation
      }
    };

    const pasteObjects = () => {
      if (window._clipboard) {
        window._clipboard.clone((clonedObj) => {
          canvas.discardActiveObject();
          clonedObj.set({
            left: clonedObj.left + 10,
            top: clonedObj.top + 10,
            evented: true,
            designElement: true,
          });
          if (clonedObj.type === 'activeSelection') {
            clonedObj.canvas = canvas;
            clonedObj.forEachObject((obj) => canvas.add(obj));
            clonedObj.setCoords();
          } else {
            canvas.add(clonedObj);
          }
          canvas.setActiveObject(clonedObj);
          canvas.requestRenderAll();
          canvas.fire('object:modified'); // Trigger price recalculation
        });
      }
    };

    const handleKeyDown = (e) => {
      if (e.ctrlKey || e.metaKey) {
        switch (e.key) {
          case 'c':
            e.preventDefault();
            copyObjects();
            break;
          case 'x':
            e.preventDefault();
            cutObjects();
            break;
          case 'v':
            e.preventDefault();
            pasteObjects();
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [canvas]);

  return null;
};

export default DuplicateLogicComponent;
