import React, { useEffect } from 'react';
import { fabric } from 'fabric';

const ResizeLogicComponent = ({ canvas, designArea }) => {

  useEffect(() => {
    if (canvas && designArea) {
      const minSize = 5; // Define the minimum size for the object

      const constrainObject = (obj) => {
        const bound = designArea.getBoundingRect();
        obj.setCoords();
        const objBound = obj.getBoundingRect();
        let modified = false;

        if (objBound.left < bound.left) {
          obj.left += bound.left - objBound.left;
          modified = true;
        }
        if (objBound.left + objBound.width > bound.left + bound.width) {
          obj.left -= (objBound.left + objBound.width) - (bound.left + bound.width);
          modified = true;
        }
        if (objBound.top < bound.top) {
          obj.top += bound.top - objBound.top;
          modified = true;
        }
        if (objBound.top + objBound.height > bound.top + bound.height) {
          obj.top -= (objBound.top + objBound.height) - (bound.top + bound.height);
          modified = true;
        }

        const maxWidth = bound.width;
        const maxHeight = bound.height;

        if (objBound.width > maxWidth || objBound.height > maxHeight) {
          const scaleX = maxWidth / obj.width;
          const scaleY = maxHeight / obj.height;
          const scale = Math.min(scaleX, scaleY);

          obj.scaleX = scale;
          obj.scaleY = scale;
          modified = true;
        }

        obj.setCoords();
        const newObjBound = obj.getBoundingRect();

        if (newObjBound.left < bound.left) {
          obj.left += bound.left - newObjBound.left;
          modified = true;
        }
        if (newObjBound.left + newObjBound.width > bound.left + bound.width) {
          obj.left -= (newObjBound.left + newObjBound.width) - (bound.left + bound.width);
          modified = true;
        }
        if (newObjBound.top < bound.top) {
          obj.top += bound.top - newObjBound.top;
          modified = true;
        }
        if (newObjBound.top + newObjBound.height > bound.top + bound.height) {
          obj.top -= (newObjBound.top + newObjBound.height) - (bound.top + bound.height);
          modified = true;
        }

        if (modified) {
          obj.setCoords();
          canvas.renderAll();
        }
      };

      const applyClipping = (obj) => {
        obj.clipPath = new fabric.Rect({
          left: designArea.left,
          top: designArea.top,
          width: designArea.width,
          height: designArea.height,
          absolutePositioned: true
        });
        canvas.renderAll();
      };

      const onScaling = (e) => {
        const obj = e.target;
        const bound = designArea.getBoundingRect();

        // Ensure scaling values are positive and respect the minimum size
        obj.scaleX = Math.max(Math.abs(obj.scaleX), minSize / obj.width);
        obj.scaleY = Math.max(Math.abs(obj.scaleY), minSize / obj.height);

        const maxWidth = bound.width - (obj.left - bound.left);
        const maxHeight = bound.height - (obj.top - bound.top);

        if (obj.width * obj.scaleX > maxWidth) {
          obj.scaleX = maxWidth / obj.width;
        }
        if (obj.height * obj.scaleY > maxHeight) {
          obj.scaleY = maxHeight / obj.height;
        }

        // Maintain aspect ratio
        const minScale = Math.max(minSize / obj.width, minSize / obj.height);
        obj.scaleX = Math.max(obj.scaleX, minScale);
        obj.scaleY = obj.scaleX;

        obj.setCoords();
        const newObjBound = obj.getBoundingRect();

        if (newObjBound.left < bound.left) {
          obj.left += bound.left - newObjBound.left;
        }
        if (newObjBound.left + newObjBound.width > bound.left + bound.width) {
          obj.left -= (newObjBound.left + newObjBound.width) - (bound.left + bound.width);
        }
        if (newObjBound.top < bound.top) {
          obj.top += bound.top - newObjBound.top;
        }
        if (newObjBound.top + newObjBound.height > bound.top + bound.height) {
          obj.top -= (newObjBound.top + newObjBound.height) - (bound.top + bound.height);
        }

        obj.setCoords();
        canvas.renderAll();
      };

      canvas.on('object:moving', (e) => constrainObject(e.target));
      canvas.on('object:scaling', onScaling);
      canvas.on('object:rotating', (e) => constrainObject(e.target));

      canvas.on('object:added', (e) => {
        const obj = e.target;
        applyClipping(obj);
        constrainObject(obj);
        obj.set({ lockScalingFlip: true }); // Prevent flipping on scaling
      });
    }
  }, [canvas, designArea]);

  return null;
};

export default ResizeLogicComponent;
