import React, { useEffect, useState } from "react";
import cartDeleteIcon from '../../assets/cart-delete-icon.svg';
import "./cart.css"; // Create a CSS file to style accordingly
import { getFromLocalStorage } from "../../utils/helperFunctions";
import { Modal, Button, Input, InputNumber } from 'antd'; // Import Modal, Button, and Input from Ant Design
import Cookies from 'js-cookie';
import Header from "../../layout/header/Header";
import Footer from "../../layout/footer/Footer";
import { useNavigate } from "react-router-dom";
import priceTableData from "../../view/json/complete_price_table.json";

import EmbrioderyPrice from "../../view/json/priceEmbroidery.json";


const { confirm } = Modal;

const Loader = () => (
  <div className="spinner"></div> // Customize this spinner in your CSS file
);

const calculateFutureDate = (daysToAdd) => {
  const date = new Date();
  date.setDate(date.getDate() + daysToAdd);
  return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
};
const Cart = () => {
  const [loading, setLoading] = useState(true); // Loading state for fetching cart data
  const [quantity, setQuantity] = useState(1);
  const email = Cookies.get('Email_guest');
  const email1 = getFromLocalStorage("Email");
  const [cart, setCart] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null); // Track the item being edited
  const [editedQuantities, setEditedQuantities] = useState({}); // Store edited quantities
  const [updatedTotalPrice, setUpdatedTotalPrice] = useState({}); // Store updated total prices for edited items
  const [forceRender, setForceRender] = useState(false); // State to trigger Header re-render
  const [selectedOption, setSelectedOption] = useState(2);
  const standardDeliveryDate = calculateFutureDate(14); // Standard 14 days for example
  const superRushDate = calculateFutureDate(4); // Super Rush is 4 days from today
  const rushDate = calculateFutureDate(7); // Rush is 7 days from today
  const [deliveryOptions, setDeliveryOptions] = useState([
    {
      id: 1,
      label: `Super Rush - ${standardDeliveryDate}`,
      subLabel: "Guaranteed 1 Business Day",
      percentage: 30, // 30% for Super Rush
    },
    {
      id: 2,
      label: `Rush - ${superRushDate}`,
      subLabel: "Guaranteed 2 Business Days",
      percentage: 15, // 15% for Rush
    },
    {
      id: 3,
      label: `Standard - ${rushDate}`,
      subLabel: "Standard Delivery",
      percentage: 0, // 0% for Standard (free)
    },
  ]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [deliveryPrices, setDeliveryPrices] = useState({}); // Track delivery prices for each product
  const [totalWithDelivery, setTotalWithDelivery] = useState(0); // Track total with delivery
  const [selectedDeliveryLabels, setSelectedDeliveryLabels] = useState({});
  const [uprice, setUPrice] = useState(0);

  // let colorSet = new Set();
  // const numberOfColors = colorSet.size;

  // console.log("colorSet:",numberOfColors);
  // const fetchDeliveryOptions = async (emailToFetch) => {
  //   try {
  //     const response = await fetch(`https://backend.inkox.com/api/checkouts/email/${emailToFetch}`);
  //     const data = await response.json();
  

  //     console.log("superRush:", data[0].Delivery_date);

  //     if (Array.isArray(data) && data.length > 0 && data[0].Delivery_date) {
  //       const { superRush, rush, standard } = data[0].Delivery_date; // Correctly access properties
  //       console.log("superRush:", superRush);
  //       console.log("rush:", rush);
  //       console.log("standard:", standard);
  
  //       setDeliveryOptions([
  //         {
  //           id: 1,
  //           label: `Super Rush - ${superRush}`,
  //           subLabel: "Guaranteed 1 Business Day",
  //           percentage: 30, // 30% for Super Rush
  //         },
  //         {
  //           id: 2,
  //           label: `Rush - ${rush}`,
  //           subLabel: "Guaranteed 2 Business Days",
  //           percentage: 15, // 15% for Rush
  //         },
  //         {
  //           id: 3,
  //           label: `Standard - ${standard}`,
  //           subLabel: "Standard Delivery",
  //           percentage: 0, // 0% for Standard (free)
  //         },
  //       ]);
  //     } else {
  //       console.error("Delivery_date is missing or undefined.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching delivery options:", error);
  //   }
  // };
  
  
  
  

  const navigate = useNavigate();

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  useEffect(() => {
    // Set the default delivery option to "Standard" (id: 3) when cart data is loaded
    if (cart.length > 0) {
       const standardOption = deliveryOptions.find(option => option.id === 3);
    const deliveryDateLabel = standardOption ? standardOption.label : "Standard";
      const defaultOptions = {};
      cart.forEach((item) => {
        defaultOptions[item.id] = 3; // Set "Standard" delivery option as default
        const itemStandardPrice = 0; // Assuming standard delivery is free
        setDeliveryPrices((prev) => ({
          ...prev,
          [item.id]: itemStandardPrice,
        }));
        const standardTotalPrice = item.totalPrice
        updateCartDeliveryOption(item.id, 'Standard', deliveryDateLabel, standardTotalPrice);

      });
      setSelectedOptions(defaultOptions);
      
    }
  }, [cart]);



 
  const handleDeliveryOptionChange = (itemId, optionId) => {
    const selectedOption = deliveryOptions.find((option) => option.id === optionId);
    const item = cart.find((item) => item.id === itemId);
  
    // Calculate the delivery price based on the selected option's percentage
    const deliveryPrice = selectedOption.percentage > 0
      ? (selectedOption.percentage / 100) * parseFloat(item.totalPrice)
      : 0;
     
      
    // Update selected options and delivery prices
    setSelectedOptions((prev) => ({
      ...prev,
      [itemId]: optionId,
    }));
  
    setDeliveryPrices((prev) => {
      const updatedPrices = {
        ...prev,
        [itemId]: deliveryPrice,
      };
      

      
      return updatedPrices;
    });
          // Calculate the new total price with delivery included
      const newTotalPrice = (parseFloat(item.totalPrice) + deliveryPrice).toFixed(2);
      console.log("newTotalPrice", newTotalPrice);
        // Create the Delivery_date object dynamically based on the selected option
  const deliveryType = selectedOption.label.split(' - ')[0]; // Extracts type like 'Rush', 'Super Rush'
  const deliveryDateLabel = selectedOption.label;
 updateCartDeliveryOption(item.id, deliveryType, deliveryDateLabel, newTotalPrice);
  // const body = JSON.stringify({
  //   Delivery_date: {
  //     [deliveryType]: deliveryDateLabel
  //   },
  //   total_price_with_delivery: newTotalPrice,
  // });
  //     fetch(`https://backend.inkox.com/api/checkouts_delivery/${itemId}`, {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: body,
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log("data", data);
  //       })
  //       .catch((error) => console.error('Error updating cart item:', error));
  };

   const updateCartDeliveryOption = (itemId, deliveryType, deliveryDateLabel, newTotalPrice) => {
    const body = JSON.stringify({
      Delivery_date: {  [deliveryType]: deliveryDateLabel },
      total_price_with_delivery: newTotalPrice,
    });

    fetch(`https://backend.inkox.com/api/checkouts_delivery/${itemId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Updated cart item:", data);
      })
      .catch((error) => console.error('Error updating cart item:', error));
  };

  
  useEffect(() => {
    const totalWithDelivery = cart.reduce((total, item) => {
      const itemTotalPrice = parseFloat(item.totalPrice) || 0;
      const itemDeliveryPrice = parseFloat(deliveryPrices[item.id]) || 0;
      return total + itemTotalPrice + itemDeliveryPrice;
    }, 0);
    setTotalWithDelivery(totalWithDelivery); // Set the computed value directly
  }, [cart, deliveryPrices]);
  
  console.log("deliveryPrices",deliveryPrices);
  // Fetch cart data and update the cart state and localStorage
  const fetchCartData = (emailToFetch) => {
    setLoading(true);
    fetch(`https://backend.inkox.com/api/checkouts/email/${emailToFetch}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          // const updatedCart = data.map(item => {
          //   const allInkColors = [
          //     ...(item.inkColors?.Front || []),
          //     ...(item.inkColors?.Back || []),
          //     ...(item.inkColors?.Left || []),
          //     ...(item.inkColors?.Right || [])
          //   ];
          //   console.log('cartpage',allInkColors);
          //   return {
          //     ...item,
          //     filteredInkColors: allInkColors,

          //   };
          // });
          setCart(data);

          
          localStorage.setItem('cartLength', data.length);
        } else if (data && typeof data === 'object') {
          // const allInkColors = [
          //   ...(data.inkColors?.Front || []),
          //   ...(data.inkColors?.Back || []),
          //   ...(data.inkColors?.Left || []),
          //   ...(data.inkColors?.Right || [])
          // ];
          // const updatedCart = [{
          //   ...data,
          //   filteredInkColors: allInkColors.filter(color => color !== data.productColor),
          // }];
          setCart(data);
          localStorage.setItem('cartLength', 1);
        } else {
          setCart([]);
          localStorage.setItem('cartLength', 0);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching cart data:', error);
        setCart([]);
        localStorage.setItem('cartLength', 0);
        setLoading(false);
      });
  };
  
  

  useEffect(() => {
    if (email) {
      fetchCartData(email);
    } else if (email1) {
      fetchCartData(email1);
    }
   

  }, [email, email1]);

  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem('cartLength', cart.length);
    } else {
      localStorage.setItem('cartLength', 0);
    }
  }, [cart]);

  // Inside your deleteCartItem function, update localStorage correctly and trigger Header re-render
  const deleteCartItem = (itemId) => {
    fetch(`https://backend.inkox.com/api/checkouts/${itemId}`, {
      method: 'DELETE',
    })
      .then((response) => {
        if (response.ok) {
          const updatedCart = cart.filter((item) => item.id !== itemId);
          setCart(updatedCart); // Update cart state
          localStorage.setItem('cartLength', updatedCart.length); // Update cart length in localStorage
          window.dispatchEvent(new Event('storage')); // Manually trigger storage event
          setForceRender(!forceRender); // Toggle forceRender to trigger Header re-render
        } else {
          console.error('Failed to delete the item');
        }
      })
      .catch((error) => console.error('Error deleting cart item:', error));
  };

  const handleNavigate = () => {
    navigate("/checkout");
  };

  const showDeleteConfirm = (itemId) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteCartItem(itemId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // Function to handle "Edit Size" click
  const handleEditSize = (itemId, quantities) => {
    setEditingItemId(itemId); // Set the editing mode for this item
    setEditedQuantities(quantities); // Set initial quantities for editing
    const item = cart.find(item => item.id === itemId);
    setUpdatedTotalPrice({ [itemId]: item.totalPrice });
  };

  const calculatePrice = (totalQuantity, numberOfColorsCart, orderType) => {
    console.log('orderTypecartpage', orderType);
    console.log("totalQuantitycart", totalQuantity);
  
    // Fetch quantity-specific data for screen printing or embroidery
    let quantityData;
    if (orderType === "screenPrinting") {
      quantityData = priceTableData.find(item => item.Quantity === totalQuantity);
      if (!quantityData) {
        console.error("No price data found for this quantity (Screen Printing).");
        return 0;
      }
    } else if (orderType === "embroidery") {
      quantityData = EmbrioderyPrice.find(item => item.Quantity === totalQuantity);
      if (!quantityData) {
        console.error("No price data found for this quantity (Embroidery).");
        return 0;
      }
    } else {
      console.error("Invalid order type.");
      return 0;
    }
  
    let additionalPrice = 0;
    console.log("quantityDatacart", quantityData);
  
    // Calculate price based on the order type
    if (orderType === "screenPrinting") {
      // Screen printing calculation
      Object.keys(numberOfColorsCart).forEach(part => {
        const colorsArray = numberOfColorsCart[part] || [];
        if (colorsArray.length > 0) {
          // Only consider the first color for this part
          additionalPrice += quantityData["1st Color Price"];
        }
      });
    } else if (orderType === "embroidery") {
      // Embroidery calculation: Add price for each part that has colors
      Object.keys(numberOfColorsCart).forEach(part => {
        const colorsArray = numberOfColorsCart[part] || [];
        if (colorsArray.length > 0) {
          additionalPrice += quantityData.Price; // Add embroidery price for each part with colors
        }
      });
  
      if (additionalPrice === 0) {
        console.log("No colors on canvas. Embroidery price not calculated.");
      }
    }
  
    console.log("Calculated additional price:", additionalPrice);
    return additionalPrice;
  };
  
  
  
  const handleSizeInputChange = (itemId, size, value) => {
    const updatedQuantities = {
      ...editedQuantities,
      [size]: parseInt(value, 10) || 0,
    };
    setEditedQuantities(updatedQuantities);
  
    // Calculate total quantity across all sizes
    const totalQuantity = Object.values(updatedQuantities).reduce((sum, val) => sum + val, 0);
    
    // Find the item in the cart and ensure it exists
    const item = cart.find(item => item.id === itemId);
    if (!item) {
      console.error(`Item with id ${itemId} not found in cart.`);
      return;
    }
  
    // Check if filteredInkColors exists on the item
    const numberOfColors = item.inkColors;
    const orderType = item.orderType;

    console.log("Typecart",orderType );
    // Calculate additional price once for the total quantity and number of colors
    const additionalPrice = calculatePrice(totalQuantity, numberOfColors,orderType);
  
    // Parse sale_price once
    let salePriceObj;
    try {
      salePriceObj = JSON.parse(item.sale_price);
    } catch (error) {
      console.error('Error parsing sale_price:', error);
      salePriceObj = {};
    }
  
    // Calculate total price for all sizes
    let newTotalPrice = 0;
  
    Object.keys(updatedQuantities).forEach(size => {
      const quantity = updatedQuantities[size];
      
      // Get the size-specific price from sale_price
      const sizePrice = salePriceObj[size] ? parseFloat(salePriceObj[size]) : 0;
      const sizeTotalPrice = sizePrice;
      
      console.log(`Size ${size}: Quantity ${quantity}, Size Price ${sizePrice}, Size Total Price ${sizeTotalPrice}`);
  
      // Add each size’s price to newTotalPrice
      newTotalPrice += (additionalPrice + sizeTotalPrice) * quantity;
    });
  
    // Calculate the unit price (total price divided by total quantity)
    const unitprice = totalQuantity > 0 ? newTotalPrice / totalQuantity : 0;
  
    setUPrice(unitprice);
     console.log("additionalPrice", additionalPrice);
    console.log("Final newTotalPrice", newTotalPrice);
    console.log("unitprice", unitprice);
    
    setUpdatedTotalPrice({ [itemId]: newTotalPrice.toFixed(2) });
  };
  
  
  
  
  

  const saveEditedSizes = (itemId) => {
    const updatedItem = cart.find(item => item.id === itemId);
  
    updatedItem.quantities = editedQuantities;
    updatedItem.totalQuantity = Object.values(editedQuantities).reduce((sum, val) => sum + val, 0);
    updatedItem.totalPrice = updatedTotalPrice[itemId];
    updatedItem.total_price_with_delivery = updatedTotalPrice[itemId].toString();

    updatedItem.unitPrice = uprice;
  
    fetch(`https://backend.inkox.com/api/checkouts/${itemId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedItem),
    })
    .then(response => response.json())
    .then(data => {
      if (email) {
        fetchCartData(email);
      } else if (email1) {
        fetchCartData(email1);
      }
      setEditingItemId(null);
      setForceRender(!forceRender);
    })
    .catch(error => console.error('Error updating cart item:', error));
  };



  return (
    <>
      {/* Hidden Header */}
      <div style={{ display: 'block' }}>
        <Header key={forceRender} />
      </div>

      {/* Show loader while fetching data */}
      {loading ? (
        <Loader />
      ) : (
        <div className="cart-page-main">
          <div className="bag-text">
            <h2>YOUR BOX</h2>
            <p>
              TOTAL: ({Array.isArray(cart) ? cart.length : 0} {cart.length === 1 ? "item" : "items"}) 
              <span className="total-price">
                 ${totalWithDelivery.toFixed(2)}
              </span>
            </p>
            <p>Items in your bag are not reserved — check out now to make them yours.</p>
          </div>

          <div className="cart-page-container">
            <div className="cart-section">
              {Array.isArray(cart) && cart.length > 0 ? (
                cart.map((item, index) => (
                  item && item.quantities && item.totalPrice && (
                    <div key={index} className="cart-item-name">
                      <div className="Design-name">
                        <h4 className="text-design-name">
                          {item.designName || 'No Design Name'} 
                        </h4>
                        <div>
                          <img 
                            src={cartDeleteIcon} 
                            alt="Delete" 
                            onClick={() => showDeleteConfirm(item.id)} 
                            style={{ cursor: 'pointer' }} 
                          />
                        </div>
                      </div>

                      <div className="cart-item">
                        <div className="catimage">
                        <img
                          src={
                            item.imagePreviews && item.imagePreviews.find(preview => preview.part === "Front") 
                              ? item.imagePreviews.find(preview => preview.part === "Front").preview 
                              : "https://via.placeholder.com/150"
                          } 
                          alt="Product Preview"
                          className="product-image"
                        />
                          <img
                          src={
                            item.imagePreviews && item.imagePreviews.find(preview => preview.part === "Front") 
                              ? item.imagePreviews.find(preview => preview.part === "Back").preview 
                              : "https://via.placeholder.com/150"
                          } 
                          alt="Product Preview"
                          className="product-image"
                        />
                          <img
                          src={
                            item.imagePreviews && item.imagePreviews.find(preview => preview.part === "Front") 
                              ? item.imagePreviews.find(preview => preview.part === "Right").preview 
                              : "https://via.placeholder.com/150"
                          } 
                          alt="Product Preview"
                          className="product-image"
                        />
                        <img
                          src={
                            item.imagePreviews && item.imagePreviews.find(preview => preview.part === "Back") 
                              ? item.imagePreviews.find(preview => preview.part === "Left").preview 
                              : "https://via.placeholder.com/150"
                          } 
                          alt="Product Preview"
                          className="product-image"
                        />
                        </div>
                        <div className="cart-item-details">
                          <h3>{item.productName || 'No Product Name'}</h3>
                          <p>
                            Color: {item.productColor || 'No Color'} | Printing
                          </p>
                          <p>
                            QTY {item.totalQuantity || 0} 
                            <span>@ ${item.unitPrice || 0} each</span>
                          </p>

                          {editingItemId === item.id ? (
                            <>
                              <div className="preview-size-main">
                              {Object.keys(item.quantities).map((size, idx) => (
  <span key={idx} className="size-preview">
    {size}: 
    <Input
      type="number"
      value={editedQuantities[size]}
      onChange={(e) => handleSizeInputChange(item.id, size, e.target.value)}
      min="0"
      className="size-input"
      style={{ width: '60px', marginLeft: '10px' }}
    />
  </span>
))}

                              </div>
                              <p>Total Price: ${updatedTotalPrice[item.id] || item.totalPrice}</p>
                              <Button type="primary" onClick={() => saveEditedSizes(item.id)}>Save</Button>
                            </>
                          ) : (
                            <>
                              <p className="preview-size-main">
                                {Object.keys(item.quantities).map((size, idx) => (
                                  <span key={idx} className="size-preview">{size}: {item.quantities[size]}</span>
                                ))}
                              </p>
                              <p className="edit-size-design">
                                <a onClick={() => handleEditSize(item.id, item.quantities)} style={{ cursor: 'pointer' }}>Edit Size</a>
                              </p>
                            </>
                          )}
                        </div>
                        <div className="cart-item-price">
                          ${editingItemId === item.id ? updatedTotalPrice[item.id] : item.totalPrice || 0}
                        </div>
                        {/* <div className="cart-item-price">abd
  ${(parseFloat(item.totalPrice || 0) + parseFloat(deliveryPrices[item.id] || 0)).toFixed(2)}
</div> */}


                      </div>
                    <div className="delivery-options-container">
                      <h2>Delivery Options</h2>
                      {deliveryOptions.map((option) => (
                        <label
                          key={option.id}
                          className={`delivery-option ${
                            selectedOptions[item.id] === option.id ? "selected-option" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name={`delivery-${item.id}`} // Unique name per item
                            value={option.id}
                            checked={selectedOptions[item.id] === option.id}
                            onChange={() => handleDeliveryOptionChange(item.id, option.id)}
                            className="delivery-radio-input"
                          />
                          <div className="delivery-option-content">
                            <div className="delivery-info">
                              <span className="delivery-label">{option.label}</span>
                              <span className="delivery-sublabel">{option.subLabel}</span>
                            </div>
                            <div className="delivery-price">
                              {/* Preview delivery price based on percentage, or 'Free' for Standard */}
                              {option.percentage > 0 
                                ? ` Add ${(option.percentage)}%$${((option.percentage / 100) * item.totalPrice).toFixed(2)} `
                                : "Free"}
                            </div>
                          </div>
                        </label>
                      ))}
                    </div>
                 
                    </div>
                  )
                ))
              ) : (
                <p>Your cart is empty.</p>
              )}
            </div>




            <div className="order-summary-section">
    
              <button className="checkout-button" onClick={handleNavigate}>CHECKOUT</button>
              <button className="google-pay-button">G Pay</button>
              

              <div className="order-summary">
                <h3>Order Summary</h3>
                <p>
                  {Array.isArray(cart) ? cart.length : 0} {cart.length === 1 ? "item" : "items"} 
                  <span className="price">
                    ${Array.isArray(cart) ? cart.reduce((total, item) => total + parseFloat(item.totalPrice), 0).toFixed(2) : "0.00"}
                  </span>
                </p>
                <p>
                  <strong>Total</strong> 
                  <span className="price">
                    ${(Array.isArray(cart) ? cart.reduce((total, item) => total + parseFloat(item.totalPrice), 0)  : 0).toFixed(2)}
                  </span>
                </p>
                <p>
  <strong>Total with Delivery</strong> 
  <span className="price">
  ${totalWithDelivery.toFixed(2)}

  </span>
</p>



              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default Cart;
