import React, { useState, useEffect } from 'react';
import './ProductColor.css'; // Import CSS file for styling

const SelectedCategoryFont = ({ onCategorySelect, onClose, handleCategoryChange, selectedCategory, onFontFamilyChange, text }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [fonts, setFonts] = useState([]); // Stores all fonts
  const [filteredFonts, setFilteredFonts] = useState([]); // Stores filtered fonts based on selected category

  // Fetch fonts from the API
  useEffect(() => {
    fetch("https://backend.inkox.com/api/fonts")
      .then((response) => response.json())
      .then((data) => {
        setFonts(data); // Load all fonts into state
      })
      .catch((error) => {
        console.error("Error fetching fonts:", error);
      });
  }, []);

  const loadFont = (fontPath, fontName) => {
    // Dynamically add each font to the document via @font-face
    const fontFace = new FontFace(fontName, `url(${fontPath})`);
    fontFace.load().then(loadedFont => {
      document.fonts.add(loadedFont); // Add the font to the document
    }).catch(error => {
      console.error("Error loading font:", error);
    });
  };

  // Automatically load fonts for all fonts in the list
  useEffect(() => {
    fonts.forEach(font => {
      loadFont(font.fontPath, font.name);
    });
  }, [fonts]);

  // Filter fonts by selected category
  useEffect(() => {
    if (selectedCategory) {
      const categoryFonts = fonts.filter(font => font.category && font.category.name === selectedCategory);
      setFilteredFonts(categoryFonts);
    }
  }, [selectedCategory, fonts]);

  // Handle the font selection
  const handleFontFamilyChange = (fontPath, fontName) => {
    onFontFamilyChange(fontPath, fontName); // Call parent method to set the font
    onClose(); // Close the font selector
  };

  return (
    <div className="font-selector">
      {/* Search box */}
      <div className="search-box">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="#aaa" d="M23.7 22.3l-5.7-5.7c1.2-1.5 1.9-3.4 1.9-5.4 0-4.7-3.8-8.5-8.5-8.5S2.9 6.5 2.9 11.2 6.7 19.7 11.4 19.7c2 0 3.9-.7 5.4-1.9l5.7 5.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4zM11.4 17c-3.2 0-5.8-2.6-5.8-5.8s2.6-5.8 5.8-5.8 5.8 2.6 5.8 5.8-2.6 5.8-5.8 5.8z" />
        </svg>
        <input
          type="text"
          placeholder="Search Fonts"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="font-search"
        />
      </div>

      {/* Font Grid */}
      <div className="font-grid">
        {filteredFonts
          .filter(font => font.name.toLowerCase().includes(searchTerm.toLowerCase())) // Search filter
          .map(font => (
            <div
              key={font.id}
              className="font-box"
              style={{ fontFamily: font.name }}
              onClick={() => handleFontFamilyChange(font.font_path, font.name)}
            >
              <div className="font-name" style={{ fontFamily: font.name }}>{text}</div>
              {font.name}
            </div>
          ))}
      </div>

      {/* Done Button */}
      <button style={{ marginTop: "10px", float: 'right' }} className='button-to-text' onClick={onClose}>
        Done
      </button>
    </div>
  );
};

export default SelectedCategoryFont;
