import React from "react";
import Slider from "react-slick";
import TestimonialCard from "../../components/testimonialCard/TestimonialCard";
import Container from "../../components/container/Container";
import Title from "../../components/typography/title/Title";

const Testimonials = ({ data }) => {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1111,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="testimonials">
      <Container>
        <Title size={"41"} tag={"h2"} weight={700}>
          Our Customers Love Us
        </Title>
        <Title customClass={"sub-title"} size={"21"} tag={"h2"} weight={500}>
          Why so many choose us for custom apparel
        </Title>
        <div className="slider-container">
          <Slider {...settings}>
            {data?.map((item, index) => {
              return (
                <div key={index}>
                  <TestimonialCard data={item} />
                </div>
              );
            })}
          </Slider>
        </div>
      </Container>
    </div>
  );
};

export default Testimonials;
