import React, {useState} from "react";
import "./explore.css"; // Importing the updated CSS
import Image from './assets/funimage.jpg';
import Breadcrumb from "./Breadcrumb.js";
import Sidebar from "./Sidebar.js";
import "./App.css";
import LayoutWrapper from "../../layout/layoutWrapper/LayoutWrapper.jsx";
import { Link } from "react-router-dom";
function Explore() {
    const [openSections, setOpenSections] = useState(["about", "agreement", "policy", "solution"]);

    const toggleSection = (section) => {
      setOpenSections((prevOpenSections) => {
        if (prevOpenSections.includes(section)) {
          return prevOpenSections.filter((s) => s !== section);
        } else {
          return [...prevOpenSections, section];
        }
      });
    };
  
    const renderIcon = (isOpen) => (
      <svg
        className="mui-icon"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ExpandMoreIcon"
        style={{
          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
        }}
      >
        <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </svg>
    );
  
    return (
        <LayoutWrapper>
        <div className="main-div-other-pages">
             <div className="container">
             <Breadcrumb />
            <div className="inkox-container">
            <Sidebar
              openSections={openSections}
              toggleSection={toggleSection}
              renderIcon={renderIcon}
            />
                {/* Main Content */}
                <div className="inkox-main-content">

                    <h1 className="inkox-content-h1"> Explore the Fun at INKOX!  </h1>
                    <p className="inkox-content-para">
                        Welcome to INKOX, where we're all about spicing up your style with a dash of fun! Our service goes beyond just clothing — it’s about celebrating what makes you tick. Whether you’re rallying a sports team, jazzing up a special event, or adding some pizzazz to your business, we’re here to make it shine. At INKOX, we believe our custom designs do more than dress you up—they lift you up, spark creativity, and spread positivity. We’re not just passionate about custom gear; we adore helping you bring your ideas to life. So, let’s start creating something amazing together!
                    </p>

                    <div className="inkox-image-section">
                        <img
                            src={Image}
                            alt="Customer Support"
                            className="inkox-support-image"
                        />
                    </div>
                    <h2 className="inkox-content-h2"> The INKOX Experience </h2>
                    <p className="inkox-content-para">
                        Each order at INKOX is unique, just like your brilliant ideas, and we're here to make them sparkle. It’s all about teamwork, and INKOX is your go-to for creative resources and a cheerful team eager to support you from start to finish.
                    </p>
                    <h2 className="inkox-content-h2"> Step Into Our Design Lab - Who Needs a Lab Coat? </h2>
                    <p className="inkox-content-para">
                        Let’s have fun bringing your ideas to life in our exclusive Design Lab. Not an artist? No worries! Our Design Lab offers free access to an extensive library of fonts and over 68,000 pieces of artwork. We've got everything you need to craft a one-of-a-kind design.
                        Kick off your creation from scratch, or upload your own artwork, photos, or logos. Need a bit of advice or an extra set of eyes? Our Custom Creations team is ready to dive in and help—just reach out by phone or email. We’re here to create with you and for you.
                    </p>

                    <h2 className="inkox-content-h2"> We Make You Shine!</h2>
                    <p className="inkox-content-para">
                        Ready to make your group look fabulous? Our team of expert artists ensures your design comes out perfect with every order. We scrutinize each submission for sizing, contrast, and layering issues. If your design needs tweaking, we’ll refine it for flawless results, and for those intricate designs, we might even send a sample your way. We’re committed to making sure the design you receive matches—or even exceeds—the vision in your mind.
                    </p>
                    <h2 className="inkox-content-h2"> Real People, Outstanding Service </h2>
                    <p className="inkox-content-para">
                        At INKOX, we’re all about connection. Our friendly team is passionate about discussing your projects. Whether it’s adjusting your design or perfecting the details, we’re here to ensure your group looks amazing. Our knowledgeable service reps are available 7 days a week—give us a call at 1-800-293-4232. Prefer to chat or email? That’s perfect too! We’re always thrilled to talk about your projects.

                    </p>
                    <h2 className="inkox-content-h2"> Quality In Every Thread</h2>
                    <p>
                        We believe in quality that lasts. That’s why our wide range of custom apparel is designed to be shown off time and again.
                        Whether it's t-shirts (from short sleeves to long, in all colors and sizes up to 6XL), hoodies, jerseys, tank tops, or jackets, we’ve got you covered. Plus, we offer an array of custom items like can coolers, pens, sunglasses, and more, ensuring your design lives on in style.

                    </p>
                    <h2 className="inkox-content-h2">Explore Our Full Product Range</h2>
                    <h3 className="inkox-content-h3"> Unbeatable Guarantee: Guaranteed Greatness</h3>
                    <p className="inkox-content-h2">
                        At INKOX, we focus on the details. We promise top-quality products and designs that perfectly capture your vision, delivered right when you need them. If what you receive doesn’t match your design or if it arrives late, you can return it within 15 days for a full refund.
                    </p>
                    <h2 className="inkox-content-h2">Check Out Our Money-Back Guarantee</h2>
                    <h3 className="inkox-content-h3"> Free Shipping, On-Time, Every Time</h3>
                    <p className="inkox-content-para">
                        We're just as excited as you are to get your fabulous order in your hands. That’s why we provide free standard ground shipping across the continental U.S. and Canada, ensuring delivery in 2 weeks or less.
                        In a hurry? Choose our Rush Delivery for a guaranteed 1-week arrival, or go for Super Rush to get your items in just 3 days.
                    </p>



                </div>
            </div>
            <p className="divider" style={{ borderBottom: "1px solid #aaa" }}>
            </p>
            <div className="browsebut" style={{textAlign:"center" }}>
            <Link className="inkox-primary-button" to={`/products`}>Browse All of Our Products</Link>
                <button className="inkox-primary-button">Start Designing in the Lab</button>
            </div>
            </div>
        </div>
        </LayoutWrapper>
    );
}
export default Explore;
