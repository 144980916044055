export const colorHexMap = {
    "New Navy": {
        "hex": "#1d283c",
        "id": "700001"
    },
    "Power Red": {
        "hex": "#b0142c",
        "id": "700002"
    },
    "Royal": {
        "hex": "#19578b",
        "id": "700003"
    },
    "Vista Grey": {
        "hex": "#b1aca8",
        "id": "700004"
    },
    "White": {
        "hex": "#e8e8e8",
        "id": "700005"
    },
    "Black": {
        "hex": "#0f1313",
        "id": "700006"
    },
    "Collegiate Navy": {
        "hex": "#1d283c",
        "id": "700007"
    },
    "Grey Three": {
        "hex": "#A4A4A4",
        "id": "700008"
    },
    "Black/ Vista Grey": {
        "hex": "#000000",
        "id": "700009"
    },
    "Collegiate Navy/ Mid Grey": {
        "hex": "#1d283c",
        "id": "700010"
    },
    "Collegiate Royal": {
        "hex": "#063B7F",
        "id": "700011"
    },
    "Vista Grey/ EQT Yellow": {
        "hex": "#707271",
        "id": "700012"
    },
    "White/ Vista Grey": {
        "hex": "#e8e8e8",
        "id": "700013"
    },
    "Collegiate Green": {
        "hex": "#005749",
        "id": "700014"
    },
    "Collegiate Red": {
        "hex": "#b0142c",
        "id": "700015"
    },
    "Green": {
        "hex": "#007853",
        "id": "700016"
    },
    "Maroon": {
        "hex": "#4c0527",
        "id": "700017"
    },
    "Navy": {
        "hex": "#1d283c",
        "id": "700018"
    },
    "Black/ Black": {
        "hex": "#0f1313",
        "id": "700019"
    },
    "Grey Five/ Grey Three": {
        "hex": "#6f6e73",
        "id": "700020"
    },
    "Black Heather/ Carbon": {
        "hex": "#4a4f55",
        "id": "700021"
    },
    "Collegiate Navy Heather/ Carbon": {
        "hex": "#5b6770",
        "id": "700022"
    },
    "Collegiate Royal Heather/ Carbon": {
        "hex": "#426ca9",
        "id": "700023"
    },
    "Grey Three Heather/ Carbon": {
        "hex": "#A4A4A4",
        "id": "700024"
    },
    "Power Red Heather/ Carbon": {
        "hex": "#d1555a",
        "id": "700025"
    },
    "White/ Carbon": {
        "hex": "#e8e8e8",
        "id": "700026"
    },
    "Black Heather/ Mid Grey": {
        "hex": "#4a4f55",
        "id": "700027"
    },
    "Collegiate Royal Heather/ Mid Grey": {
        "hex": "#426ca9",
        "id": "700028"
    },
    "Mid Grey Heather/ Black": {
        "hex": "#A4A4A4",
        "id": "700029"
    },
    "Navy Heather/ Mid Grey": {
        "hex": "#323e48",
        "id": "700030"
    },
    "Power Red Heather/ Black": {
        "hex": "#b0142c",
        "id": "700031"
    },
    "Black/ White": {
        "hex": "#0f1313",
        "id": "700032"
    },
    "Collegiate Navy/ White": {
        "hex": "#1d283c",
        "id": "700033"
    },
    "Collegiate Red/ Black": {
        "hex": "#b0142c",
        "id": "700034"
    },
    "Collegiate Royal/ Grey Three": {
        "hex": "#063B7F",
        "id": "700035"
    },
    "Grey Five/ Black": {
        "hex": "#707271",
        "id": "700036"
    },
    "White/ Black": {
        "hex": "#e8e8e8",
        "id": "700037"
    },
    "Collegiate Red Melange": {
        "hex": "#b0142c",
        "id": "700038"
    },
    "Collegiate Royal Melange": {
        "hex": "#19578b",
        "id": "700039"
    },
    "Mid Grey Melange": {
        "hex": "#A4A4A4",
        "id": "700040"
    },
    "Navy Melange": {
        "hex": "#1a2238",
        "id": "700041"
    },
    "Black Melange": {
        "hex": "#0f1313",
        "id": "700042"
    },
    "Black Heather": {
        "hex": "#4a4f55",
        "id": "700043"
    },
    "Collegiate Navy Melange": {
        "hex": "#1d283c",
        "id": "700044"
    },
    "Lucky Blue Melange": {
        "hex": "#84bfd8",
        "id": "700045"
    },
    "Dark Grey Heather": {
        "hex": "#494d54",
        "id": "700046"
    },
    "Grey Heather": {
        "hex": "#A4A4A4",
        "id": "700047"
    },
    "Red": {
        "hex": "#b0142c",
        "id": "700048"
    },
    "Grey Five": {
        "hex": "#707271",
        "id": "700049"
    },
    "Black/ Grey Two": {
        "hex": "#0f1313",
        "id": "700050"
    },
    "Grey Three/ White": {
        "hex": "#A4A4A4",
        "id": "700051"
    },
    "Team Collegiate Red/ Grey Two": {
        "hex": "#ea002a",
        "id": "700052"
    },
    "Team Navy Blue/ Grey Two": {
        "hex": "#1d283c",
        "id": "700053"
    },
    "Team Royal/ Grey Two": {
        "hex": "#19578b",
        "id": "700054"
    },
    "Black/ White/ Grey Three": {
        "hex": "#0f1313",
        "id": "700055"
    },
    "Glow Blue/ White/ Navy": {
        "hex": "#c3d6ee",
        "id": "700056"
    },
    "Grey Three/ Team Royal/ Navy": {
        "hex": "#A4A4A4",
        "id": "700057"
    },
    "Navy Blue/ White/ Grey Three": {
        "hex": "#1d283c",
        "id": "700058"
    },
    "Clear Mint": {
        "hex": "#B1D9CA",
        "id": "700059"
    },
    "Hazy Blue": {
        "hex": "#7b98ac",
        "id": "700060"
    },
    "Real Coral": {
        "hex": "#BE414C",
        "id": "700061"
    },
    "Team Navy Blue": {
        "hex": "#1d283c",
        "id": "700062"
    },
    "Tech Purple": {
        "hex": "#5D5671",
        "id": "700063"
    },
    "Grey Six": {
        "hex": "#4A4A4D",
        "id": "700064"
    },
    "Grey Six/ Black": {
        "hex": "#4A4A4D",
        "id": "700065"
    },
    "Tech Olive/ Legend Earth": {
        "hex": "#676E45",
        "id": "700066"
    },
    "Black/ Grey Three": {
        "hex": "#0f1313",
        "id": "700067"
    },
    "Collegiate Navy/ Grey Three": {
        "hex": "#2E3341",
        "id": "700068"
    },
    "Black/ Grey Three Melange": {
        "hex": "#0f1313",
        "id": "700069"
    },
    "Collegiate Navy/ Grey Three Melange": {
        "hex": "#1d283c",
        "id": "700070"
    },
    "Green Oxide/ Grey Three Melange": {
        "hex": "#007853",
        "id": "700071"
    },
    "White/ Grey Three Melange": {
        "hex": "#e8e8e8",
        "id": "700072"
    },
    "Focus Blue Melange": {
        "hex": "#2C54A0",
        "id": "700073"
    },
    "Grey Three Melange": {
        "hex": "#A4A4A4",
        "id": "700074"
    },
    "Medium Grey Heather": {
        "hex": "#A4A4A4",
        "id": "700075"
    },
    "Grey Two Melange": {
        "hex": "#c8c8c8",
        "id": "700076"
    },
    "Hemp": {
        "hex": "#8D906B",
        "id": "700077"
    },
    "Olive Strata": {
        "hex": "#3F4931",
        "id": "700078"
    },
    "Core White": {
        "hex": "#E8EDF5",
        "id": "700079"
    },
    "Blue Dawn Melange": {
        "hex": "#96bdf2",
        "id": "700080"
    },
    "Grey One Heather": {
        "hex": "#A4A4A4",
        "id": "700081"
    },
    "Crew Navy": {
        "hex": "#1D3253",
        "id": "700082"
    },
    "Grey": {
        "hex": "#A4A4A4",
        "id": "700083"
    },
    "Athletic Heather": {
        "hex": "#97999B",
        "id": "700084"
    },
    "Charcoal": {
        "hex": "#53565A",
        "id": "700085"
    },
    "Charcoal Heather": {
        "hex": "#54585A",
        "id": "700086"
    },
    "Celadon": {
        "hex": "#6ECEB2",
        "id": "700087"
    },
    "Athletic Heather/ Black": {
        "hex": "#97999B",
        "id": "700088"
    },
    "Athletic Heather/ Navy": {
        "hex": "#97999B",
        "id": "700089"
    },
    "Black/ Athletic Heather": {
        "hex": "#1C1F2A",
        "id": "700090"
    },
    "Black/ Gold": {
        "hex": "#1C1F2A",
        "id": "700091"
    },
    "Black/ Red": {
        "hex": "#1C1F2A",
        "id": "700092"
    },
    "White/ Navy": {
        "hex": "#FFFFFF",
        "id": "700093"
    },
    "White/ Red": {
        "hex": "#FFFFFF",
        "id": "700094"
    },
    "White/ Royal": {
        "hex": "#FFFFFF",
        "id": "700095"
    },
    "Cardinal": {
        "hex": "#9D2235",
        "id": "700096"
    },
    "Graphite Heather": {
        "hex": "#707372",
        "id": "700097"
    },
    "Kelly": {
        "hex": "#007749",
        "id": "700098"
    },
    "Military Green": {
        "hex": "#535435",
        "id": "700099"
    },
    "Navy Heather": {
        "hex": "#333F48",
        "id": "700100"
    },
    "Orange": {
        "hex": "#FF6A13",
        "id": "700101"
    },
    "Red Heather": {
        "hex": "#BF0D3E",
        "id": "700102"
    },
    "Royal Heather": {
        "hex": "#385E9D",
        "id": "700103"
    },
    "Safety Green": {
        "hex": "#D0DF00",
        "id": "700104"
    },
    "Sand": {
        "hex": "#D9C0A9",
        "id": "700105"
    },
    "Silver": {
        "hex": "#C1C6C8",
        "id": "700106"
    },
    "Yellow": {
        "hex": "#F4DA40",
        "id": "700107"
    },
    "Banana": {
        "hex": "#FBD872",
        "id": "700108"
    },
    "Pink": {
        "hex": "#FABBCB",
        "id": "700109"
    },
    "Powder Blue": {
        "hex": "#A7BCD6",
        "id": "700110"
    },
    "Purple": {
        "hex": "#563D82",
        "id": "700111"
    },
    "Turquoise": {
        "hex": "#007FA3",
        "id": "700112"
    },
    "Ash": {
        "hex": "#C8C9C7",
        "id": "700113"
    },
    "Burgundy": {
        "hex": "#6F263D",
        "id": "700114"
    },
    "Cream": {
        "hex": "#EFDBB2",
        "id": "700115"
    },
    "Kelly Heather": {
        "hex": "#6FA287",
        "id": "700116"
    },
    "Mint": {
        "hex": "#9BE198",
        "id": "700117"
    },
    "Pacific Blue": {
        "hex": "#4AC9E3",
        "id": "700118"
    },
    "Slate": {
        "hex": "#5B7F95",
        "id": "700119"
    },
    "Coral": {
        "hex": "#F65275",
        "id": "700120"
    },
    "Camo": {
        "hex": "#5C524C",
        "id": "700121"
    },
    "Eco Black": {
        "hex": "#5F5B50",
        "id": "700122"
    },
    "Eco Grey": {
        "hex": "#7D7E83",
        "id": "700123"
    },
    "Eco Light Grey": {
        "hex": "#E4E2E7",
        "id": "700124"
    },
    "Eco True Black": {
        "hex": "#120c10",
        "id": "700125"
    },
    "Eco True Navy": {
        "hex": "#424E6A",
        "id": "700126"
    },
    "Eco True Red": {
        "hex": "#F14D62",
        "id": "700127"
    },
    "Ash Grey": {
        "hex": "#C1B2B6",
        "id": "700128"
    },
    "Carolina Blue": {
        "hex": "#7BAFD4",
        "id": "700129"
    },
    "Forest": {
        "hex": "#173F35",
        "id": "700130"
    },
    "Gold": {
        "hex": "#F2A900",
        "id": "700131"
    },
    "Heather Charcoal": {
        "hex": "#75787B",
        "id": "700132"
    },
    "Heather Grey": {
        "hex": "#B1B3B3",
        "id": "700133"
    },
    "Royal Blue": {
        "hex": "#1D4F91",
        "id": "700134"
    },
    "True Navy": {
        "hex": "#131E29",
        "id": "700135"
    },
    "Heather Burgundy": {
        "hex": "#76232F",
        "id": "700136"
    },
    "Heather Navy": {
        "hex": "#003057",
        "id": "700137"
    },
    "Heather Red": {
        "hex": "#BF0D3E",
        "id": "700138"
    },
    "Heather Royal": {
        "hex": "#385E9D",
        "id": "700139"
    },
    "Tar": {
        "hex": "#373A36",
        "id": "700140"
    },
    "Asphalt": {
        "hex": "#53565A",
        "id": "700141"
    },
    "Baby Blue": {
        "hex": "#8BB8E8",
        "id": "700142"
    },
    "Cranberry": {
        "hex": "#76232F",
        "id": "700143"
    },
    "Creme": {
        "hex": "#E7CEB5",
        "id": "700144"
    },
    "Eggplant": {
        "hex": "#4B3048",
        "id": "700145"
    },
    "Fuchsia": {
        "hex": "#E63888",
        "id": "700146"
    },
    "Grass": {
        "hex": "#319B42",
        "id": "700147"
    },
    "Kelly Green": {
        "hex": "#007B4B",
        "id": "700148"
    },
    "Light Pink": {
        "hex": "#F2C6CF",
        "id": "700149"
    },
    "New Silver": {
        "hex": "#a7a8aa",
        "id": "700150"
    },
    "Olive": {
        "hex": "#3E4827",
        "id": "700151"
    },
    "Truffle": {
        "hex": "#643335",
        "id": "700152"
    },
    "Heather Black": {
        "hex": "#131E29",
        "id": "700153"
    },
    "Heather Cranberry": {
        "hex": "#70273D",
        "id": "700154"
    },
    "Heather Gold": {
        "hex": "#F1B300",
        "id": "700155"
    },
    "Heather Kelly Green": {
        "hex": "#00AD6F",
        "id": "700156"
    },
    "Heather Lake Blue": {
        "hex": "#256BA2",
        "id": "700157"
    },
    "Heather Orange": {
        "hex": "#F87C56",
        "id": "700158"
    },
    "Heather Plum": {
        "hex": "#512A44",
        "id": "700159"
    },
    "Lapis": {
        "hex": "#151F6D",
        "id": "700160"
    },
    "Dark Heather Grey": {
        "hex": "#54585A",
        "id": "700161"
    },
    "Sea Blue": {
        "hex": "#315470",
        "id": "700162"
    },
    "Bone": {
        "hex": "#D1C1A8",
        "id": "700163"
    },
    "Lieutenant": {
        "hex": "#65665C",
        "id": "700164"
    },
    "Athletic Blue": {
        "hex": "#6899C4",
        "id": "700165"
    },
    "Athletic Grey": {
        "hex": "#9A959B",
        "id": "700166"
    },
    "Tri-Black": {
        "hex": "#212721",
        "id": "700167"
    },
    "Tri-Indigo": {
        "hex": "#384967",
        "id": "700168"
    },
    "Dark Grey": {
        "hex": "#737470",
        "id": "700169"
    },
    "Khaki": {
        "hex": "#807c6c",
        "id": "700170"
    },
    "Light Grey Melange": {
        "hex": "#A2A7A1",
        "id": "700171"
    },
    "Mustard Yellow": {
        "hex": "#c68a32",
        "id": "700172"
    },
    "Dark Grey/ Black": {
        "hex": "#54585A",
        "id": "700173"
    },
    "Navy/ Navy": {
        "hex": "#1B365D",
        "id": "700174"
    },
    "Olive/ Black": {
        "hex": "#635c4f",
        "id": "700175"
    },
    "White/ White": {
        "hex": "#ffffff",
        "id": "700176"
    },
    "Aqua": {
        "hex": "#4298B5",
        "id": "700177"
    },
    "Autumn": {
        "hex": "#A7492D",
        "id": "700178"
    },
    "Berry": {
        "hex": "#C6007E",
        "id": "700179"
    },
    "Burnt Orange": {
        "hex": "#CB6015",
        "id": "700180"
    },
    "Canvas Red": {
        "hex": "#9D2235",
        "id": "700181"
    },
    "Charity Pink": {
        "hex": "#F04E98",
        "id": "700182"
    },
    "Columbia Blue": {
        "hex": "#225394",
        "id": "700183"
    },
    "Dark Lavender": {
        "hex": "#E6E6FA",
        "id": "700184"
    },
    "Deep Teal": {
        "hex": "#004F71",
        "id": "700185"
    },
    "Dusty Blue": {
        "hex": "#A3B2A4",
        "id": "700186"
    },
    "Evergreen": {
        "hex": "#115740",
        "id": "700187"
    },
    "Leaf": {
        "hex": "#4f924e",
        "id": "700188"
    },
    "Light Blue": {
        "hex": "#bfccd9",
        "id": "700189"
    },
    "Lilac": {
        "hex": "#4B2E5C",
        "id": "700190"
    },
    "Maize Yellow": {
        "hex": "#f0cd64",
        "id": "700191"
    },
    "Mauve": {
        "hex": "#C13C7E",
        "id": "700192"
    },
    "Mustard": {
        "hex": "#f0cd64",
        "id": "700193"
    },
    "Natural": {
        "hex": "#f3f2ed",
        "id": "700194"
    },
    "Ocean Blue": {
        "hex": "#72b3de",
        "id": "700195"
    },
    "Orchid": {
        "hex": "#A5929D",
        "id": "700196"
    },
    "Oxblood Black": {
        "hex": "#000102",
        "id": "700197"
    },
    "Pebble Brown": {
        "hex": "#7D6B6B",
        "id": "700198"
    },
    "Poppy": {
        "hex": "#CA3606",
        "id": "700199"
    },
    "Rust": {
        "hex": "#e77f2b",
        "id": "700200"
    },
    "Soft Cream": {
        "hex": "#D3C4AD",
        "id": "700201"
    },
    "Soft Pink": {
        "hex": "#f4d2d9",
        "id": "700202"
    },
    "Steel Blue": {
        "hex": "#66819d",
        "id": "700203"
    },
    "Storm": {
        "hex": "#645e5e",
        "id": "700204"
    },
    "Sunset": {
        "hex": "#ee7c6f",
        "id": "700205"
    },
    "Tan": {
        "hex": "#cab9a5",
        "id": "700206"
    },
    "Teal": {
        "hex": "#0E4C61",
        "id": "700207"
    },
    "Team Navy": {
        "hex": "#28283d",
        "id": "700208"
    },
    "Team Purple": {
        "hex": "#4B2E5C",
        "id": "700209"
    },
    "True Royal": {
        "hex": "#2d60a6",
        "id": "700210"
    },
    "Vintage Black": {
        "hex": "#000102",
        "id": "700211"
    },
    "Vintage White": {
        "hex": "#e8e8e8",
        "id": "700212"
    },
    "Deep Heather": {
        "hex": "#716E6A",
        "id": "700213"
    },
    "Heather Aqua": {
        "hex": "#44A8C6",
        "id": "700214"
    },
    "Heather Autumn": {
        "hex": "#98563D",
        "id": "700215"
    },
    "Heather Blue": {
        "hex": "#8E9FBC",
        "id": "700216"
    },
    "Heather Blue Lagoon": {
        "hex": "#86A1A9",
        "id": "700217"
    },
    "Heather Brown": {
        "hex": "#5E4B3C",
        "id": "700218"
    },
    "Heather Bubble Gum": {
        "hex": "#FFBBE0",
        "id": "700219"
    },
    "Heather Canvas Red": {
        "hex": "#750F1A",
        "id": "700220"
    },
    "Heather Cardinal": {
        "hex": "#651D32",
        "id": "700221"
    },
    "Heather Cement": {
        "hex": "#C2BEB8",
        "id": "700222"
    },
    "Heather Charity Pink": {
        "hex": "#F6648D",
        "id": "700223"
    },
    "Heather Clay": {
        "hex": "#A6675A",
        "id": "700224"
    },
    "Heather Columbia Blue": {
        "hex": "#638ACC",
        "id": "700225"
    },
    "Heather Cool Grey": {
        "hex": "#CDC0B9",
        "id": "700226"
    },
    "Heather Deep Teal": {
        "hex": "#34657F",
        "id": "700227"
    },
    "Heather Dust": {
        "hex": "#C5B9AC",
        "id": "700228"
    },
    "Heather Dusty Blue": {
        "hex": "#92ACA0",
        "id": "700229"
    },
    "Heather Emerald": {
        "hex": "#1C2821",
        "id": "700230"
    },
    "Heather Forest": {
        "hex": "#095d39",
        "id": "700231"
    },
    "Heather Grass Green": {
        "hex": "#095d39",
        "id": "700232"
    },
    "Heather Green": {
        "hex": "#79863C",
        "id": "700233"
    },
    "Heather Ice Blue": {
        "hex": "#B1E4E3",
        "id": "700234"
    },
    "Heather Kelly": {
        "hex": "#00965E",
        "id": "700235"
    },
    "Heather Lapis": {
        "hex": "#685BC7",
        "id": "700236"
    },
    "Heather Magenta": {
        "hex": "#945E93",
        "id": "700237"
    },
    "Heather Marmalade": {
        "hex": "#FFAA00",
        "id": "700238"
    },
    "Heather Maroon": {
        "hex": "#582D40",
        "id": "700239"
    },
    "Heather Mauve": {
        "hex": "#B17C7B",
        "id": "700240"
    },
    "Heather Midnight Navy": {
        "hex": "#3B4559",
        "id": "700241"
    },
    "Heather Military Green": {
        "hex": "#535435",
        "id": "700242"
    },
    "Heather Mint": {
        "hex": "#A8D5BA",
        "id": "700243"
    },
    "Heather Mustard": {
        "hex": "#DAAA00",
        "id": "700244"
    },
    "Heather Natural": {
        "hex": "#f6e9d6",
        "id": "700245"
    },
    "Heather Olive": {
        "hex": "#7A7256",
        "id": "700246"
    },
    "Heather Orchid": {
        "hex": "#C299A1",
        "id": "700247"
    },
    "Heather Peach": {
        "hex": "#FFC286",
        "id": "700248"
    },
    "Heather Prism Blue": {
        "hex": "#94A9CB",
        "id": "700249"
    },
    "Heather Prism Dusty Blue": {
        "hex": "#92ACA0",
        "id": "700250"
    },
    "Heather Prism Ice Blue": {
        "hex": "#BFCEC2",
        "id": "700251"
    },
    "Heather Prism Lilac": {
        "hex": "#000102",
        "id": "700252"
    },
    "Heather Prism Mint": {
        "hex": "#000102",
        "id": "700253"
    },
    "Heather Prism Natural": {
        "hex": "#000102",
        "id": "700254"
    },
    "Heather Prism Peach": {
        "hex": "#000102",
        "id": "700255"
    },
    "Heather Prism Sunset": {
        "hex": "#000102",
        "id": "700256"
    },
    "Heather Purple": {
        "hex": "#4B2E5C",
        "id": "700257"
    },
    "Heather Raspberry": {
        "hex": "#C13C7E",
        "id": "700258"
    },
    "Heather Sage": {
        "hex": "#95A69F",
        "id": "700259"
    },
    "Heather Sand Dune": {
        "hex": "#000000",
        "id": "700260"
    },
    "Heather Sea Green": {
        "hex": "#00A284",
        "id": "700261"
    },
    "Heather Slate": {
        "hex": "#4e5e69",
        "id": "700262"
    },
    "Heather Stone": {
        "hex": "#969a9c",
        "id": "700263"
    },
    "Heather Storm": {
        "hex": "#645e5e",
        "id": "700264"
    },
    "Heather Sunset": {
        "hex": "#ee7c6f",
        "id": "700265"
    },
    "Heather Tan": {
        "hex": "#c1aa97",
        "id": "700266"
    },
    "Heather Team Purple": {
        "hex": "#4B2E5C",
        "id": "700267"
    },
    "Heather True Royal": {
        "hex": "#2d60a6",
        "id": "700268"
    },
    "Heather Yellow": {
        "hex": "#ffdc72",
        "id": "700269"
    },
    "Heather Yellow Gold": {
        "hex": "#ffdc72",
        "id": "700270"
    },
    "Solid Black Blend": {
        "hex": "#000102",
        "id": "700271"
    },
    "Solid White Blend": {
        "hex": "#f2f0ef",
        "id": "700272"
    },
    "Deep Heather/ Black": {
        "hex": "#716E6A",
        "id": "700273"
    },
    "Heather Blue/ Navy": {
        "hex": "#8E9FBC",
        "id": "700274"
    },
    "Heather Brown/ Brown": {
        "hex": "#5E4B3C",
        "id": "700275"
    },
    "Aqua Triblend": {
        "hex": "#0095C8",
        "id": "700276"
    },
    "Athletic Grey Triblend": {
        "hex": "#898D8D",
        "id": "700277"
    },
    "Berry Triblend": {
        "hex": "#AC145A",
        "id": "700278"
    },
    "Black Heather Triblend": {
        "hex": "#3D3935",
        "id": "700279"
    },
    "Blue Triblend": {
        "hex": "#6787B7",
        "id": "700280"
    },
    "Cardinal Triblend": {
        "hex": "#782F40",
        "id": "700281"
    },
    "Charcoal Black Triblend": {
        "hex": "#3D3935",
        "id": "700282"
    },
    "Clay Triblend": {
        "hex": "#81312F",
        "id": "700283"
    },
    "Denim Triblend": {
        "hex": "#478091",
        "id": "700284"
    },
    "Emerald Triblend": {
        "hex": "#173F35",
        "id": "700285"
    },
    "Grass Green Triblend": {
        "hex": "#095d39",
        "id": "700286"
    },
    "Green Triblend": {
        "hex": "#00C65E",
        "id": "700287"
    },
    "Grey Triblend": {
        "hex": "#7E7F74",
        "id": "700288"
    },
    "Maroon Triblend": {
        "hex": "#48252C",
        "id": "700289"
    },
    "Mauve Triblend": {
        "hex": "#C13C7E",
        "id": "700290"
    },
    "Military Green Triblend": {
        "hex": "#353b23",
        "id": "700291"
    },
    "Mustard Triblend": {
        "hex": "#f0cd64",
        "id": "700292"
    },
    "Navy Triblend": {
        "hex": "#484C6F",
        "id": "700293"
    },
    "Oatmeal Triblend": {
        "hex": "#f3f2ed",
        "id": "700294"
    },
    "Olive Triblend": {
        "hex": "#353b23",
        "id": "700295"
    },
    "Orange Triblend": {
        "hex": "#e77f2b",
        "id": "700296"
    },
    "Peach Triblend": {
        "hex": "#ee7c6f",
        "id": "700297"
    },
    "Purple Triblend": {
        "hex": "#4B2E5C",
        "id": "700298"
    },
    "Red Triblend": {
        "hex": "#ad132a",
        "id": "700299"
    },
    "Sea Green Triblend": {
        "hex": "#0E4C61",
        "id": "700300"
    },
    "Solid Black Triblend": {
        "hex": "#000102",
        "id": "700301"
    },
    "Solid Dark Grey Triblend": {
        "hex": "#484648",
        "id": "700302"
    },
    "Solid Forest Triblend": {
        "hex": "#095d39",
        "id": "700303"
    },
    "Solid Navy Triblend": {
        "hex": "#28283d",
        "id": "700304"
    },
    "Solid Red Triblend": {
        "hex": "#ad132a",
        "id": "700305"
    },
    "Solid White Triblend": {
        "hex": "#FFFFFF",
        "id": "700306"
    },
    "Steel Blue Triblend": {
        "hex": "#66819d",
        "id": "700307"
    },
    "Storm Triblend": {
        "hex": "#645e5e",
        "id": "700308"
    },
    "Tan Triblend": {
        "hex": "#cab9a5",
        "id": "700309"
    },
    "Teal Triblend": {
        "hex": "#0E4C61",
        "id": "700310"
    },
    "True Royal Triblend": {
        "hex": "#2d60a6",
        "id": "700311"
    },
    "White Fleck Triblend": {
        "hex": "#E1DFE1",
        "id": "700312"
    },
    "Yellow Gold Triblend": {
        "hex": "#ffdc72",
        "id": "700313"
    },
    "White Marble": {
        "hex": "#f2f0ef",
        "id": "700314"
    },
    "DTG Black": {
        "hex": "#0A0A0A",
        "id": "700315"
    },
    "DTG Dark Grey": {
        "hex": "#262626",
        "id": "700316"
    },
    "DTG White": {
        "hex": "#FFFFFF",
        "id": "700317"
    },
    "Dark Khaki": {
        "hex": "#6c4f3d",
        "id": "700318"
    },
    "Red/ Black Buffalo": {
        "hex": "#BE0F34",
        "id": "700319"
    },
    "Denim": {
        "hex": "#003e74",
        "id": "700320"
    },
    "Red/ Black": {
        "hex": "#BE0F34",
        "id": "700321"
    },
    "Black/ Grey": {
        "hex": "#000000",
        "id": "700322"
    },
    "Black Plaid": {
        "hex": "#040404",
        "id": "700323"
    },
    "Black/ Heather Charcoal": {
        "hex": "#000000",
        "id": "700324"
    },
    "Heather Navy/ Heather Charcoal": {
        "hex": "#002B49",
        "id": "700325"
    },
    "Black Camo/ Black": {
        "hex": "#040404",
        "id": "700326"
    },
    "Black/ Charcoal": {
        "hex": "#000000",
        "id": "700327"
    },
    "Graphite": {
        "hex": "#474d4e",
        "id": "700328"
    },
    "Safety Orange": {
        "hex": "#ff6600",
        "id": "700329"
    },
    "Safety Yellow": {
        "hex": "#dcff00",
        "id": "700330"
    },
    "Ice Grey": {
        "hex": "#6e7279",
        "id": "700331"
    },
    "Night": {
        "hex": "#2e3862",
        "id": "700332"
    },
    "Stream Blue": {
        "hex": "#A3BEDF",
        "id": "700333"
    },
    "Carbon": {
        "hex": "#6e7279",
        "id": "700334"
    },
    "Mist": {
        "hex": "#BCD5EE",
        "id": "700335"
    },
    "Stream": {
        "hex": "#A3BEDF",
        "id": "700336"
    },
    "What's New Catalog 2023": {
        "hex": "#A0A19C",
        "id": "700337"
    },
    "Color/ Alstyle": {
        "hex": "#ffffff",
        "id": "700338"
    },
    "Color/ Alternative 2024": {
        "hex": "#ffffff",
        "id": "700339"
    },
    "Color/ Bella 2023": {
        "hex": "#ffffff",
        "id": "700340"
    },
    "Color/ Champion 2024": {
        "hex": "#ffffff",
        "id": "700341"
    },
    "Color/ Gildan 2022": {
        "hex": "#ffffff",
        "id": "700342"
    },
    "Color/ Gildan 2023": {
        "hex": "#ffffff",
        "id": "700343"
    },
    "Color/ Gildan 2024": {
        "hex": "#A0A19C",
        "id": "700344"
    },
    "Color/ Independent 2022": {
        "hex": "#ffffff",
        "id": "700345"
    },
    "Color/ Independent 2024": {
        "hex": "#A0A19C",
        "id": "700346"
    },
    "Color/ Jerzees 2023": {
        "hex": "#000000",
        "id": "700347"
    },
    "Color/ Jerzees 2024": {
        "hex": "#ffffff",
        "id": "700348"
    },
    "Color/ M&O 2024": {
        "hex": "#ffffff",
        "id": "700349"
    },
    "Color/ MO 2021": {
        "hex": "#ffffff",
        "id": "700350"
    },
    "Color/ Rabbit Skins 2021": {
        "hex": "#ffffff",
        "id": "700351"
    },
    "Color/ Russell 2023": {
        "hex": "#000000",
        "id": "700352"
    },
    "Color/ Russell Athletic 2022": {
        "hex": "#A0A19C",
        "id": "700353"
    },
    "Light Steel": {
        "hex": "#A7A7A7",
        "id": "700354"
    },
    "Bright Red Scarlet": {
        "hex": "#db183a",
        "id": "700355"
    },
    "Red Scarlet": {
        "hex": "#e11f48",
        "id": "700356"
    },
    "Oxford Grey": {
        "hex": "#909090",
        "id": "700357"
    },
    "Silver Grey": {
        "hex": "#B4B2B1",
        "id": "700358"
    },
    "Athletic Dark Green": {
        "hex": "#274A36",
        "id": "700359"
    },
    "Athletic Royal": {
        "hex": "#293A65",
        "id": "700360"
    },
    "Body Blush": {
        "hex": "#e1bbb4",
        "id": "700361"
    },
    "Dark Green": {
        "hex": "#29513D",
        "id": "700362"
    },
    "Fresh Olive": {
        "hex": "#767555",
        "id": "700363"
    },
    "Oatmeal Heather": {
        "hex": "#DDBC82",
        "id": "700364"
    },
    "Scarlet": {
        "hex": "#BB142F",
        "id": "700365"
    },
    "Stone Grey": {
        "hex": "#7C7876",
        "id": "700366"
    },
    "Columbia Navy": {
        "hex": "#012943",
        "id": "700367"
    },
    "Fossil": {
        "hex": "#99896f",
        "id": "700368"
    },
    "Grill": {
        "hex": "#595d69",
        "id": "700369"
    },
    "Black/ Graphite": {
        "hex": "#000000",
        "id": "700370"
    },
    "City Grey Heather": {
        "hex": "#989495",
        "id": "700371"
    },
    "Chalk Heather": {
        "hex": "#aaa5a9",
        "id": "700372"
    },
    "Black/ Canada Tree Flag": {
        "hex": "#000000",
        "id": "700373"
    },
    "City Grey/ Canada Tree Flag": {
        "hex": "#989495",
        "id": "700374"
    },
    "Blue Jean": {
        "hex": "#647692",
        "id": "700375"
    },
    "Blue Spruce": {
        "hex": "#004C45",
        "id": "700376"
    },
    "Butter": {
        "hex": "#F5E1A4",
        "id": "700377"
    },
    "Chambray": {
        "hex": "#BDD6E6",
        "id": "700378"
    },
    "Crimson": {
        "hex": "#A4123F",
        "id": "700379"
    },
    "Light Green": {
        "hex": "#5C7F71",
        "id": "700380"
    },
    "Pepper": {
        "hex": "#4E4B48",
        "id": "700381"
    },
    "Bay": {
        "hex": "#94A596",
        "id": "700382"
    },
    "Blossom": {
        "hex": "#F8BED6",
        "id": "700383"
    },
    "Brick": {
        "hex": "#8A1538",
        "id": "700384"
    },
    "Chalky Mint": {
        "hex": "#5CB8B2",
        "id": "700385"
    },
    "Flo Blue": {
        "hex": "#5576D1",
        "id": "700386"
    },
    "Granite": {
        "hex": "#7C878E",
        "id": "700387"
    },
    "Ice Blue": {
        "hex": "#5B7F95",
        "id": "700388"
    },
    "Island Reef": {
        "hex": "#8FE2B0",
        "id": "700389"
    },
    "Ivory": {
        "hex": "#E7CEB5",
        "id": "700390"
    },
    "Lagoon": {
        "hex": "#05C3DE",
        "id": "700391"
    },
    "Moss": {
        "hex": "#22372B",
        "id": "700392"
    },
    "Sandstone": {
        "hex": "#A69F88",
        "id": "700393"
    },
    "Seafoam": {
        "hex": "#487A7B",
        "id": "700394"
    },
    "Terracotta": {
        "hex": "#E3775E",
        "id": "700395"
    },
    "Violet": {
        "hex": "#7474C1",
        "id": "700396"
    },
    "Washed Denim": {
        "hex": "#6787B7",
        "id": "700397"
    },
    "Watermelon": {
        "hex": "#F4364C",
        "id": "700398"
    },
    "Yam": {
        "hex": "#BE531C",
        "id": "700399"
    },
    "Black - Duck": {
        "hex": "#1b151a",
        "id": "700400"
    },
    "Tobacco - Duck": {
        "hex": "#524331",
        "id": "700401"
    },
    "Saddle": {
        "hex": "#7a4920",
        "id": "700402"
    },
    "Field Khaki": {
        "hex": "#745436",
        "id": "700403"
    },
    "Black Heather/ Black": {
        "hex": "#1a151a",
        "id": "700404"
    },
    "Dark Oxford": {
        "hex": "#595d5d",
        "id": "700405"
    },
    "Black/ Teal": {
        "hex": "#1b151a",
        "id": "700406"
    },
    "Caramel/ Khaki": {
        "hex": "#6b4a35",
        "id": "700407"
    },
    "Charcoal/ Black": {
        "hex": "#737470",
        "id": "700408"
    },
    "Charcoal/ White": {
        "hex": "#4f4e46",
        "id": "700409"
    },
    "Coyote Brown/ Black": {
        "hex": "#6b4a35",
        "id": "700410"
    },
    "Coyote Brown/ Khaki": {
        "hex": "#6b4a35",
        "id": "700411"
    },
    "Heather Grey/ Black": {
        "hex": "#7b8a8e",
        "id": "700412"
    },
    "Melange Charcoal/ Black": {
        "hex": "#8a9b9e",
        "id": "700413"
    },
    "Melange Silver/ White": {
        "hex": "#d9d5d4",
        "id": "700414"
    },
    "Navy/ White": {
        "hex": "#333e59",
        "id": "700415"
    },
    "Brown": {
        "hex": "#6b4a35",
        "id": "700416"
    },
    "Brown/ Khaki": {
        "hex": "#6b4a35",
        "id": "700417"
    },
    "Dark Navy": {
        "hex": "#222840",
        "id": "700418"
    },
    "Coyote Brown": {
        "hex": "#B5A186",
        "id": "700419"
    },
    "Kryptek Typhon": {
        "hex": "#64625b",
        "id": "700420"
    },
    "Multicam Alpine": {
        "hex": "#32572b",
        "id": "700421"
    },
    "Multicam Black": {
        "hex": "#1c1b19",
        "id": "700422"
    },
    "Multicam Green": {
        "hex": "#32572b",
        "id": "700423"
    },
    "Multicam Tropic": {
        "hex": "#1c1b19",
        "id": "700424"
    },
    "Poseidon Black": {
        "hex": "#000000",
        "id": "700425"
    },
    "Spruce": {
        "hex": "#3c5148",
        "id": "700426"
    },
    "Stone": {
        "hex": "#c8cfcc",
        "id": "700427"
    },
    "Khaki/ Spruce": {
        "hex": "#CCBCAD",
        "id": "700428"
    },
    "Red/ Navy": {
        "hex": "#ee344b",
        "id": "700429"
    },
    "Dark Heather Grey/ Black": {
        "hex": "#64625b",
        "id": "700430"
    },
    "Heather Grey/ Navy": {
        "hex": "#7b8a8e",
        "id": "700431"
    },
    "Heather Grey/ Red": {
        "hex": "#7b8a8e",
        "id": "700432"
    },
    "Heather Grey/ Royal": {
        "hex": "#7b8a8e",
        "id": "700433"
    },
    "Heather Grey/ White": {
        "hex": "#64625b",
        "id": "700434"
    },
    "Dark Heather": {
        "hex": "#64625b",
        "id": "700435"
    },
    "Black/ White/ Black": {
        "hex": "#1b151a",
        "id": "700436"
    },
    "Caramel/ Black": {
        "hex": "#6b4a35",
        "id": "700437"
    },
    "Navy/ White/ Navy": {
        "hex": "#333e59",
        "id": "700438"
    },
    "Red/ White": {
        "hex": "#ee344b",
        "id": "700439"
    },
    "Royal/ White": {
        "hex": "#305791",
        "id": "700440"
    },
    "Grey/ Black": {
        "hex": "#4d464d",
        "id": "700441"
    },
    "Sport Grey": {
        "hex": "#97999B",
        "id": "700442"
    },
    "Forest Green": {
        "hex": "#273B33",
        "id": "700443"
    },
    "Antique Cherry Red": {
        "hex": "#971B2F",
        "id": "700444"
    },
    "Cardinal Red": {
        "hex": "#8A1538",
        "id": "700445"
    },
    "Cherry Red": {
        "hex": "#AC2B37",
        "id": "700446"
    },
    "Dark Chocolate": {
        "hex": "#382F2D",
        "id": "700447"
    },
    "Garnet": {
        "hex": "#7D2935",
        "id": "700448"
    },
    "Heather Dark Green": {
        "hex": "#43695B",
        "id": "700449"
    },
    "Heather Dark Maroon": {
        "hex": "#651D32",
        "id": "700450"
    },
    "Heather Dark Navy": {
        "hex": "#595478",
        "id": "700451"
    },
    "Heather Deep Royal": {
        "hex": "#1D4F91",
        "id": "700452"
    },
    "Heather Scarlet Red": {
        "hex": "#B83A4B",
        "id": "700453"
    },
    "Heliconia": {
        "hex": "#DB3E79",
        "id": "700454"
    },
    "Indigo Blue": {
        "hex": "#486D87",
        "id": "700455"
    },
    "Irish Green": {
        "hex": "#00A74A",
        "id": "700456"
    },
    "Safety Pink": {
        "hex": "#E16F8F",
        "id": "700457"
    },
    "Sapphire": {
        "hex": "#0077B5",
        "id": "700458"
    },
    "Antique Sapphire": {
        "hex": "#006A8E",
        "id": "700459"
    },
    "Azalea": {
        "hex": "#DD74A1",
        "id": "700460"
    },
    "Mint Green": {
        "hex": "#A0CFA8",
        "id": "700461"
    },
    "Old Gold": {
        "hex": "#C39367",
        "id": "700462"
    },
    "Black/ Sport Grey": {
        "hex": "#25282A",
        "id": "700463"
    },
    "Royal/ Sport Grey": {
        "hex": "#224D8F",
        "id": "700464"
    },
    "Sport Grey/ Black": {
        "hex": "#97999B",
        "id": "700465"
    },
    "Blackberry": {
        "hex": "#221C35",
        "id": "700466"
    },
    "Meadow": {
        "hex": "#046A38",
        "id": "700467"
    },
    "Midnight": {
        "hex": "#005670",
        "id": "700468"
    },
    "Tweed": {
        "hex": "#4B4F54",
        "id": "700469"
    },
    "Russet": {
        "hex": "#512F2E",
        "id": "700470"
    },
    "Antique Irish Green": {
        "hex": "#00843D",
        "id": "700471"
    },
    "Antique Royal": {
        "hex": "#003087",
        "id": "700472"
    },
    "Blue Dusk": {
        "hex": "#253746",
        "id": "700473"
    },
    "Cornsilk": {
        "hex": "#f0ec74",
        "id": "700474"
    },
    "Daisy": {
        "hex": "#fed141",
        "id": "700475"
    },
    "Galapagos Blue": {
        "hex": "#005D6F",
        "id": "700476"
    },
    "Heather Indigo": {
        "hex": "#4D6995",
        "id": "700477"
    },
    "Heather Sapphire": {
        "hex": "#0076A8",
        "id": "700478"
    },
    "Iris": {
        "hex": "#3975B7",
        "id": "700479"
    },
    "Jade Dome": {
        "hex": "#008E85",
        "id": "700480"
    },
    "Kiwi": {
        "hex": "#89A84F",
        "id": "700481"
    },
    "Lime": {
        "hex": "#92BF55",
        "id": "700482"
    },
    "Metro Blue": {
        "hex": "#464E7E",
        "id": "700483"
    },
    "PFD White": {
        "hex": "#eeedec",
        "id": "700484"
    },
    "Pistachio": {
        "hex": "#A9C47F",
        "id": "700485"
    },
    "Prairie Dust": {
        "hex": "#7a7256",
        "id": "700486"
    },
    "Sky": {
        "hex": "#71c5e8",
        "id": "700487"
    },
    "Stone Blue": {
        "hex": "#7E93A7",
        "id": "700488"
    },
    "Tangerine": {
        "hex": "#FF8A3D",
        "id": "700489"
    },
    "Texas Orange": {
        "hex": "#B65A30",
        "id": "700490"
    },
    "Vegas Gold": {
        "hex": "#F4D1A1",
        "id": "700491"
    },
    "Plumrose": {
        "hex": "#C9809E",
        "id": "700492"
    },
    "Sage": {
        "hex": "#788A7A",
        "id": "700493"
    },
    "Marbled Charcoal": {
        "hex": "#56565A",
        "id": "700494"
    },
    "Marbled Galapagos Blue": {
        "hex": "#005D83",
        "id": "700495"
    },
    "Marbled Heliconia": {
        "hex": "#D51067",
        "id": "700496"
    },
    "Marbled Navy": {
        "hex": "#012639",
        "id": "700497"
    },
    "Marbled Royal": {
        "hex": "#2E5573",
        "id": "700498"
    },
    "Dark Maroon": {
        "hex": "#572932",
        "id": "700499"
    },
    "Deep Royal": {
        "hex": "#002D72",
        "id": "700500"
    },
    "Heather Sport Black": {
        "hex": "#453536",
        "id": "700501"
    },
    "Heather Sport Orange": {
        "hex": "#FF5C39",
        "id": "700502"
    },
    "Heather Sport Purple": {
        "hex": "#535486",
        "id": "700503"
    },
    "Scarlet Red": {
        "hex": "#BA0C2F",
        "id": "700504"
    },
    "Sport Athletic Gold": {
        "hex": "#FFC845",
        "id": "700505"
    },
    "Sport Light Blue": {
        "hex": "#418FDE",
        "id": "700506"
    },
    "Sport Orange": {
        "hex": "#FC4C02",
        "id": "700507"
    },
    "Sport Purple": {
        "hex": "#470A68",
        "id": "700508"
    },
    "Electric Green": {
        "hex": "#43B02A",
        "id": "700509"
    },
    "Marbled Orange": {
        "hex": "#FF6A39",
        "id": "700510"
    },
    "Marbled Purple": {
        "hex": "#523178",
        "id": "700511"
    },
    "Marbled Sapphire": {
        "hex": "#0081B5",
        "id": "700512"
    },
    "Antique Jade Dome": {
        "hex": "#006269",
        "id": "700513"
    },
    "Antique Orange": {
        "hex": "#B33D26",
        "id": "700514"
    },
    "Brown Savana": {
        "hex": "#7A6855",
        "id": "700515"
    },
    "Cobalt": {
        "hex": "#171C8F",
        "id": "700516"
    },
    "Coral Silk": {
        "hex": "#FB637E",
        "id": "700517"
    },
    "Gravel": {
        "hex": "#888B8D",
        "id": "700518"
    },
    "Heather Radiant Orchid": {
        "hex": "#A15A95",
        "id": "700519"
    },
    "Neon Blue": {
        "hex": "#0C51A3",
        "id": "700520"
    },
    "Neon Green": {
        "hex": "#93DA49",
        "id": "700521"
    },
    "Tennessee Orange": {
        "hex": "#FF8200",
        "id": "700522"
    },
    "Tropical Blue": {
        "hex": "#00859B",
        "id": "700523"
    },
    "Turf Green": {
        "hex": "#007A3E",
        "id": "700524"
    },
    "Yellow Haze": {
        "hex": "#F5E1A4",
        "id": "700525"
    },
    "Sport Grey/ Navy": {
        "hex": "#97999B",
        "id": "700526"
    },
    "Sport Grey/ Red": {
        "hex": "#97999B",
        "id": "700527"
    },
    "Sport Grey/ Royal": {
        "hex": "#97999B",
        "id": "700528"
    },
    "White/ Heliconia": {
        "hex": "#FFFFFF",
        "id": "700529"
    },
    "Antique Heliconia": {
        "hex": "#AA0061",
        "id": "700530"
    },
    "Heather Berry": {
        "hex": "#994878",
        "id": "700531"
    },
    "Heather Cardinal Red": {
        "hex": "#9B2743",
        "id": "700532"
    },
    "Heather Dark Grey": {
        "hex": "#53535A",
        "id": "700533"
    },
    "Heather Galapagos Blue": {
        "hex": "#0093B2",
        "id": "700534"
    },
    "Heather Heliconia": {
        "hex": "#E24585",
        "id": "700535"
    },
    "Heather Irish Green": {
        "hex": "#5CAA7F",
        "id": "700536"
    },
    "Paragon": {
        "hex": "#948794",
        "id": "700537"
    },
    "Heather Aubergine": {
        "hex": "#5D3754",
        "id": "700538"
    },
    "Pitch Black": {
        "hex": "#101820",
        "id": "700539"
    },
    "Amethyst": {
        "hex": "#6C4B94",
        "id": "700540"
    },
    "Cactus": {
        "hex": "#788A7A",
        "id": "700541"
    },
    "Caribbean Mist": {
        "hex": "#5CB8B2",
        "id": "700542"
    },
    "Carolina Blue Mist": {
        "hex": "#7ba4db",
        "id": "700543"
    },
    "Cement": {
        "hex": "#AEAEAE",
        "id": "700544"
    },
    "Dune Mist": {
        "hex": "#7A7256",
        "id": "700545"
    },
    "Dusty Rose": {
        "hex": "#E1BBB4",
        "id": "700546"
    },
    "Gunmetal": {
        "hex": "#939694",
        "id": "700547"
    },
    "Maroon Mist": {
        "hex": "#6D273C",
        "id": "700548"
    },
    "Navy Mist": {
        "hex": "#2C4068",
        "id": "700549"
    },
    "Pitch Black Mist": {
        "hex": "#2D2926",
        "id": "700550"
    },
    "Red Mist": {
        "hex": "#CA3639",
        "id": "700551"
    },
    "Royal Mist": {
        "hex": "#425C7B",
        "id": "700552"
    },
    "Caribbean Blue": {
        "hex": "#00A9CE",
        "id": "700553"
    },
    "True Red": {
        "hex": "#BA0C2F",
        "id": "700554"
    },
    "Heather Caribbean Blue": {
        "hex": "#00AFD7",
        "id": "700555"
    },
    "Smoke": {
        "hex": "#3D3935",
        "id": "700556"
    },
    "Storm Grey": {
        "hex": "#898D8D",
        "id": "700557"
    },
    "Teal Ice": {
        "hex": "#B1E4E3",
        "id": "700558"
    },
    "Black/ Dark Grey": {
        "hex": "#1f2327",
        "id": "700559"
    },
    "Dark Heather/ Dark Grey": {
        "hex": "#53535A",
        "id": "700560"
    },
    "Heather Blue/ Neon Yellow": {
        "hex": "#3A5DAE",
        "id": "700561"
    },
    "Navy/ Dark Grey": {
        "hex": "#263147",
        "id": "700562"
    },
    "True Red/ Dark Grey": {
        "hex": "#BA0C2F",
        "id": "700563"
    },
    "White/ Red-Black": {
        "hex": "#ffffff",
        "id": "700564"
    },
    "Frost Grey": {
        "hex": "#b1aca8",
        "id": "700565"
    },
    "Black Camo": {
        "hex": "#1b1d1e",
        "id": "700566"
    },
    "Blush": {
        "hex": "#EED3D0",
        "id": "700567"
    },
    "Tie Dye Cotton Candy": {
        "hex": "#fbd6e1",
        "id": "700568"
    },
    "Forest Camo": {
        "hex": "#706b57",
        "id": "700569"
    },
    "Gunmetal Heather": {
        "hex": "#7E8083",
        "id": "700570"
    },
    "Army/ Black Zipper": {
        "hex": "#344512",
        "id": "700571"
    },
    "Smoke/ Graphite": {
        "hex": "#D7D7D7",
        "id": "700572"
    },
    "Black/ Forest Camo": {
        "hex": "#1b1d1e",
        "id": "700573"
    },
    "Classic Navy": {
        "hex": "#032E54",
        "id": "700574"
    },
    "Alpine Green": {
        "hex": "#193E34",
        "id": "700575"
    },
    "Army": {
        "hex": "#344512",
        "id": "700576"
    },
    "Blue Aqua": {
        "hex": "#96D8F1",
        "id": "700577"
    },
    "Lavender": {
        "hex": "#E0C1EF",
        "id": "700578"
    },
    "Slate Blue": {
        "hex": "#2c4163",
        "id": "700579"
    },
    "Snow Camo": {
        "hex": "#bab8b8",
        "id": "700580"
    },
    "Solid Charcoal": {
        "hex": "#717374",
        "id": "700581"
    },
    "Charcoal Heather/ Army Heather": {
        "hex": "#2A2A2A",
        "id": "700582"
    },
    "Charcoal Heather/ Black": {
        "hex": "#2A2A2A",
        "id": "700583"
    },
    "Charcoal Heather/ Burgundy Heather": {
        "hex": "#2A2A2A",
        "id": "700584"
    },
    "Gunmetal Heather/ Black": {
        "hex": "#7E8083",
        "id": "700585"
    },
    "Gunmetal Heather/ Charcoal Heather": {
        "hex": "#7E8083",
        "id": "700586"
    },
    "Gunmetal Heather/ Classic Navy Heather": {
        "hex": "#7E8083",
        "id": "700587"
    },
    "Dusty Pink": {
        "hex": "#fbd6e1",
        "id": "700588"
    },
    "Midnight Navy": {
        "hex": "#234163",
        "id": "700589"
    },
    "Nickel": {
        "hex": "#7E8083",
        "id": "700590"
    },
    "Pacific": {
        "hex": "#97C1E5",
        "id": "700591"
    },
    "Pomegranate": {
        "hex": "#CC4855",
        "id": "700592"
    },
    "Red Buffalo Plaid": {
        "hex": "#C10933",
        "id": "700593"
    },
    "Sea Green": {
        "hex": "#78C7B9",
        "id": "700594"
    },
    "Black Cheetah": {
        "hex": "#1b1d1e",
        "id": "700595"
    },
    "Pigment Alpine Green": {
        "hex": "#5A7B7E",
        "id": "700596"
    },
    "Pigment Black": {
        "hex": "#4B4B4B",
        "id": "700597"
    },
    "Pigment Pink": {
        "hex": "#FEA9BE",
        "id": "700598"
    },
    "Pigment Slate Blue": {
        "hex": "#5C7491",
        "id": "700599"
    },
    "Nickel Heather/ Forest Camo": {
        "hex": "#7E8083",
        "id": "700600"
    },
    "Forest Camo Heather": {
        "hex": "#706b57",
        "id": "700601"
    },
    "Shadow": {
        "hex": "#45464A",
        "id": "700602"
    },
    "Misty Blue": {
        "hex": "#8AACC9",
        "id": "700603"
    },
    "Stone Heather": {
        "hex": "#F3F0EB",
        "id": "700604"
    },
    "Pigment Light Blue": {
        "hex": "#97B6E0",
        "id": "700605"
    },
    "Pigment Maroon": {
        "hex": "#A45F77",
        "id": "700606"
    },
    "Pigment Mint": {
        "hex": "#91C7CB",
        "id": "700607"
    },
    "Pigment Sandstone": {
        "hex": "#CDB79F",
        "id": "700608"
    },
    "Pigment Yellow": {
        "hex": "#F9DE92",
        "id": "700609"
    },
    "Prepared For Dye": {
        "hex": "#e7e8e7",
        "id": "700610"
    },
    "Tie Dye Aqua Blue": {
        "hex": "#70c8c3",
        "id": "700611"
    },
    "Tie Dye Black": {
        "hex": "#1b1d1e",
        "id": "700612"
    },
    "Tie Dye Navy": {
        "hex": "#212b3e",
        "id": "700613"
    },
    "Tie Dye Pink": {
        "hex": "#fbd6e1",
        "id": "700614"
    },
    "Tie Dye Sunset Swirl": {
        "hex": "#fbd6e1",
        "id": "700615"
    },
    "Harvest Gold": {
        "hex": "#FAD085",
        "id": "700616"
    },
    "Indigo": {
        "hex": "#212b3e",
        "id": "700617"
    },
    "Port": {
        "hex": "#70383E",
        "id": "700618"
    },
    "Rose": {
        "hex": "#F7C9BD",
        "id": "700619"
    },
    "Army Heather": {
        "hex": "#3A4B08",
        "id": "700620"
    },
    "Classic Navy Heather": {
        "hex": "#02284B",
        "id": "700621"
    },
    "Kelly Green Heather": {
        "hex": "#12895F",
        "id": "700622"
    },
    "Light Yellow": {
        "hex": "#F8E5A9",
        "id": "700623"
    },
    "Neon Pink": {
        "hex": "#FC5980",
        "id": "700624"
    },
    "Peach": {
        "hex": "#FEC282",
        "id": "700625"
    },
    "Black/ Leopard": {
        "hex": "#000000",
        "id": "700626"
    },
    "Dusty Pink/ Floral": {
        "hex": "#c0869b",
        "id": "700627"
    },
    "Lavender/ Stripes": {
        "hex": "#aab3d7",
        "id": "700628"
    },
    "Light Grey/ Polka Dots": {
        "hex": "#b1aca8",
        "id": "700629"
    },
    "White/ Floral": {
        "hex": "#ffffff",
        "id": "700630"
    },
    "Dusty Rose/ White": {
        "hex": "#C48490",
        "id": "700631"
    },
    "Seafoam/ White": {
        "hex": "#95e1c8",
        "id": "700632"
    },
    "Deep Purple": {
        "hex": "#2D1B46",
        "id": "700633"
    },
    "J. Navy": {
        "hex": "#253355",
        "id": "700634"
    },
    "Charcoal Grey": {
        "hex": "#54565b",
        "id": "700635"
    },
    "Oxford": {
        "hex": "#939495",
        "id": "700636"
    },
    "Vintage Heather Navy": {
        "hex": "#26273a",
        "id": "700637"
    },
    "Chocolate": {
        "hex": "#5C4A3B",
        "id": "700638"
    },
    "Classic Pink": {
        "hex": "#EBBECB",
        "id": "700639"
    },
    "Cyber Pink": {
        "hex": "#AA1054",
        "id": "700640"
    },
    "Scuba Blue": {
        "hex": "#47C7C7",
        "id": "700641"
    },
    "Vintage Heather Blue": {
        "hex": "#8996aa",
        "id": "700642"
    },
    "Vintage Heather Red": {
        "hex": "#db3633",
        "id": "700643"
    },
    "Cool Mint": {
        "hex": "#6ECEB2",
        "id": "700644"
    },
    "Mustard Heather": {
        "hex": "#C69214",
        "id": "700645"
    },
    "Retro Heather Coral": {
        "hex": "#FF585E",
        "id": "700646"
    },
    "Rock": {
        "hex": "#939495",
        "id": "700647"
    },
    "Sunset Coral": {
        "hex": "#ee7c6f",
        "id": "700648"
    },
    "Realtree All Purpose": {
        "hex": "#79614D",
        "id": "700649"
    },
    "Realtree Max4": {
        "hex": "#3a3c3a",
        "id": "700650"
    },
    "White Realtree AP": {
        "hex": "#F2F8F8",
        "id": "700651"
    },
    "Mossy Oak BreakUp": {
        "hex": "#413838",
        "id": "700652"
    },
    "Black/ Grey Heather": {
        "hex": "#2D2926",
        "id": "700653"
    },
    "Dark Charcoal/ Grey Heather": {
        "hex": "#343b45",
        "id": "700654"
    },
    "Dark Charcoal/ Heather Purple": {
        "hex": "#343b45",
        "id": "700655"
    },
    "Dark Charcoal/ Heather Red": {
        "hex": "#343b45",
        "id": "700656"
    },
    "Dark Charcoal/ Heather Royal": {
        "hex": "#343b45",
        "id": "700657"
    },
    "Grey Heather/ Black": {
        "hex": "#99a4a8",
        "id": "700658"
    },
    "Grey Heather/ Dark Charcoal": {
        "hex": "#99a4a8",
        "id": "700659"
    },
    "Grey Heather/ Heather Navy": {
        "hex": "#99a4a8",
        "id": "700660"
    },
    "Heather Navy/ Grey Heather": {
        "hex": "#003057",
        "id": "700661"
    },
    "Heather Purple/ Dark Charcoal": {
        "hex": "#654EA3",
        "id": "700662"
    },
    "Heather Red/ Dark Charcoal": {
        "hex": "#BA0C2F",
        "id": "700663"
    },
    "Heather Royal/ Dark Charcoal": {
        "hex": "#1D4F91",
        "id": "700664"
    },
    "Grey Heather/ Navy": {
        "hex": "#99a4a8",
        "id": "700665"
    },
    "Charcoal Mix": {
        "hex": "#66676C",
        "id": "700666"
    },
    "Black/ Kelly": {
        "hex": "#2D2926",
        "id": "700667"
    },
    "Charcoal/ Red": {
        "hex": "#66676C",
        "id": "700668"
    },
    "Navy/ Light Blue": {
        "hex": "#003057",
        "id": "700669"
    },
    "Navy/ Red": {
        "hex": "#003057",
        "id": "700670"
    },
    "Sport Grey/ Forest Green": {
        "hex": "#97999B",
        "id": "700671"
    },
    "Sport Grey/ Purple": {
        "hex": "#97999B",
        "id": "700672"
    },
    "Sport Grey/ Royal Blue": {
        "hex": "#97999B",
        "id": "700673"
    },
    "Navy/ Sport Grey": {
        "hex": "#003057",
        "id": "700674"
    },
    "Camel/ Brown": {
        "hex": "#6b4a35",
        "id": "700675"
    },
    "Navy/ Grey": {
        "hex": "#333e59",
        "id": "700676"
    },
    "Army Camo/ Khaki": {
        "hex": "#51695d",
        "id": "700677"
    },
    "Black/ Khaki": {
        "hex": "#000000",
        "id": "700678"
    },
    "Cardinal/ Khaki": {
        "hex": "#8a1b33",
        "id": "700679"
    },
    "Dark Green/ Khaki": {
        "hex": "#32572b",
        "id": "700680"
    },
    "Grey/ Khaki": {
        "hex": "#b1aca8",
        "id": "700681"
    },
    "Kelly Green/ Khaki": {
        "hex": "#00774f",
        "id": "700682"
    },
    "Navy/ Khaki": {
        "hex": "#333e59",
        "id": "700683"
    },
    "Royal/ Khaki": {
        "hex": "#333e59",
        "id": "700684"
    },
    "Scarlet Red/ Khaki": {
        "hex": "#e11f48",
        "id": "700685"
    },
    "Yellow/ Khaki": {
        "hex": "#eba857",
        "id": "700686"
    },
    "Cantaloupe": {
        "hex": "#EE9572",
        "id": "700687"
    },
    "Heather Fuchsia": {
        "hex": "#B4458D",
        "id": "700688"
    },
    "Iron": {
        "hex": "#5e5e5e",
        "id": "700689"
    },
    "Jet Black Heather": {
        "hex": "#05090c",
        "id": "700690"
    },
    "Neo Mint": {
        "hex": "#A0CFA8",
        "id": "700691"
    },
    "Purist Blue": {
        "hex": "#97c8cb",
        "id": "700692"
    },
    "Heather Graphite": {
        "hex": "#707372",
        "id": "700693"
    },
    "Heather White": {
        "hex": "#dee1ea",
        "id": "700694"
    },
    "Fine Black": {
        "hex": "#25282A",
        "id": "700695"
    },
    "Fine Charcoal": {
        "hex": "#66676C",
        "id": "700696"
    },
    "Fine Navy": {
        "hex": "#263147",
        "id": "700697"
    },
    "Fine Red": {
        "hex": "#BA0C2F",
        "id": "700698"
    },
    "Fine Turquoise": {
        "hex": "#009CBD",
        "id": "700699"
    },
    "Fine White": {
        "hex": "#FFFFFF",
        "id": "700700"
    },
    "Vivid Lime": {
        "hex": "#92BF55",
        "id": "700701"
    },
    "Fine Fuchsia": {
        "hex": "#B4458D",
        "id": "700702"
    },
    "Deep Navy": {
        "hex": "#120c10",
        "id": "700703"
    },
    "Deep Red": {
        "hex": "#b51220",
        "id": "700704"
    },
    "Fine Kelly Green": {
        "hex": "#00805E",
        "id": "700705"
    },
    "Light Blue Heather": {
        "hex": "#A4C8E1",
        "id": "700706"
    },
    "Platinum": {
        "hex": "#A7A7A7",
        "id": "700707"
    },
    "Azure": {
        "hex": "#003087",
        "id": "700708"
    },
    "Ibis Rose": {
        "hex": "#DB3E79",
        "id": "700709"
    },
    "Jet Black": {
        "hex": "#05090c",
        "id": "700710"
    },
    "Aloe": {
        "hex": "#A9C47F",
        "id": "700711"
    },
    "Bright Salmon": {
        "hex": "#FF6D6A",
        "id": "700712"
    },
    "Citrus": {
        "hex": "#FFC27B",
        "id": "700713"
    },
    "Monterey Sage": {
        "hex": "#284734",
        "id": "700714"
    },
    "Periwinkle": {
        "hex": "#485CC7",
        "id": "700715"
    },
    "Royal Caribe": {
        "hex": "#0082BA",
        "id": "700716"
    },
    "Vineyard": {
        "hex": "#672146",
        "id": "700717"
    },
    "Bright Red": {
        "hex": "#b51220",
        "id": "700718"
    },
    "New Forest Green": {
        "hex": "#273B33",
        "id": "700719"
    },
    "Pale Yellow": {
        "hex": "#F5E1A4",
        "id": "700720"
    },
    "Bright Navy": {
        "hex": "#2c3649",
        "id": "700721"
    },
    "Bright Royal": {
        "hex": "#224D8F",
        "id": "700722"
    },
    "Bright Turquoise": {
        "hex": "#00b6d4",
        "id": "700723"
    },
    "Hi-Light": {
        "hex": "#ffffff",
        "id": "700724"
    },
    "Caviar": {
        "hex": "#000000",
        "id": "700725"
    },
    "Pigment": {
        "hex": "#000000",
        "id": "700726"
    },
    "Steel": {
        "hex": "#000000",
        "id": "700727"
    },
    "Athletic Grey Heather": {
        "hex": "#000000",
        "id": "700728"
    },
    "Black Grey": {
        "hex": "#000000",
        "id": "700729"
    },
    "Team Red": {
        "hex": "#ffffff",
        "id": "700730"
    },
    "Bright Grape": {
        "hex": "#ffffff",
        "id": "700731"
    },
    "Classic Green": {
        "hex": "#ffffff",
        "id": "700732"
    },
    "College Navy": {
        "hex": "#FFFFFF",
        "id": "700733"
    },
    "Court Purple": {
        "hex": "#000000",
        "id": "700734"
    },
    "Game Royal": {
        "hex": "#FFFFFF",
        "id": "700735"
    },
    "Geranium": {
        "hex": "#000000",
        "id": "700736"
    },
    "Red Violet": {
        "hex": "#000000",
        "id": "700737"
    },
    "University Blue": {
        "hex": "#ffffff",
        "id": "700738"
    },
    "University Red": {
        "hex": "#ffffff",
        "id": "700739"
    },
    "Pewter Grey ": {
        "hex": "#000000",
        "id": "700740"
    },
    "Blue Void": {
        "hex": "#000000",
        "id": "700741"
    },
    "Team Crimson": {
        "hex": "#000000",
        "id": "700742"
    },
    "Gridiron": {
        "hex": "#ffffff",
        "id": "700743"
    },
    "Sky Grey": {
        "hex": "#ffffff",
        "id": "700744"
    },
    "Team Maroon": {
        "hex": "#000000",
        "id": "700745"
    },
    "Red Line": {
        "hex": "#f80808",
        "id": "700746"
    },
    "Blackout": {
        "hex": "#1b151a",
        "id": "700747"
    },
    "Ozone": {
        "hex": "#0080FF",
        "id": "700748"
    },
    "Balsam": {
        "hex": "#478aa2",
        "id": "700749"
    },
    "Fathom": {
        "hex": "#41577b",
        "id": "700750"
    },
    "Dark Brush": {
        "hex": "#766e54",
        "id": "700751"
    },
    "Rye": {
        "hex": "#F3E5C5",
        "id": "700752"
    },
    "Blackout Light Heather": {
        "hex": "#1b151a",
        "id": "700753"
    },
    "Fathom Heather": {
        "hex": "#1b151a",
        "id": "700754"
    },
    "Dark Blue": {
        "hex": "#333e59",
        "id": "700755"
    },
    "Forged Iron": {
        "hex": "#66676C",
        "id": "700756"
    },
    "Grigio Scuro": {
        "hex": "#64625b",
        "id": "700757"
    },
    "Iron Red": {
        "hex": "#920f26",
        "id": "700758"
    },
    "New Dark Brush": {
        "hex": "#766e54",
        "id": "700759"
    },
    "Universal Blue": {
        "hex": "#2b3953",
        "id": "700760"
    },
    "Navy Blue": {
        "hex": "#333e59",
        "id": "700761"
    },
    "California Blue": {
        "hex": "#2e6297",
        "id": "700762"
    },
    "Puma Black": {
        "hex": "#000000",
        "id": "700763"
    },
    "Pale Pink": {
        "hex": "#ffbdc5",
        "id": "700764"
    },
    "Peacoat": {
        "hex": "#6E7675",
        "id": "700765"
    },
    "Quarry": {
        "hex": "#6E7675",
        "id": "700766"
    },
    "Surf The Web": {
        "hex": "#305791",
        "id": "700767"
    },
    "Fuchsia Heather": {
        "hex": "#db1573",
        "id": "700768"
    },
    "Natural/ Black": {
        "hex": "#ddd5c1",
        "id": "700769"
    },
    "Natural/ Navy": {
        "hex": "#ddd5c1",
        "id": "700770"
    },
    "Black/ Natural/ Light Grey": {
        "hex": "#1c1b19",
        "id": "700771"
    },
    "Forest/ Natural/ Khaki": {
        "hex": "#09322f",
        "id": "700772"
    },
    "Maroon/ Natural/ Light Pink": {
        "hex": "#fcdfe2",
        "id": "700773"
    },
    "Navy/ Natural/ Carolina Blue": {
        "hex": "#a5bbd8",
        "id": "700774"
    },
    "Purple/ Natural/ Lavender": {
        "hex": "#aab3d7",
        "id": "700775"
    },
    "Natural/ Natural": {
        "hex": "#ddd5c1",
        "id": "700776"
    },
    "Natural/ Red": {
        "hex": "#ddd5c1",
        "id": "700777"
    },
    "Natural/ Royal": {
        "hex": "#ddd5c1",
        "id": "700778"
    },
    "Hot Pink": {
        "hex": "#F633CF",
        "id": "700779"
    },
    "Natural/ Forest": {
        "hex": "#ddd5c1",
        "id": "700780"
    },
    "Natural/ Orange": {
        "hex": "#d95917",
        "id": "700781"
    },
    "Heather": {
        "hex": "#ada8a5",
        "id": "700782"
    },
    "Granite Heather": {
        "hex": "#6d6665",
        "id": "700783"
    },
    "Mauvelous": {
        "hex": "#d4b8b9",
        "id": "700784"
    },
    "Natural Heather": {
        "hex": "#ffecd6",
        "id": "700785"
    },
    "Vintage Camo": {
        "hex": "#493c29",
        "id": "700786"
    },
    "Vintage Smoke": {
        "hex": "#302c28",
        "id": "700787"
    },
    "Ballerina": {
        "hex": "#efcbcf",
        "id": "700788"
    },
    "Loden/ Black": {
        "hex": "#777056",
        "id": "700789"
    },
    "Red/ Charcoal": {
        "hex": "#ee344b",
        "id": "700790"
    },
    "Royal/ Charcoal": {
        "hex": "#305791",
        "id": "700791"
    },
    "White/ Charcoal/ Black": {
        "hex": "#e3e7ed",
        "id": "700792"
    },
    "Black/ Vegas Gold": {
        "hex": "#1b151a",
        "id": "700793"
    },
    "Black/ White/ Heather Grey": {
        "hex": "#1b151a",
        "id": "700794"
    },
    "Black/ White/ Red": {
        "hex": "#1b151a",
        "id": "700795"
    },
    "Charcoal/ Navy": {
        "hex": "#64625b",
        "id": "700796"
    },
    "Charcoal/ Neon Blue": {
        "hex": "#8a9b9e",
        "id": "700797"
    },
    "Charcoal/ Neon Orange": {
        "hex": "#4d464d",
        "id": "700798"
    },
    "Charcoal/ Neon Pink": {
        "hex": "#4d464d",
        "id": "700799"
    },
    "Charcoal/ Neon Yellow": {
        "hex": "#4d464d",
        "id": "700800"
    },
    "Charcoal/ Royal": {
        "hex": "#305791",
        "id": "700801"
    },
    "Columbia Blue/ White": {
        "hex": "#5b9ce4",
        "id": "700802"
    },
    "Dark Green/ White": {
        "hex": "#00523e",
        "id": "700803"
    },
    "Dark Green/ White/ Heather Grey": {
        "hex": "#00523e",
        "id": "700804"
    },
    "Grey/ Charcoal/ Black": {
        "hex": "#4d464d",
        "id": "700805"
    },
    "Heather Grey/ Charcoal/ Maroon": {
        "hex": "#b1aca8",
        "id": "700806"
    },
    "Heather Grey/ Dark Green": {
        "hex": "#7b8a8e",
        "id": "700807"
    },
    "Heather Grey/ Light Grey": {
        "hex": "#7b8a8e",
        "id": "700808"
    },
    "Kelly/ White": {
        "hex": "#00774f",
        "id": "700809"
    },
    "Khaki/ Coffee": {
        "hex": "#CCBCAD",
        "id": "700810"
    },
    "Khaki/ White": {
        "hex": "#CCBCAD",
        "id": "700811"
    },
    "Maroon/ White": {
        "hex": "#5d1d2a",
        "id": "700812"
    },
    "Navy/ Charcoal": {
        "hex": "#333e59",
        "id": "700813"
    },
    "Navy/ Orange": {
        "hex": "#333e59",
        "id": "700814"
    },
    "Navy/ White/ Heather Grey": {
        "hex": "#333e59",
        "id": "700815"
    },
    "Purple/ White": {
        "hex": "#3a385e",
        "id": "700816"
    },
    "Red/ White/ Black": {
        "hex": "#ee344b",
        "id": "700817"
    },
    "Red/ White/ Heather Grey": {
        "hex": "#ee344b",
        "id": "700818"
    },
    "Royal/ White/ Heather Grey": {
        "hex": "#305791",
        "id": "700819"
    },
    "Army Olive Green/ Tan": {
        "hex": "#51695d",
        "id": "700820"
    },
    "Heather Grey/ Amber Gold": {
        "hex": "#7b8a8e",
        "id": "700821"
    },
    "Ombre Blue/ Navy": {
        "hex": "#3474a6",
        "id": "700822"
    },
    "Army Camo/ Black": {
        "hex": "#51695d",
        "id": "700823"
    },
    "Army Camo/ White": {
        "hex": "#51695d",
        "id": "700824"
    },
    "Kryptek Typhon/ Black": {
        "hex": "#64625b",
        "id": "700825"
    },
    "Military Digital Camo/ Light Green": {
        "hex": "#51695d",
        "id": "700826"
    },
    "Realtree Edge/ Brown": {
        "hex": "#6b5742",
        "id": "700827"
    },
    "Realtree Original/ Black": {
        "hex": "#6b5742",
        "id": "700828"
    },
    "Black/ Neon Pink": {
        "hex": "#1b151a",
        "id": "700829"
    },
    "Blue Teal/ Birch/ Navy": {
        "hex": "#439AAB",
        "id": "700830"
    },
    "Chocolate Chip/ Grey Brown": {
        "hex": "#614431",
        "id": "700831"
    },
    "Coffee/ Claret": {
        "hex": "#413838",
        "id": "700832"
    },
    "Cyan/ Black": {
        "hex": "#00FFFF",
        "id": "700833"
    },
    "Dark Loden/ Jaffa Orange": {
        "hex": "#175344",
        "id": "700834"
    },
    "Heather Grey/ Birch/ Amber Gold": {
        "hex": "#64625b",
        "id": "700835"
    },
    "Heather Grey/ Dark Charcoal": {
        "hex": "#64625b",
        "id": "700836"
    },
    "Heather Navy/ Light Grey": {
        "hex": "#333e59",
        "id": "700837"
    },
    "Heather Royal/ Light Grey": {
        "hex": "#305791",
        "id": "700838"
    },
    "Hot Pink/ Black": {
        "hex": "#F633CF",
        "id": "700839"
    },
    "Loden": {
        "hex": "#777056",
        "id": "700840"
    },
    "Smoke Blue/ Aluminum": {
        "hex": "#305791",
        "id": "700841"
    },
    "Black/ Gold/ White": {
        "hex": "#1b151a",
        "id": "700842"
    },
    "Black/ Grey/ White": {
        "hex": "#1b151a",
        "id": "700843"
    },
    "Navy/ Grey/ White": {
        "hex": "#333e59",
        "id": "700844"
    },
    "Red/ Black/ White": {
        "hex": "#ee344b",
        "id": "700845"
    },
    "Royal/ Black/ White": {
        "hex": "#305791",
        "id": "700846"
    },
    "Camel": {
        "hex": "#B48148",
        "id": "700847"
    },
    "Birch": {
        "hex": "#605951",
        "id": "700848"
    },
    "Black/ Camo/ Loden": {
        "hex": "#1b151a",
        "id": "700849"
    },
    "Royal/ Black": {
        "hex": "#305791",
        "id": "700850"
    },
    "Black/ Charcoal Split": {
        "hex": "#1b151a",
        "id": "700851"
    },
    "Charcoal/ Black Split": {
        "hex": "#4d464d",
        "id": "700852"
    },
    "Charcoal/ White Split": {
        "hex": "#8a9b9e",
        "id": "700853"
    },
    "Grey/ White/ Black Tri": {
        "hex": "#64625b",
        "id": "700854"
    },
    "Navy/ White/ Red Tri": {
        "hex": "#333e59",
        "id": "700855"
    },
    "Red/ Charcoal/ Black Tri": {
        "hex": "#ee344b",
        "id": "700856"
    },
    "Royal/ Charcoal/ Black Tri": {
        "hex": "#305791",
        "id": "700857"
    },
    "White/ Navy/ Red Tri": {
        "hex": "#ededed",
        "id": "700858"
    },
    "Grey/ Red": {
        "hex": "#dcdddb",
        "id": "700859"
    },
    "Grey/ Royal": {
        "hex": "#747474",
        "id": "700860"
    },
    "Black/ Stone": {
        "hex": "#000000",
        "id": "700861"
    },
    "Brown/ Stone": {
        "hex": "#6b4a35",
        "id": "700862"
    },
    "Charcoal/ Stone": {
        "hex": "#4d464d",
        "id": "700863"
    },
    "Navy/ Stone": {
        "hex": "#333e59",
        "id": "700864"
    },
    "Olive/ Khaki": {
        "hex": "#807c6c",
        "id": "700865"
    },
    "Black/ Silver": {
        "hex": "#1b151a",
        "id": "700866"
    },
    "Fatigue/ Khaki": {
        "hex": "#4e4f3f",
        "id": "700867"
    },
    "Ocean/ Sage": {
        "hex": "#01549A",
        "id": "700868"
    },
    "Black/ Orange": {
        "hex": "#1b151a",
        "id": "700869"
    },
    "Dark Green/ Gold": {
        "hex": "#00523e",
        "id": "700870"
    },
    "Forest/ Gold": {
        "hex": "#09322f",
        "id": "700871"
    },
    "Heather Black/ Heather Grey": {
        "hex": "#1b151a",
        "id": "700872"
    },
    "Maroon/ Gold": {
        "hex": "#5d1d2a",
        "id": "700873"
    },
    "Navy/ Gold": {
        "hex": "#333e59",
        "id": "700874"
    },
    "Grey/ Charcoal": {
        "hex": "#64625b",
        "id": "700875"
    },
    "Pink/ Dark Pink": {
        "hex": "#ee344b",
        "id": "700876"
    },
    "Red/ Maroon": {
        "hex": "#f80808",
        "id": "700877"
    },
    "Royal/ Navy": {
        "hex": "#305791",
        "id": "700878"
    },
    "Black/ Heather Grey": {
        "hex": "#1b151a",
        "id": "700879"
    },
    "Navy/ Heather Grey": {
        "hex": "#333e59",
        "id": "700880"
    },
    "Blaze Orange": {
        "hex": "#f2724d",
        "id": "700881"
    },
    "Heather Dark Royal": {
        "hex": "#305791",
        "id": "700882"
    },
    "Neon Fuchsia": {
        "hex": "#EC2FEE",
        "id": "700883"
    },
    "Black/ Heather": {
        "hex": "#1b151a",
        "id": "700884"
    },
    "Heather/ Black": {
        "hex": "#dcdddb",
        "id": "700885"
    },
    "Heather/ Navy": {
        "hex": "#dcdddb",
        "id": "700886"
    },
    "Dark Heather Grey/ Navy": {
        "hex": "#64625b",
        "id": "700887"
    },
    "Dark Heather Grey/ Red": {
        "hex": "#64625b",
        "id": "700888"
    },
    "Light Heather Grey": {
        "hex": "#7b8a8e",
        "id": "700889"
    },
    "Red/ Gold": {
        "hex": "#ee344b",
        "id": "700890"
    },
    "Royal/ Light Heather Grey": {
        "hex": "#3474a6",
        "id": "700891"
    },
    "Royal/ Red": {
        "hex": "#305791",
        "id": "700892"
    },
    "Cotton Candy": {
        "hex": "#ccb0c9",
        "id": "700893"
    },
    "Purple Passion": {
        "hex": "#3a385e",
        "id": "700894"
    },
    "Raspberry Mist": {
        "hex": "#ffbdc5",
        "id": "700895"
    },
    "Ocean": {
        "hex": "#01549A",
        "id": "700896"
    },
    "Wine": {
        "hex": "#ee344b",
        "id": "700897"
    },
    "Aqua/ Stone": {
        "hex": "#7BC8D0",
        "id": "700898"
    },
    "Forest/ Stone": {
        "hex": "#09322f",
        "id": "700899"
    },
    "Mustard Yellow/ Stone": {
        "hex": "#c68a32",
        "id": "700900"
    },
    "Red/ Stone": {
        "hex": "#ee344b",
        "id": "700901"
    },
    "Royal/ Stone": {
        "hex": "#305791",
        "id": "700902"
    },
    "Black/ White/ Grey/ Red": {
        "hex": "#1b151a",
        "id": "700903"
    },
    "Maroon/ White/ Grey": {
        "hex": "#5d1d2a",
        "id": "700904"
    },
    "Navy/ White/ Grey/ Gold": {
        "hex": "#333e59",
        "id": "700905"
    },
    "Navy/ White/ Grey/ Red": {
        "hex": "#333e59",
        "id": "700906"
    },
    "Red/ White/ Grey/ Black": {
        "hex": "#ee344b",
        "id": "700907"
    },
    "Royal/ White/ Grey": {
        "hex": "#305791",
        "id": "700908"
    },
    "Black/ Natural": {
        "hex": "#1b151a",
        "id": "700909"
    },
    "Grey/ White Speckled": {
        "hex": "#64625b",
        "id": "700910"
    },
    "Maroon/ Natural": {
        "hex": "#DF01A5",
        "id": "700911"
    },
    "Oatmeal": {
        "hex": "#d0bea3",
        "id": "700912"
    },
    "Light Grey/ Charcoal": {
        "hex": "#2E2926",
        "id": "700913"
    },
    "Greyscale": {
        "hex": "#64625b",
        "id": "700914"
    },
    "Pastel": {
        "hex": "#F633CF",
        "id": "700915"
    },
    "Sorbet": {
        "hex": "#ffffff",
        "id": "700916"
    },
    "Pink/ White": {
        "hex": "#c0869b",
        "id": "700917"
    },
    "Green Camo": {
        "hex": "#605951",
        "id": "700918"
    },
    "Grey Camo": {
        "hex": "#747474",
        "id": "700919"
    },
    "Rainbow Tie-Dye": {
        "hex": "#ffffff",
        "id": "700920"
    },
    "Red Paisley": {
        "hex": "#f80808",
        "id": "700921"
    },
    "Black/ Stone Stitch": {
        "hex": "#1b151a",
        "id": "700922"
    },
    "Neon Orange": {
        "hex": "#F47821",
        "id": "700923"
    },
    "Neon Yellow": {
        "hex": "#E7E41B",
        "id": "700924"
    },
    "Pink Camo": {
        "hex": "#c0869b",
        "id": "700925"
    },
    "Sky Blue": {
        "hex": "#7ba0c7",
        "id": "700926"
    },
    "White/ Kelly": {
        "hex": "#e3e7ed",
        "id": "700927"
    },
    "English Blue": {
        "hex": "#526094",
        "id": "700928"
    },
    "Blue Mist": {
        "hex": "#9ab6d9",
        "id": "700929"
    },
    "Danish Blue": {
        "hex": "#526094",
        "id": "700930"
    },
    "French Grey": {
        "hex": "#919193",
        "id": "700931"
    },
    "Blue": {
        "hex": "#b1b9d6",
        "id": "700932"
    },
    "French Blue": {
        "hex": "#12609c",
        "id": "700933"
    },
    "Greystone": {
        "hex": "#919193",
        "id": "700934"
    },
    "Ultra Blue": {
        "hex": "#615D90",
        "id": "700935"
    },
    "Blue Ocean": {
        "hex": "#6d81a3",
        "id": "700936"
    },
    "Kelly/ White/ Kelly": {
        "hex": "#006C47",
        "id": "700937"
    },
    "Multicam Black/ Black": {
        "hex": "#1c1b19",
        "id": "700938"
    },
    "Multicam Tropic/ Green": {
        "hex": "#1c1b19",
        "id": "700939"
    },
    "Royal/ White/ Royal": {
        "hex": "#3474a6",
        "id": "700940"
    },
    "Silver/ Black": {
        "hex": "#d9d5d4",
        "id": "700941"
    },
    "Black/ Camo": {
        "hex": "#1b151a",
        "id": "700942"
    },
    "Black/ Purple": {
        "hex": "#1b151a",
        "id": "700943"
    },
    "Camo/ Black": {
        "hex": "#51695d",
        "id": "700944"
    },
    "Dark Heather/ Black": {
        "hex": "#64625b",
        "id": "700945"
    },
    "Oak": {
        "hex": "#CCBCAD",
        "id": "700946"
    },
    "Light Grey": {
        "hex": "#A2A7A1",
        "id": "700947"
    },
    "Caramel": {
        "hex": "#6b4a35",
        "id": "700948"
    },
    "Evergreen/ White": {
        "hex": "#09322f",
        "id": "700949"
    },
    "Green Camo/ Black": {
        "hex": "#a29189",
        "id": "700950"
    },
    "Maroon/ Grey": {
        "hex": "#5d1d2a",
        "id": "700951"
    },
    "Moss/ Khaki": {
        "hex": "#64625b",
        "id": "700952"
    },
    "Multicam Alpine/ White": {
        "hex": "#32572b",
        "id": "700953"
    },
    "Multicam Green/ Black": {
        "hex": "#32572b",
        "id": "700954"
    },
    "Navy/ Silver": {
        "hex": "#333e59",
        "id": "700955"
    },
    "Rustic Orange/ Khaki": {
        "hex": "#c16c4b",
        "id": "700956"
    },
    "Steel Blue/ Silver": {
        "hex": "#3474a6",
        "id": "700957"
    },
    "Ash Grey***": {
        "hex": "#d4d4d4",
        "id": "700958"
    },
    "Dark Heather**": {
        "hex": "#43464c",
        "id": "700959"
    },
    "Safety Green**^": {
        "hex": "#e2e452",
        "id": "700960"
    },
    "Safety Orange**": {
        "hex": "#f37339",
        "id": "700961"
    },
    "Sport Grey*": {
        "hex": "#929091",
        "id": "700962"
    },
    "Graphite Heather**": {
        "hex": "#8e8e8e",
        "id": "700963"
    },
    "Heather Indigo**": {
        "hex": "#767f8d",
        "id": "700964"
    },
    "Heather Irish Green**": {
        "hex": "#52b57a",
        "id": "700965"
    },
    "Heather Maroon**": {
        "hex": "#623951",
        "id": "700966"
    },
    "Heather Navy**": {
        "hex": "#2e313a",
        "id": "700967"
    },
    "Heather Royal**": {
        "hex": "#758fcc",
        "id": "700968"
    },
    "Heather Red**": {
        "hex": "#d24657",
        "id": "700969"
    },
    "Antique Sapphire*": {
        "hex": "#498fc0",
        "id": "700970"
    },
    "Heather Purple**": {
        "hex": "#6d5a91",
        "id": "700971"
    },
    "Athletic Grey*/Black": {
        "hex": "#c1c1bf",
        "id": "700972"
    },
    "MultiCam\u00c2\u00ae*": {
        "hex": "#584234",
        "id": "700973"
    },
    "MultiCam\u00c2\u00ae Black*": {
        "hex": "#2f2e36",
        "id": "700974"
    },
    "Athletic Grey*": {
        "hex": "#c6c6c6",
        "id": "700975"
    },
    "Charcoal Heather***": {
        "hex": "#434343",
        "id": "700976"
    },
    "Deep Heather**": {
        "hex": "#7e827e",
        "id": "700977"
    },
    "Cardinal Heather***": {
        "hex": "#6b2b3a",
        "id": "700978"
    },
    "Forest Heather***": {
        "hex": "#3d574c",
        "id": "700979"
    },
    "Navy Heather***": {
        "hex": "#445060",
        "id": "700980"
    },
    "Charcoal Heather**": {
        "hex": "#3b3e43",
        "id": "700981"
    },
    "Black Heather**": {
        "hex": "#313031",
        "id": "700982"
    },
    "Charcoal*": {
        "hex": "#383838",
        "id": "700983"
    },
    "Oxford*": {
        "hex": "#6d6c6a",
        "id": "700984"
    },
    "Charcoal Heather*": {
        "hex": "#646363",
        "id": "700985"
    },
    "Black Heather*": {
        "hex": "#1e1e1e",
        "id": "700986"
    },
    "Charcoal*/Charcoal*": {
        "hex": "#3d3d3d",
        "id": "700987"
    },
    "Athletic Grey**": {
        "hex": "#bab9b7",
        "id": "700988"
    },
    "Navy Heather*": {
        "hex": "#323949",
        "id": "700989"
    },
    "Charcoal Grey*": {
        "hex": "#3f3c3d",
        "id": "700990"
    },
    "Athletic Heather*": {
        "hex": "#9f9f9f",
        "id": "700991"
    },
    "Dark Heather Grey**": {
        "hex": "#3d3e40",
        "id": "700992"
    },
    "Oatmeal Heather***": {
        "hex": "#e9e2d8",
        "id": "700993"
    },
    "Neon Blue**": {
        "hex": "#068eb6",
        "id": "700994"
    },
    "Neon Green**": {
        "hex": "#79e077",
        "id": "700995"
    },
    "Neon Orange**": {
        "hex": "#fd875c",
        "id": "700996"
    },
    "Neon Pink**": {
        "hex": "#fd739c",
        "id": "700997"
    },
    "Neon Yellow**": {
        "hex": "#d7f871",
        "id": "700998"
    },
    "Camo*": {
        "hex": "#b18a66",
        "id": "700999"
    },
    "Charcoal Heather*/Black": {
        "hex": "#525250",
        "id": "701000"
    },
    "Grey Heather*": {
        "hex": "#87888d",
        "id": "701001"
    },
    "Dark Grey Heather**": {
        "hex": "#424347",
        "id": "701002"
    },
    "Multicam\u00c2\u00ae*/Black": {
        "hex": "#94765f",
        "id": "701003"
    },
    "Petrol": {
        "hex": "#636365",
        "id": "701004"
    },
    "Wasabe": {
        "hex": "#88ae47",
        "id": "701005"
    },
    "Safety Green^": {
        "hex": "#d6ef06",
        "id": "701006"
    },
    "Black/Silver": {
        "hex": "#494a4f",
        "id": "701007"
    },
    "Black/Diesel Grey/Electric Blue": {
        "hex": "#6a7ea6",
        "id": "701008"
    },
    "Black/Red/Silver": {
        "hex": "#383738",
        "id": "701009"
    },
    "Blizzard/Pink": {
        "hex": "#a3abb3",
        "id": "701010"
    },
    "Cynderfunk/Red": {
        "hex": "#565c68",
        "id": "701011"
    },
    "Stealth": {
        "hex": "#525157",
        "id": "701012"
    },
    "Black/Red": {
        "hex": "#272727",
        "id": "701013"
    },
    "Coal Grey": {
        "hex": "#343a41",
        "id": "701014"
    },
    "Black/Coal Grey": {
        "hex": "#1e1d1b",
        "id": "701015"
    },
    "True Navy/White": {
        "hex": "#2d3a4d",
        "id": "701016"
    },
    "True Red/Black": {
        "hex": "#b72633",
        "id": "701017"
    },
    "True Royal/White": {
        "hex": "#32528b",
        "id": "701018"
    },
    "White/True Navy": {
        "hex": "#e8e8e8",
        "id": "701019"
    },
    "Athletic Grey*/Black Camo": {
        "hex": "#d2d5d4",
        "id": "701020"
    },
    "Athletic Grey*/Cardinal Heather**": {
        "hex": "#c6c8c7",
        "id": "701021"
    },
    "Athletic Grey*/Charcoal Heather**": {
        "hex": "#d0d3d4",
        "id": "701022"
    },
    "Athletic Grey*/Navy Heather**": {
        "hex": "#c0c2c1",
        "id": "701023"
    },
    "White/Black": {
        "hex": "#f7f7f9",
        "id": "701024"
    },
    "White/Camo": {
        "hex": "#f8f9fd",
        "id": "701025"
    },
    "White/Grey Camo": {
        "hex": "#f5f6fa",
        "id": "701026"
    },
    "White/Pink Camo": {
        "hex": "#f7f8fc",
        "id": "701027"
    },
    "White/True Red": {
        "hex": "#f7f7f9",
        "id": "701028"
    },
    "White/True Royal": {
        "hex": "#f6f6f8",
        "id": "701029"
    },
    "Black/Gold/White": {
        "hex": "#d49f41",
        "id": "701030"
    },
    "Black/Gold": {
        "hex": "#0e0e0e",
        "id": "701031"
    },
    "Deep Orange/Black": {
        "hex": "#f05833",
        "id": "701032"
    },
    "True Navy/Gold": {
        "hex": "#1b212d",
        "id": "701033"
    },
    "Purple/White": {
        "hex": "#44306d",
        "id": "701034"
    },
    "True Red/White": {
        "hex": "#af2321",
        "id": "701035"
    },
    "Black/Grey": {
        "hex": "#212121",
        "id": "701036"
    },
    "Black/Royal": {
        "hex": "#2c2c2c",
        "id": "701037"
    },
    "Fresh Green": {
        "hex": "#89c15c",
        "id": "701038"
    },
    "Blue Ribbon": {
        "hex": "#354d8a",
        "id": "701039"
    },
    "Black/Black": {
        "hex": "#1a1a1a",
        "id": "701040"
    },
    "Black/White": {
        "hex": "#2e2e2e",
        "id": "701041"
    },
    "Charcoal/Charcoal": {
        "hex": "#424045",
        "id": "701042"
    },
    "True Navy/True Navy": {
        "hex": "#31384d",
        "id": "701043"
    },
    "Silver/Black": {
        "hex": "#b8bdb9",
        "id": "701044"
    },
    "Dark Grey/Light Grey": {
        "hex": "#585e5a",
        "id": "701045"
    },
    "Red/Red": {
        "hex": "#a9262c",
        "id": "701046"
    },
    "Royal/Royal": {
        "hex": "#24395a",
        "id": "701047"
    },
    "White/White": {
        "hex": "#e7e7e7",
        "id": "701048"
    },
    "Caldera Red": {
        "hex": "#6c2825",
        "id": "701049"
    },
    "Extreme Pink": {
        "hex": "#fe668f",
        "id": "701050"
    },
    "Teal Heather***": {
        "hex": "#306e87",
        "id": "701051"
    },
    "Lime Shock": {
        "hex": "#acca5a",
        "id": "701052"
    },
    "Wild Raspberry": {
        "hex": "#ce477f",
        "id": "701053"
    },
    "Extreme Yellow": {
        "hex": "#eff265",
        "id": "701054"
    },
    "Military Camo": {
        "hex": "#5f5d46",
        "id": "701055"
    },
    "Black/True Red": {
        "hex": "#232323",
        "id": "701056"
    },
    "Black/True Royal": {
        "hex": "#36467e",
        "id": "701057"
    },
    "Athletic Gold": {
        "hex": "#ee9021",
        "id": "701058"
    },
    "Chrome": {
        "hex": "#9a9a9a",
        "id": "701059"
    },
    "Tropical Pink": {
        "hex": "#ee3089",
        "id": "701060"
    },
    "Bright Orange": {
        "hex": "#ed5a26",
        "id": "701061"
    },
    "Hunter": {
        "hex": "#002d20",
        "id": "701062"
    },
    "Athletic Green": {
        "hex": "#022f24",
        "id": "701063"
    },
    "Concrete": {
        "hex": "#78756c",
        "id": "701064"
    },
    "Neon Lime": {
        "hex": "#9ae063",
        "id": "701065"
    },
    "Raspberry": {
        "hex": "#cf3e8d",
        "id": "701066"
    },
    "Iron Grey": {
        "hex": "#45484d",
        "id": "701067"
    },
    "Pink Raspberry": {
        "hex": "#cf2870",
        "id": "701068"
    },
    "Authentic Blue": {
        "hex": "#0199c8",
        "id": "701069"
    },
    "Realtree Max-5\u00c2\u00ae": {
        "hex": "#ccc6b5",
        "id": "701070"
    },
    "Realtree Xtra\u00c2\u00ae": {
        "hex": "#2b2725",
        "id": "701071"
    },
    "Realtree Hardwoods HD\u00e2\u201e\u00a2": {
        "hex": "#6a6c60",
        "id": "701072"
    },
    "Black/Khaki": {
        "hex": "#202020",
        "id": "701073"
    },
    "Coal Grey/Black": {
        "hex": "#56595e",
        "id": "701074"
    },
    "Hunter/Khaki": {
        "hex": "#345648",
        "id": "701075"
    },
    "Khaki/Navy": {
        "hex": "#a79b85",
        "id": "701076"
    },
    "Navy/Gold": {
        "hex": "#2f3d4a",
        "id": "701077"
    },
    "Navy/White": {
        "hex": "#2b3946",
        "id": "701078"
    },
    "Royal/White": {
        "hex": "#31418b",
        "id": "701079"
    },
    "Stone/Black": {
        "hex": "#cbbfa5",
        "id": "701080"
    },
    "White/Navy": {
        "hex": "#f4f4f4",
        "id": "701081"
    },
    "Bright White": {
        "hex": "#f4f5f9",
        "id": "701082"
    },
    "Chili Pepper": {
        "hex": "#a61c38",
        "id": "701083"
    },
    "Peacoat Navy": {
        "hex": "#151d33",
        "id": "701084"
    },
    "Smoked Pearl": {
        "hex": "#585858",
        "id": "701085"
    },
    "Caviar Heather": {
        "hex": "#626264",
        "id": "701086"
    },
    "Medium Blue": {
        "hex": "#006e9f",
        "id": "701087"
    },
    "Quiet Shade": {
        "hex": "#585a5c",
        "id": "701088"
    },
    "Salsa Red": {
        "hex": "#bd2030",
        "id": "701089"
    },
    "Vibrant Green": {
        "hex": "#32a443",
        "id": "701090"
    },
    "Monument": {
        "hex": "#797c7b",
        "id": "701091"
    },
    "Palace Blue": {
        "hex": "#467ec1",
        "id": "701092"
    },
    "Coffee Bean": {
        "hex": "#4d3c2c",
        "id": "701093"
    },
    "Court Green": {
        "hex": "#0d945b",
        "id": "701094"
    },
    "Light Stone": {
        "hex": "#dbd6c5",
        "id": "701095"
    },
    "Mediterranean Blue": {
        "hex": "#3a4a81",
        "id": "701096"
    },
    "Deep Blue": {
        "hex": "#3d5071",
        "id": "701097"
    },
    "Grape Harvest": {
        "hex": "#746488",
        "id": "701098"
    },
    "Navy Frost": {
        "hex": "#b7bbc4",
        "id": "701099"
    },
    "Oxford Blue": {
        "hex": "#b5bcd9",
        "id": "701100"
    },
    "Soft Black": {
        "hex": "#525252",
        "id": "701101"
    },
    "Grey/White": {
        "hex": "#7a878d",
        "id": "701102"
    },
    "Aubergine Purple": {
        "hex": "#c7bec4",
        "id": "701103"
    },
    "Moonlight Blue": {
        "hex": "#d4e1ea",
        "id": "701104"
    },
    "Wintergreen": {
        "hex": "#c1cdbf",
        "id": "701105"
    },
    "Blue Lake": {
        "hex": "#85a0cd",
        "id": "701106"
    },
    "Rich Red": {
        "hex": "#7a2135",
        "id": "701107"
    },
    "True White": {
        "hex": "#e0e3ea",
        "id": "701108"
    },
    "Mineral Green": {
        "hex": "#4c503f",
        "id": "701109"
    },
    "Goldenrod Yellow": {
        "hex": "#c2a038",
        "id": "701110"
    },
    "Jester Red": {
        "hex": "#d12039",
        "id": "701111"
    },
    "Asphalt/Black": {
        "hex": "#4f5356",
        "id": "701112"
    },
    "Glacier Grey/Black": {
        "hex": "#d0d1cc",
        "id": "701113"
    },
    "Asphalt Heather": {
        "hex": "#656661",
        "id": "701114"
    },
    "Glacier Heather": {
        "hex": "#c8c5bc",
        "id": "701115"
    },
    "Glacier Grey": {
        "hex": "#edeee8",
        "id": "701116"
    },
    "Cayenne Orange": {
        "hex": "#ba3b27",
        "id": "701117"
    },
    "Deep Sea Blue": {
        "hex": "#e6e6e6",
        "id": "701118"
    },
    "Iron Gate": {
        "hex": "#484b50",
        "id": "701119"
    },
    "Very Berry": {
        "hex": "#942159",
        "id": "701120"
    },
    "Grey Steel": {
        "hex": "#44484b",
        "id": "701121"
    },
    "Peak Blue": {
        "hex": "#0089ba",
        "id": "701122"
    },
    "Black/Grey Steel": {
        "hex": "#292929",
        "id": "701123"
    },
    "Evergreen/Grey Steel": {
        "hex": "#3a462c",
        "id": "701124"
    },
    "Grey Steel/Black": {
        "hex": "#3a3b40",
        "id": "701125"
    },
    "Concord Purple/Grey Steel": {
        "hex": "#4d1a39",
        "id": "701126"
    },
    "Canteen Grey": {
        "hex": "#615146",
        "id": "701127"
    },
    "Cobalt Blue": {
        "hex": "#05488f",
        "id": "701128"
    },
    "Pink Raspberry/White": {
        "hex": "#bf2970",
        "id": "701129"
    },
    "Coal Grey/Coal Grey": {
        "hex": "#625b57",
        "id": "701130"
    },
    "Navy/Charcoal*": {
        "hex": "#3d3850",
        "id": "701131"
    },
    "Navy/Navy": {
        "hex": "#383346",
        "id": "701132"
    },
    "Raspberry/Raspberry": {
        "hex": "#ce5392",
        "id": "701133"
    },
    "Deep Orange": {
        "hex": "#ef6b2a",
        "id": "701134"
    },
    "Black/Charcoal": {
        "hex": "#1f1f1c",
        "id": "701135"
    },
    "Charcoal/Black": {
        "hex": "#343132",
        "id": "701136"
    },
    "Charcoal/Lime Shock": {
        "hex": "#373633",
        "id": "701137"
    },
    "Charcoal/True Red": {
        "hex": "#d62231",
        "id": "701138"
    },
    "Charcoal/True Royal": {
        "hex": "#313b7e",
        "id": "701139"
    },
    "Athletic  Heather**": {
        "hex": "#cecece",
        "id": "701140"
    },
    "Sport Heather Grey": {
        "hex": "#9b9b9c",
        "id": "701141"
    },
    "Glacier Blue": {
        "hex": "#235168",
        "id": "701142"
    },
    "Chive Green": {
        "hex": "#2f6a34",
        "id": "701143"
    },
    "Light Royal": {
        "hex": "#215c90",
        "id": "701144"
    },
    "Imperial Blue": {
        "hex": "#005b90",
        "id": "701145"
    },
    "Midnight Blue": {
        "hex": "#082137",
        "id": "701146"
    },
    "Pearl Grey Heather*": {
        "hex": "#515256",
        "id": "701147"
    },
    "Black/Graphite": {
        "hex": "#1b1b1b",
        "id": "701148"
    },
    "Graphite/Winter White": {
        "hex": "#414546",
        "id": "701149"
    },
    "Citron": {
        "hex": "#a2a743",
        "id": "701150"
    },
    "Charcoal Triblend": {
        "hex": "#353533",
        "id": "701151"
    },
    "Royal Triblend": {
        "hex": "#5263a9",
        "id": "701152"
    },
    "Extreme Orange": {
        "hex": "#e85521",
        "id": "701153"
    },
    "Blue Wake Heather": {
        "hex": "#408fb0",
        "id": "701154"
    },
    "Cardinal Heather": {
        "hex": "#9e414c",
        "id": "701155"
    },
    "Cobalt Heather": {
        "hex": "#e96949",
        "id": "701156"
    },
    "Deep Orange Heather": {
        "hex": "#316244",
        "id": "701157"
    },
    "Forest Green Heather": {
        "hex": "#545a5b",
        "id": "701158"
    },
    "Raspberry Heather": {
        "hex": "#e05282",
        "id": "701159"
    },
    "Turf Green Heather": {
        "hex": "#6cb65f",
        "id": "701160"
    },
    "Black/Extreme Orange": {
        "hex": "#f5933b",
        "id": "701161"
    },
    "Black/Extreme Yellow": {
        "hex": "#d1db59",
        "id": "701162"
    },
    "Carolina Blue/Coal Grey": {
        "hex": "#5b9ac7",
        "id": "701163"
    },
    "Gold/Black": {
        "hex": "#f9ad1f",
        "id": "701164"
    },
    "Lime Shock/True Navy": {
        "hex": "#9cc84f",
        "id": "701165"
    },
    "Raspberry/White": {
        "hex": "#ed4a8d",
        "id": "701166"
    },
    "Atomic Blue": {
        "hex": "#00afde",
        "id": "701167"
    },
    "Graphite Heather/Black": {
        "hex": "#636363",
        "id": "701168"
    },
    "True Navy Heather/True Navy": {
        "hex": "#232a3c",
        "id": "701169"
    },
    "Raspberry Heather/Wild Raspberry": {
        "hex": "#c75a84",
        "id": "701170"
    },
    "True Royal Heather/True Royal": {
        "hex": "#354290",
        "id": "701171"
    },
    "Scarlet Red Heather/True Red": {
        "hex": "#ae373d",
        "id": "701172"
    },
    "Faded Olive": {
        "hex": "#817f68",
        "id": "701173"
    },
    "Honeydew": {
        "hex": "#bbd594",
        "id": "701174"
    },
    "Black/Iron Grey": {
        "hex": "#4a4d54",
        "id": "701175"
    },
    "Iron Grey/Black": {
        "hex": "#42454c",
        "id": "701176"
    },
    "Kelly Green/White": {
        "hex": "#31875a",
        "id": "701177"
    },
    "Black/Lime": {
        "hex": "#272226",
        "id": "701178"
    },
    "Black/Orange": {
        "hex": "#2d282c",
        "id": "701179"
    },
    "Black/Raspberry": {
        "hex": "#2e2a2b",
        "id": "701180"
    },
    "Iron Grey/Lime": {
        "hex": "#3b3f47",
        "id": "701181"
    },
    "Iron Grey/Red": {
        "hex": "#383c42",
        "id": "701182"
    },
    "Iron Grey/Royal": {
        "hex": "#474b53",
        "id": "701183"
    },
    "Iron Grey/White": {
        "hex": "#383d42",
        "id": "701184"
    },
    "^Safety Yellow": {
        "hex": "#eefa70",
        "id": "701185"
    },
    "Blue Lake/Iron Grey": {
        "hex": "#78a5cf",
        "id": "701186"
    },
    "White/Iron Grey": {
        "hex": "#e6e6e6",
        "id": "701187"
    },
    "Brilliant Blue": {
        "hex": "#017eb8",
        "id": "701188"
    },
    "Steel Grey": {
        "hex": "#50504e",
        "id": "701189"
    },
    "Black/Neon Orange": {
        "hex": "#262626",
        "id": "701190"
    },
    "Black/Neon Yellow": {
        "hex": "#252525",
        "id": "701191"
    },
    "Red/Steel Grey": {
        "hex": "#ac2136",
        "id": "701192"
    },
    "Royal/Steel Grey": {
        "hex": "#16294b",
        "id": "701193"
    },
    "Steel Grey/Steel Grey": {
        "hex": "#4c4c49",
        "id": "701194"
    },
    "White/Steel Grey": {
        "hex": "#e2e2e2",
        "id": "701195"
    },
    "Black/Steel Grey": {
        "hex": "#252525",
        "id": "701196"
    },
    "Blue Wake": {
        "hex": "#0083a8",
        "id": "701197"
    },
    "Dark Fuchsia": {
        "hex": "#b21e50",
        "id": "701198"
    },
    "True Blue": {
        "hex": "#204981",
        "id": "701199"
    },
    "Vine Green": {
        "hex": "#4e8a3f",
        "id": "701200"
    },
    "Grey Concrete": {
        "hex": "#755f51",
        "id": "701201"
    },
    "Bark": {
        "hex": "#838381",
        "id": "701202"
    },
    "Faded Blue": {
        "hex": "#e6e6e6",
        "id": "701203"
    },
    "Hibiscus": {
        "hex": "#ef6563",
        "id": "701204"
    },
    "Blue Surf": {
        "hex": "#79cee2",
        "id": "701205"
    },
    "Green Oasis": {
        "hex": "#b1cf78",
        "id": "701206"
    },
    "^Safety Green": {
        "hex": "#ebf96e",
        "id": "701207"
    },
    "^Safety Orange": {
        "hex": "#e3592b",
        "id": "701208"
    },
    "Tropic Blue": {
        "hex": "#4f8d9a",
        "id": "701209"
    },
    "Prism Navy": {
        "hex": "#353243",
        "id": "701210"
    },
    "Prism Orange": {
        "hex": "#c94530",
        "id": "701211"
    },
    "Prism Red": {
        "hex": "#a62b2e",
        "id": "701212"
    },
    "Prism Royal": {
        "hex": "#343875",
        "id": "701213"
    },
    "Prism Black": {
        "hex": "#292728",
        "id": "701214"
    },
    "Pink Orchid": {
        "hex": "#dba0be",
        "id": "701215"
    },
    "Winter White/Graphite": {
        "hex": "#e8eae8",
        "id": "701216"
    },
    "Blacktop": {
        "hex": "#272725",
        "id": "701217"
    },
    "Electric Blue": {
        "hex": "#2e5f97",
        "id": "701218"
    },
    "Gear Grey": {
        "hex": "#6b6a65",
        "id": "701219"
    },
    "Pace Yellow": {
        "hex": "#d0de53",
        "id": "701220"
    },
    "Ripped Red": {
        "hex": "#a22b31",
        "id": "701221"
    },
    "Diesel Grey": {
        "hex": "#555553",
        "id": "701222"
    },
    "Gridiron Green": {
        "hex": "#6e8245",
        "id": "701223"
    },
    "Signal Red": {
        "hex": "#982d2d",
        "id": "701224"
    },
    "Yield Yellow": {
        "hex": "#d6b33d",
        "id": "701225"
    },
    "Pink Crush": {
        "hex": "#b82d66",
        "id": "701226"
    },
    "Blush Red": {
        "hex": "#922238",
        "id": "701227"
    },
    "Fuel Green": {
        "hex": "#004746",
        "id": "701228"
    },
    "Blue Steel": {
        "hex": "#2e5b94",
        "id": "701229"
    },
    "Purple Haze": {
        "hex": "#6f5585",
        "id": "701230"
    },
    "Blueprint": {
        "hex": "#262e45",
        "id": "701231"
    },
    "Bolt Blue": {
        "hex": "#015f92",
        "id": "701232"
    },
    "Nitro Yellow": {
        "hex": "#d6de39",
        "id": "701233"
    },
    "Pink Punch": {
        "hex": "#b2243c",
        "id": "701234"
    },
    "Diesel Grey/Acid Green": {
        "hex": "#4b4547",
        "id": "701235"
    },
    "Deep Navy/Deep Navy": {
        "hex": "#242f42",
        "id": "701236"
    },
    "Deep Navy/White": {
        "hex": "#344058",
        "id": "701237"
    },
    "Graphite/Cyber Green": {
        "hex": "#54545d",
        "id": "701238"
    },
    "Grey/Black": {
        "hex": "#383031",
        "id": "701239"
    },
    "Scarlet/White": {
        "hex": "#8c1d24",
        "id": "701240"
    },
    "Deep Navy/Gold": {
        "hex": "#151c27",
        "id": "701241"
    },
    "Black/Graphite/White": {
        "hex": "#72716f",
        "id": "701242"
    },
    "Royal/Grey/White": {
        "hex": "#384975",
        "id": "701243"
    },
    "Black/Scarlet": {
        "hex": "#d56364",
        "id": "701244"
    },
    "Camo/Black": {
        "hex": "#9a8e65",
        "id": "701245"
    },
    "Graphite/Black": {
        "hex": "#3b3a36",
        "id": "701246"
    },
    "White/Gold/Dark Green": {
        "hex": "#ecb943",
        "id": "701247"
    },
    "White/Grey/Deep Navy": {
        "hex": "#1e2a3b",
        "id": "701248"
    },
    "White/Scarlet/Deep Navy": {
        "hex": "#f0f0f0",
        "id": "701249"
    },
    "Slate Grey": {
        "hex": "#373739",
        "id": "701250"
    },
    "Blue Enamel": {
        "hex": "#00639a",
        "id": "701251"
    },
    "Purple Luxe": {
        "hex": "#5a1e51",
        "id": "701252"
    },
    "Alloy Green": {
        "hex": "#716f32",
        "id": "701253"
    },
    "Astro Green": {
        "hex": "#81a05d",
        "id": "701254"
    },
    "Carolina Blue/White": {
        "hex": "#dae1ec",
        "id": "701255"
    },
    "Forest Green/White": {
        "hex": "#3a4d41",
        "id": "701256"
    },
    "Charcoal Heather/Black": {
        "hex": "#8b8b8b",
        "id": "701257"
    },
    "Charcoal Heather/Deep Orange": {
        "hex": "#db4f24",
        "id": "701258"
    },
    "Charcoal Heather/Forest Green": {
        "hex": "#1a3222",
        "id": "701259"
    },
    "Charcoal Heather/True Navy": {
        "hex": "#1f1e2e",
        "id": "701260"
    },
    "Charcoal Heather/Purple": {
        "hex": "#7f7f7f",
        "id": "701261"
    },
    "Charcoal Heather/True Red": {
        "hex": "#c01e2b",
        "id": "701262"
    },
    "Charcoal Heather/True Royal": {
        "hex": "#293176",
        "id": "701263"
    },
    "White/Carolina Blue": {
        "hex": "#65a3d2",
        "id": "701264"
    },
    "White/Coal Grey": {
        "hex": "#5d6164",
        "id": "701265"
    },
    "White/Deep Orange": {
        "hex": "#ed6122",
        "id": "701266"
    },
    "White/Forest Green": {
        "hex": "#193020",
        "id": "701267"
    },
    "White/Gold": {
        "hex": "#f3a720",
        "id": "701268"
    },
    "White/Lime Shock": {
        "hex": "#a4cd53",
        "id": "701269"
    },
    "White/Maroon": {
        "hex": "#422021",
        "id": "701270"
    },
    "White/Purple": {
        "hex": "#4b307f",
        "id": "701271"
    },
    "Lime Shock/Black": {
        "hex": "#a5c653",
        "id": "701272"
    },
    "True Royal/Black": {
        "hex": "#354288",
        "id": "701273"
    },
    "Steel Grey/Steel": {
        "hex": "#50504e",
        "id": "701274"
    },
    "Charcoal/Grey": {
        "hex": "#333335",
        "id": "701275"
    },
    "Charcoal/Royal Blue": {
        "hex": "#2d589b",
        "id": "701276"
    },
    "Navy/Grey": {
        "hex": "#1e2432",
        "id": "701277"
    },
    "Black Inseam 30\"": {
        "hex": "#202020",
        "id": "701278"
    },
    "Black Inseam 32\"": {
        "hex": "#161616",
        "id": "701279"
    },
    "Black Inseam 34\"": {
        "hex": "#242424",
        "id": "701280"
    },
    "Charcoal Inseam 30\"": {
        "hex": "#413f40",
        "id": "701281"
    },
    "Charcoal Inseam 32\"": {
        "hex": "#514f50",
        "id": "701282"
    },
    "Charcoal Inseam 34\"": {
        "hex": "#525051",
        "id": "701283"
    },
    "Navy Inseam 30\"": {
        "hex": "#263b56",
        "id": "701284"
    },
    "Navy Inseam 32\"": {
        "hex": "#283d5a",
        "id": "701285"
    },
    "Navy Inseam 34\"": {
        "hex": "#243a52",
        "id": "701286"
    },
    "Iron Grey/True Royal": {
        "hex": "#223f89",
        "id": "701287"
    },
    "True Navy/Iron Grey": {
        "hex": "#1a2239",
        "id": "701288"
    },
    "Sangria": {
        "hex": "#cd4f7e",
        "id": "701289"
    },
    "Fatigue Green": {
        "hex": "#657359",
        "id": "701290"
    },
    "Medium Grey": {
        "hex": "#8f9291",
        "id": "701291"
    },
    "Black Dynamic": {
        "hex": "#222",
        "id": "701292"
    },
    "Charcoal Dynamic": {
        "hex": "#404040",
        "id": "701293"
    },
    "True Royal Dynamic": {
        "hex": "#465481",
        "id": "701294"
    },
    "Gear Grey/Black": {
        "hex": "#43434d",
        "id": "701295"
    },
    "Black/Realtree Xtra\u00c2\u00ae": {
        "hex": "#7c5c43",
        "id": "701296"
    },
    "Aquatic Blue": {
        "hex": "#5c9fc9",
        "id": "701297"
    },
    "Bright Aqua": {
        "hex": "#009996",
        "id": "701298"
    },
    "Candy Pink": {
        "hex": "#f1a1c5",
        "id": "701299"
    },
    "Clover Green": {
        "hex": "#01924f",
        "id": "701300"
    },
    "Dark Sand": {
        "hex": "#b1997d",
        "id": "701301"
    },
    "Dark Chocolate Brown": {
        "hex": "#41322b",
        "id": "701302"
    },
    "Woodland Brown": {
        "hex": "#84664e",
        "id": "701303"
    },
    "Magnetic Blue": {
        "hex": "#186ca9",
        "id": "701304"
    },
    "Provence Blue": {
        "hex": "#7b9ccf",
        "id": "701305"
    },
    "Athletic Grey/Black": {
        "hex": "#22211f",
        "id": "701306"
    },
    "Forest Green/Black": {
        "hex": "#1c1b17",
        "id": "701307"
    },
    "Maroon/Black": {
        "hex": "#662634",
        "id": "701308"
    },
    "True Navy/Black": {
        "hex": "#1e2b3e",
        "id": "701309"
    },
    "Charcoal Dynamic/Black": {
        "hex": "#494949",
        "id": "701310"
    },
    "Charcoal Dynamic/Coal Grey": {
        "hex": "#736b68",
        "id": "701311"
    },
    "Charcoal Dynamic/Forest Green": {
        "hex": "#253a27",
        "id": "701312"
    },
    "Charcoal Dynamic/Maroon": {
        "hex": "#4e1926",
        "id": "701313"
    },
    "Charcoal Dynamic/True Navy": {
        "hex": "#182339",
        "id": "701314"
    },
    "Charcoal Dynamic/True Red": {
        "hex": "#d42431",
        "id": "701315"
    },
    "Charcoal Dynamic/True Royal": {
        "hex": "#2d3272",
        "id": "701316"
    },
    "Charcoal Dynamic/White": {
        "hex": "#505050",
        "id": "701317"
    },
    "Coal Grey/Grey": {
        "hex": "#52575c",
        "id": "701318"
    },
    "Coal Grey/White": {
        "hex": "#4f5155",
        "id": "701319"
    },
    "Forest/White": {
        "hex": "#2a3f34",
        "id": "701320"
    },
    "Maroon/White": {
        "hex": "#511f2b",
        "id": "701321"
    },
    "Red/White": {
        "hex": "#c3293d",
        "id": "701322"
    },
    "Black Heather*/Charcoal Heather": {
        "hex": "#414548",
        "id": "701323"
    },
    "Cardinal Heather/Charcoal Heather": {
        "hex": "#6d3542",
        "id": "701324"
    },
    "Forest Heather/Charcoal Heather": {
        "hex": "#1e382f",
        "id": "701325"
    },
    "Navy Heather/Charcoal Heather": {
        "hex": "#455565",
        "id": "701326"
    },
    "Onyx": {
        "hex": "#130f10",
        "id": "701327"
    },
    "Olive Green": {
        "hex": "#77775b",
        "id": "701328"
    },
    "Red Jasper": {
        "hex": "#cf4755",
        "id": "701329"
    },
    "Dawn": {
        "hex": "#e9d3c6",
        "id": "701330"
    },
    "Dusk": {
        "hex": "#bcbaae",
        "id": "701331"
    },
    "Azure Blue": {
        "hex": "#6b9bc5",
        "id": "701332"
    },
    "Cypress": {
        "hex": "#617667",
        "id": "701333"
    },
    "Pumpkin": {
        "hex": "#e86d4b",
        "id": "701334"
    },
    "City Blue": {
        "hex": "#8cb3da",
        "id": "701335"
    },
    "Platinum Grey": {
        "hex": "#828c95",
        "id": "701336"
    },
    "Emerald": {
        "hex": "#007c57",
        "id": "701337"
    },
    "Regatta Blue": {
        "hex": "#23519f",
        "id": "701338"
    },
    "Blueprint Heather": {
        "hex": "#303d66",
        "id": "701339"
    },
    "Castlerock Heather": {
        "hex": "#4e4f4a",
        "id": "701340"
    },
    "Highrise Heather": {
        "hex": "#e7e7e5",
        "id": "701341"
    },
    "Blue Atoll": {
        "hex": "#6bcbde",
        "id": "701342"
    },
    "Graphite Grey": {
        "hex": "#7f7774",
        "id": "701343"
    },
    "Mid Grey": {
        "hex": "#c0bfc4",
        "id": "701344"
    },
    "Carolina Blue/True Navy": {
        "hex": "#618eb8",
        "id": "701345"
    },
    "Navy Heather*/True Navy": {
        "hex": "#3d4a5a",
        "id": "701346"
    },
    "Black/Autumn Gold": {
        "hex": "#131313",
        "id": "701347"
    },
    "Black/Glacier": {
        "hex": "#191919",
        "id": "701348"
    },
    "Black/Rich Red": {
        "hex": "#e5393d",
        "id": "701349"
    },
    "Black/Regatta Blue": {
        "hex": "#204e99",
        "id": "701350"
    },
    "Black/Iron": {
        "hex": "#1f1f1f",
        "id": "701351"
    },
    "Navy Camo": {
        "hex": "#445167",
        "id": "701352"
    },
    "Athletic Heather/Black": {
        "hex": "#1e2223",
        "id": "701353"
    },
    "Athletic Heather/Dark Navy": {
        "hex": "#131b2e",
        "id": "701354"
    },
    "Athletic Heather/Red": {
        "hex": "#ca1b3a",
        "id": "701355"
    },
    "Athletic Heather/Royal": {
        "hex": "#195297",
        "id": "701356"
    },
    "Black Caviar Heather": {
        "hex": "#262d35",
        "id": "701357"
    },
    "Black Iris Blue": {
        "hex": "#1a2940",
        "id": "701358"
    },
    "Caviar Black": {
        "hex": "#1c1d1f",
        "id": "701359"
    },
    "Pearl Blue": {
        "hex": "#d7e4ea",
        "id": "701360"
    },
    "Blueberry Pancake": {
        "hex": "#235b87",
        "id": "701361"
    },
    "Hedge Green": {
        "hex": "#6e6d5d",
        "id": "701362"
    },
    "Pro Red": {
        "hex": "#a03545",
        "id": "701363"
    },
    "Varsity Royal": {
        "hex": "#2a4d9f",
        "id": "701364"
    },
    "Deep Black": {
        "hex": "#1f1f1f",
        "id": "701365"
    },
    "Gym Red": {
        "hex": "#cd3743",
        "id": "701366"
    },
    "Anthracite": {
        "hex": "#484c4d",
        "id": "701367"
    },
    "Brisk Blue": {
        "hex": "#3d82cf",
        "id": "701368"
    },
    "Cool Grey": {
        "hex": "#848d96",
        "id": "701369"
    },
    "Marine": {
        "hex": "#1e2337",
        "id": "701370"
    },
    "Old Royal": {
        "hex": "#244294",
        "id": "701371"
    },
    "Dark Grey/White": {
        "hex": "#757678",
        "id": "701372"
    },
    "Game Royal/White": {
        "hex": "#405cb1",
        "id": "701373"
    },
    "Anthracite/Black": {
        "hex": "#575958",
        "id": "701374"
    },
    "Cool Grey/Anthracite": {
        "hex": "#909598",
        "id": "701375"
    },
    "Old Royal/Marine": {
        "hex": "#2e3d6a",
        "id": "701376"
    },
    "Brilliant Orange": {
        "hex": "#f15e2a",
        "id": "701377"
    },
    "Rush Blue": {
        "hex": "#26529e",
        "id": "701378"
    },
    "Valor Blue": {
        "hex": "#527cb8",
        "id": "701379"
    },
    "Sport Red": {
        "hex": "#bd2c35",
        "id": "701380"
    },
    "Team Anthracite": {
        "hex": "#3f4544",
        "id": "701381"
    },
    "Team Black": {
        "hex": "#242424",
        "id": "701382"
    },
    "Team Royal": {
        "hex": "#274da0",
        "id": "701383"
    },
    "Team Scarlet": {
        "hex": "#c43232",
        "id": "701384"
    },
    "Grey Frost": {
        "hex": "#595959",
        "id": "701385"
    },
    "Indigo Frost": {
        "hex": "#343e4c",
        "id": "701386"
    },
    "Lapis Blue Frost": {
        "hex": "#5971b4",
        "id": "701387"
    },
    "Military Frost": {
        "hex": "#606048",
        "id": "701388"
    },
    "Onyx Frost": {
        "hex": "#272729",
        "id": "701389"
    },
    "Scarlet Frost": {
        "hex": "#ca4a58",
        "id": "701390"
    },
    "Lapis Blue": {
        "hex": "#1f4181",
        "id": "701391"
    },
    "Neptune Blue": {
        "hex": "#1b3d59",
        "id": "701392"
    },
    "Orange Burst": {
        "hex": "#ee7e2c",
        "id": "701393"
    },
    "Military": {
        "hex": "#807d62",
        "id": "701394"
    },
    "Neon Violet": {
        "hex": "#bc9fcd",
        "id": "701395"
    },
    "Brown/Khaki": {
        "hex": "#593c31",
        "id": "701396"
    },
    "Caramel/Black": {
        "hex": "#c27541",
        "id": "701397"
    },
    "Charcoal/White": {
        "hex": "#483a39",
        "id": "701398"
    },
    "Heather/Black": {
        "hex": "#6c6b75",
        "id": "701399"
    },
    "Heather/White": {
        "hex": "#a4a6ae",
        "id": "701400"
    },
    "Multicam\u00c2\u00ae Black*/Black": {
        "hex": "#1f1a1a",
        "id": "701401"
    },
    "Navy/Silver": {
        "hex": "#2a303e",
        "id": "701402"
    },
    "Realtree EDGE\u00c2\u00ae**/Brown": {
        "hex": "#956d3e",
        "id": "701403"
    },
    "Coffee Brown": {
        "hex": "#452f22",
        "id": "701404"
    },
    "Sahara": {
        "hex": "#cdbdb0",
        "id": "701405"
    }
};