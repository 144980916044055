import React from 'react';
import ReactDOM from 'react-dom';
import './Paymentconfirm.css';
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";


// Create a PaymentConfirm component with a detailed UI
function PaymentConfirm() {
  return (
    <LayoutWrapper>       
    <div className="payment-confirm-container mt-5 mb-5">


      <div className="payment-confirm-row d-flex justify-content-center">
        <div className="payment-confirm-col-md-8">
          <div className="payment-confirm-card">
            <div className="payment-confirm-header d-flex justify-content-between p-3">
              <h5>Thank you for your order #3911690705825</h5>
              <button className="btn btn-link">Continue Shopping</button>
            </div>
            <div className="payment-confirm-details p-5">
              <div className="payment-confirm-shipping-info d-flex justify-content-between">
                <div>
                  <span className="font-weight-bold d-block">Order placed</span>
                  <span>Value shipping</span>
                  <div>Arrives by <span className="text-success font-weight-bold">Tue, May 10</span></div>
                  <div>Sold by Walmart.com</div>
                  <div>Order #3911690705825</div>
                </div>
                <div className="payment-confirm-shipping-address">
                  <span className="font-weight-bold d-block">Shipping address</span>
                  <span>John Newman</span>
                  <div>2125 Chestnut Street</div>
                  <div>San Francisco, CA 94123</div>
                  <div>test-order@baymard.com</div>
                </div>
              </div>
              <div className="payment-confirm-status mt-4 mb-4">
                <div className="d-flex align-items-center">
                  <span className="order-placed-status completed">Order placed</span>
                  <span className="status-separator" />
                  <span className="order-processing-status">Processing</span>
                  <span className="status-separator" />
                  <span className="order-shipped-status">Shipped</span>
                  <span className="status-separator" />
                  <span className="order-delivered-status">Delivered</span>
                </div>
              </div>
              <div className="payment-confirm-product border-bottom">
                <div className="d-flex align-items-center">
                  <img src="https://i.imgur.com/u11K1qd.jpg" width="90" alt="Crayola Bundle" />
                  <div className="ml-3">
                    <span className="font-weight-bold d-block">Crayola 83-Piece Bundle Set</span>
                    <div>Quantity: 1</div>
                    <div>Value shipping: Arrives by Tue, May 10</div>
                  </div>
                  <div className="ml-auto">
                    <span className="font-weight-bold">$4.43</span>
                  </div>
                </div>
              </div>
              <div className="payment-confirm-summary mt-4 d-flex justify-content-between">
                <div>
                  <span className="font-weight-bold">Order summary</span>
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>Subtotal (1 item)</td>
                        <td className="text-right">$4.43</td>
                      </tr>
                      <tr>
                        <td>Value shipping</td>
                        <td className="text-right">$4.97</td>
                      </tr>
                      <tr>
                        <td>Est. tax</td>
                        <td className="text-right">$0.39</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total</td>
                        <td className="text-right font-weight-bold">$9.79</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="payment-confirm-payment-type">
                  <span className="font-weight-bold">Payment type</span>
                  <div className="py-2">
                    <img src="https://img.icons8.com/color/48/000000/visa.png" width="20" alt="Visa" /> Ending in 8903
                  </div>
                  <div className="font-weight-bold mt-4">Billing address</div>
                  <span>John Newman</span>
                  <div>2125 Chestnut Street</div>
                  <div>San Francisco, CA 94123</div>
                  <div>test-order@baymard.com</div>
                </div>
              </div>
              <p className="mt-4">We will send you an email with tracking information when your item ships.</p>
            </div>
            <div className="payment-confirm-footer d-flex justify-content-between p-3">
              <span>Need Help? Visit our <a href="#">help center</a></span>
              <span>12 June, 2020</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </LayoutWrapper>
  );
}

export default PaymentConfirm;