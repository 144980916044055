import { Col, Row } from "antd";
import React from "react";
import SimpleButton from "../../components/simpleButton/SimpleButton";
import Title from "../../components/typography/title/Title";
import free from "../../assets/images/free.png";
import rush from "../../assets/images/rush.png";
import email from "../../assets/images/email.png";




const NewArrivalOrignal = ({ data }) => {
  return (
    <div className="newArrival newarrivalorignal">
      <div className="main-text-newarival">

      <div className="main-text-newarival">

<Title size={"41"} tag={"h2"} weight={700}>
  Powered by Precision!
  </Title>
  <Title customClass={"sub-title"} size={"21"} tag={"h2"} weight={500}>
  With a focus on excellence, we bring your designs to life with the utmost care and precision
  </Title>
</div>
</div>
      <Row gutter={[25, 20]}>
        <Col md={16}>
          <Row gutter={[25, 20]}>
            <Col md={12}>
              <div className="box">
                <img src={data[0].image} alt="imag" />
                <SimpleButton size={"large"} color={"black"} bgColor={"white"}>
                  <Title size={"13"} tag={"h6"} weight={700}>
                    {data[0].subTitle}
                  </Title>
                  <Title size={"22"} tag={"h5"} weight={700}>
                    {data[0].title}
                  </Title>
                </SimpleButton>
              </div>
            </Col>

            <Col md={12}>
              <div className="box">
                <img src={data[1].image} alt="imag" />
                <SimpleButton size={"large"} color={"black"} bgColor={"white"}>
                  <Title size={"13"} tag={"h6"} weight={700}>
                    {data[1].subTitle}
                  </Title>
                  <Title size={"22"} tag={"h5"} weight={700}>
                    {data[1].title}
                  </Title>
                </SimpleButton>
              </div>
            </Col>
            <Col md={24}>
              <div className="box three">
                <img src={data[2].image} alt="imag" />
                <SimpleButton size={"large"} color={"black"} bgColor={"white"}>
                  <Title size={"13"} tag={"h6"} weight={700}>
                    {data[2].subTitle}
                  </Title>
                  <Title size={"22"} tag={"h5"} weight={700}>
                    {data[2].title}
                  </Title>
                </SimpleButton>
              </div>
            </Col>
          </Row>
        </Col>
        
        <Col xs={24} md={8}>
          <div className="box">
            <img src={data[3].image} alt="imag" />
            <SimpleButton size={"large"} color={"black"} bgColor={"white"}>
              <Title size={"13"} tag={"h6"} weight={700}>
                {data[3].subTitle}
              </Title>
              <Title size={"22"} tag={"h5"} weight={700}>
                {data[3].title}
              </Title>
            </SimpleButton>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NewArrivalOrignal;
