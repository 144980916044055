import React, { useEffect } from 'react';

const ObjectColorComponent = ({
  canvas,
  selectedObject,
  selectedObjectColors,
  setSelectedObjectColors,
  selectedColor,
  setSelectedColor,
  showColorPalette,
  setShowColorPalette,
  inkColors,
  setInkColors,
  onColorChange,
  handleFillChange,
  addToHistory
}) => {

  useEffect(() => {
    fetch('https://backend.inkox.com/api/inkcolors')
      .then((response) => response.json())
      .then((data) => {
        const allColors = data.map((item) => item.color_hex.split(',')).flat().map(color => color.trim());
        setInkColors(allColors);
      })
      .catch((error) => {
        console.error('Error fetching outline colors:', error);
      });
  }, [setInkColors]);

  const applyColorToClipart = (color) => {
    const activeObject = selectedObject;
    if (activeObject) {
      const prevState = activeObject.toObject(['left', 'top', 'scaleX', 'scaleY', 'src', 'fill']); // Include 'fill' for color state
      if (activeObject.type === 'group') {
        activeObject.getObjects().forEach((obj) => {
          if ((obj.type === 'path' || obj.type === 'rect' || obj.type === 'circle') && obj.fill === selectedColor) {
            obj.set('fill', color);
          }
        });
      } else if (activeObject.type === 'path' && activeObject.fill === selectedColor) {
        activeObject.set('fill', color);
      } else if (activeObject.type === 'text' || activeObject.type === 'i-text') {
        activeObject.set('fill', color);
      }
      canvas.renderAll();
      const newState = activeObject.toObject(['left', 'top', 'scaleX', 'scaleY', 'src', 'fill']); 
      onColorChange();
      addToHistory(activeObject, prevState, newState, 'colorChange'); 
    }
    setSelectedObjectColors((prevColors) => prevColors.map((c) => (c === selectedColor ? color : c)));
    setShowColorPalette(false); // Close the palette after selecting a color
    handleFillChange(color); 
  };

  const handleColorBoxClick = (color) => {
    setSelectedColor(color);
    setShowColorPalette(true);
  };

  const handleColorPaletteClick = (color) => {
    applyColorToClipart(color);
  };

  return (
    <div style={{ width: '100%' }}>

      {/* If the color palette is clicked, hide everything else */}
      {showColorPalette ? (
        <div className="color-palette" style={{ display: 'flex', marginTop: '10px' }}>
          {inkColors.map((color, index) => (
            <div
              key={index}
              className="color-box"
              style={{
                backgroundColor: color,
                border: '1px solid #000',
                marginRight: '5px',
                width: '25px',
                height: '25px'
              }}
              onClick={() => handleColorPaletteClick(color)}
            ></div>
          ))}
        </div>
      ) : (
        <>
          {/* Display color options when the palette is not shown */}
          {Array.isArray(selectedObjectColors) && selectedObjectColors.length > 0 && (
<>

    <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            
            <label className='labFont' style={{ color: 'black', fontSize: '12px' }}>Color</label>
            <div className="colors-gallery  outline-main" style={{ display: 'flex' }}>
              {selectedObjectColors.map((color, index) => (
                <div
                  key={index}
                  className="color-box"
                  style={{
                    backgroundColor: color,
                    border: 'none',
                    marginRight: '5px',
                    width: '25px',
                    height: '25px',
                    borderRadius:"5px",

                  }}
                  onClick={() => handleColorBoxClick(color)}
                ></div>
                
              ))}
            </div>
          </div>
          
</>
      
          )}
        </>
      )}
    </div>
  );
};

export default ObjectColorComponent;
