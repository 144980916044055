import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFromLocalStorage,
  setInLocalStorage,
} from "../../utils/helperFunctions";
import { getAllProducts as getAllProductsAPI } from "./service";

export const getAllProducts = createAsyncThunk(
  "get/products",
  async (payload) => {
    // const token = getFromLocalStorage("APP_TOKEN");
    const params = {
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    };
    const response = await getAllProductsAPI(payload, params);
    return response.data;
  }
);
