import React, {useState} from "react";
import "./policy.css"; // Importing the updated CSS
import Image from './assets/image.jpg';
import Breadcrumb from "./Breadcrumb.js";
import Sidebar from "./Sidebar.js";
import "./App.css";
import LayoutWrapper from "../../layout/layoutWrapper/LayoutWrapper.jsx";
import { Link } from "react-router-dom";
function Policy() {
    const [openSections, setOpenSections] = useState(["about", "agreement", "policy", "solution"]);

    const toggleSection = (section) => {
      setOpenSections((prevOpenSections) => {
        if (prevOpenSections.includes(section)) {
          return prevOpenSections.filter((s) => s !== section);
        } else {
          return [...prevOpenSections, section];
        }
      });
    };
  
    const renderIcon = (isOpen) => (
      <svg
        className="mui-icon"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ExpandMoreIcon"
        style={{
          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
        }}
      >
        <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </svg>
    );
  
    return (
        <LayoutWrapper>
        <div className="main-div-other-pages">
              <div className="container">
             <Breadcrumb />
            <div className="inkox-container">
            <Sidebar
              openSections={openSections}
              toggleSection={toggleSection}
              renderIcon={renderIcon}
            />
                {/* Main Content */}
                <div className="inkox-main-content">
                    <h1 style={{ color: "#444444 !important", fontSize: "24px" }}>Privacy Policy Last Updated: December 15, 2023</h1>

                    <h2 className="inkox-content-h2">Introduction</h2>
                    <p className="inkox-content-para">
                        INKOX, LLC ("INKOX," “we,” or “us”) recognizes the importance of privacy for our users. This Privacy Policy explains how we handle the personal information you provide and that we collect in connection with your use of our website www.inkox.com (the "Site"). By using the Site, you agree to the collection, use, and sharing of your information as described in this Policy.
                    </p>

                    {/* <div className="inkox-image-section">
                        <img
                            src={Image}
                            alt="Customer Support"
                            className="inkox-support-image"
                        />
                    </div> */}

                    <h3 className="inkox-content-h3">Please read this policy thoroughly</h3>
                    <p className="inkox-content-para">
                        to understand our practices concerning your information and how we will treat it. If you do not agree with our policies and practices, you should not use our Site. This Privacy Policy is incorporated into our User Agreement.
                    </p>

                    <h3 className="inkox-content-h3">Changes to This Policy</h3>
                    <p className="inkox-content-para">
                        We may update this policy from time to time. Your continued use of our Site after we make changes indicates your acceptance of those changes, so please check the policy periodically for updates.
                    </p>

                    <h3 className="inkox-content-h3">Information We Collect</h3>
                    <p  className="inkox-content-para">We collect several types of information from and about users of our Site, including information:</p>
                    <ul style={{lineHeight: "23px"}}>
                        <li className="inkox-provide" ><strong>Provided by you directly</strong> (e.g., name, email address, telephone number).</li><br/>
                        <li className="inkox-provide"><strong>Automatically as you navigate the site</strong> (e.g., usage details, IP addresses).</li><br/>
                        <li className="inkox-provide"><strong>From third parties</strong> (e.g., business partners, analytics providers).</li>
                    </ul>

                    <h1 style={{ color: "#444444 !important", fontSize: "20px" }}>How We Use Your Information</h1>
                    <ul style={{lineHeight: "23px"}}>
                        <li className="inkox-provide"><strong>To provide and improve our services</strong>, including to fulfill orders, manage accounts, and respond to inquiries.</li><br/>
                        <li className="inkox-provide"><strong>For marketing purposes</strong>, to offer you tailored promotions and advertising.</li><br/>
                        <li className="inkox-provide"><strong>To conduct research and analytics</strong> to better understand how users interact with our Site.</li><br/>
                        <li className="inkox-provide"><strong>To protect the legal rights, safety, and security</strong> of our company, our users, or others.</li>
                    </ul>

                    <h1 style={{ color: "#444444 !important", fontSize: "20px" }}>How We Share Your Information</h1>
                    <ul style={{lineHeight: "23px"}}>
                        <li className="inkox-provide">With <strong>service providers</strong> who perform services on our behalf.</li><br/>
                        <li className="inkox-provide">With <strong>affiliates or subsidiaries</strong>.</li><br/>
                        <li className="inkox-provide">With <strong>third parties</strong> for their direct marketing purposes, if you have not opted out.</li><br/>
                        <li className="inkox-provide">For <strong>legal reasons</strong>, including law enforcement requests, if we believe disclosure is in accordance with, or required by, any applicable law.</li>
                    </ul>

                    <h3 className="inkox-content-h3">Cookies and Tracking Technologies</h3>
                    <p className="inkox-content-para">We use cookies, web beacons, and other technologies to collect information about your browsing activities in order to provide targeted advertising and track your preferences.</p>

                    <h3 className="inkox-content-h3">Your Choices and Rights</h3>
                    <ul style={{lineHeight: "23px"}}>
                        <li className="inkox-provide">You can manage your account and the information we hold about you.</li><br/>
                        <li className="inkox-provide">You may opt out of receiving promotional emails from us by using the unsubscribe link within each email.</li><br/>
                        <li className="inkox-provide">Users in certain locations may have additional rights regarding their information, such as the right to request access to or correction of their data.</li>
                    </ul>

                    <h3 className="inkox-content-h3">Data Security</h3>
                    <p className="inkox-content-para">We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>

                    <h3 className="inkox-content-h3">Data Retention</h3>
                    <p className="inkox-content-para">We retain your personal information for as long as necessary to provide the services you have requested, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our policies.</p>

                    <h1 style={{ color: "#444444 !important", fontSize: "20px" }}>Your Privacy Rights</h1>
                    <ul>
                        <li className="inkox-provide"><strong>California residents</strong> have additional personal information rights and choices. Please see our <a href="your-california-privacy-notice-link">California Privacy Notice</a> for more information.</li><br/>
                        <li className="inkox-provide"><strong>European residents</strong> may have rights under the General Data Protection Regulation (GDPR) to request access to and correction or deletion of their personal information, or to object to data processing.</li>
                    </ul>

                    <h3 className="inkox-content-h3">Children's Privacy</h3>
                    <p className="inkox-content-para">Our Site is not intended for children under 13 years of age, and we do not knowingly collect personal information from children under 13.</p>

                    <h3 className="inkox-content-h3">External Links</h3>
                    <p className="inkox-content-para">Our Site may include links to external sites that are not operated by us. These links are provided for your convenience, and we encourage you to review the privacy policies of those websites before providing them with personal information.</p>

                    <h3 className="inkox-content-h3">Contact Information</h3>
                    <p className="inkox-content-para" >For any questions or comments about this policy, or to make a request regarding your data, please contact us at: INKOX, LLC 2910 District Ave. Fairfax, VA 22031 Tel: 703-891-2205 Email: privacy@inkox.com</p>

                    <h3 className="inkox-content-h3" >Changes to this Policy</h3>
                    <p  className="inkox-content-para">We may update this Privacy Policy from time to time. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
                </div>
            </div>

            <p className="inkox-divider" style={{ borderBottom: "1px solid #aaa" }}></p>

            <div className="inkox-browsebut" style={{ textAlign: "center" }}>
            <Link className="inkox-primary-button" to={`/products`}>Browse All of Our Products</Link>
                <button className="inkox-primary-button">Start Designing in the Lab</button>
            </div>
            </div>
        </div>
        </LayoutWrapper>
    );
}

export default Policy;
