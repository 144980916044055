import React from "react";
import { Link } from "react-router-dom";

const SimpleButton = (props) => {
  const { children, size, customClass, link, color, bgColor } = props;
  return link ? (
    <Link
      className={`simpleButton ${size && size} ${
        customClass && customClass
      } text-${color && color} bg-${bgColor && bgColor}`}
      to={link | "#"}
    >
      {children}
    </Link>
  ) : (
    <button
      className={`simpleButton ${size && size} ${
        customClass && customClass
      } text-${color && color} bg-${bgColor && bgColor}`}
    >
      {children}
    </button>
  );
};

export default SimpleButton;
