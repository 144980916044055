import React from "react";
import Title from "../typography/title/Title";
import Description from "../typography/description/Description";
import { Rating } from "react-simple-star-rating";

const TestimonialCard = (props) => {
  const { data } = props;
  return (
    <div className="testimonialCard">
      {data?.description && (
        <Description>
          <q>{data?.description}</q>
        </Description>
      )}
      <div className="card-image-row">
        <div className="line"></div>
        <div className="card-image">
          <img src={data?.image} alt="card-img" />
        </div>
      </div>
      <div>
        <div className="content-row">
          <div>
            {data?.name && (
              <Title
                customClass={"card-title"}
                size={"17"}
                tag={"h5"}
                weight={700}
              >
                {data?.name}
              </Title>
            )}
            {data?.designation && (
              <Title
                customClass={"designation text-skyblue4"}
                size={"16"}
                tag={"h5"}
                weight={500}
              >
                {data?.designation}
              </Title>
            )}
          </div>
          <Rating
            emptyColor="#fff"
            size={22}
            readonly
            initialValue={data?.rating}
          />
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
