import React, { useState, useEffect } from 'react';
import { fabric } from 'fabric';
import './AddClipartComponent.css';
import close from "../assets/close.png";

// Extract colors function
const extractColorsFromSVG = (svgContent) => {
  const colors = new Set();
  const fillRegex = /fill="(#[0-9a-fA-F]{3,6}|rgba?\(\d+,\d+,\d+(?:,\d+)?\))"/g;
  let match;
  while ((match = fillRegex.exec(svgContent)) !== null) {
    colors.add(match[1]);
  }
  return Array.from(colors);
};

const AddClipartComponent = ({ canvas, onColorsExtracted, closePropertiesSidebar }) => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [cliparts, setCliparts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch categories on mount
  useEffect(() => {
    fetch(`https://backend.inkox.com/api/categories`)
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

  // Fetch sub-categories for the selected category
  useEffect(() => {
    if (selectedCategory) {
      setSubCategories([]); // Reset sub-categories
      setLoading(true);
      fetch(`https://backend.inkox.com/api/sub-categories`)
        .then((response) => response.json())
        .then((data) => {
          const filteredSubCategories = data.filter(sub => sub.category_id === selectedCategory.id);
          setSubCategories(filteredSubCategories);
          setLoading(false); // Stop loading after data is fetched
        })
        .catch((error) => {
          console.error('Error fetching sub-categories:', error);
          setLoading(false); // Stop loading in case of error
        });
    }
  }, [selectedCategory]);

  // Fetch cliparts for the selected sub-category
  useEffect(() => {
    if (selectedSubCategory) {
      setCliparts([]);
      setLoading(true);
      fetch(`https://backend.inkox.com/api/cliparts`)
        .then((response) => response.json())
        .then((data) => {
          const filteredCliparts = data.filter(clipart => clipart.sub_category === selectedSubCategory.id.toString());
          setCliparts(filteredCliparts);
          setLoading(false); // Stop loading after data is fetched
        })
        .catch((error) => {
          console.error('Error fetching cliparts:', error);
          setLoading(false); // Stop loading in case of error
        });
    }
  }, [selectedSubCategory]);
// Stop loa
  const addClipart = (clipart) => {
    if (canvas) {
      const clipartPath = clipart;
      const secureClipartPath = clipartPath.startsWith("http:") ? clipartPath.replace("http:", "https:") : clipartPath;

      fabric.loadSVGFromURL(secureClipartPath, (objects, options) => {
        const svg = fabric.util.groupSVGElements(objects, options);
        const designArea = canvas.getObjects().find(obj => obj.designArea);
        if (designArea) {
          const scaleFactor = 40 / svg.width;
          svg.set({
            left: designArea.left,
            top: designArea.top,
            scaleX: scaleFactor,
            scaleY: scaleFactor,
            selectable: true,
            clipartPath: secureClipartPath,
            designElement: true
          });
          canvas.add(svg);
          canvas.setActiveObject(svg);
          
          fetch(secureClipartPath)
            .then((response) => response.text())
            .then((svgContent) => {
              const colors = extractColorsFromSVG(svgContent);
              onColorsExtracted(colors);
            })
            .catch((error) => console.error('Error fetching SVG:', error));
        }
      });
      
    } else {
      console.error('Canvas is not initialized');
    }
  };

  return (

  <>
    <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '3px',
          backgroundColor: '#f6f6f6',
        }}
      >
        <h5
          style={{
            margin: '0 auto',
            color: '#aaaa',
            fontWeight: '400',
            textAlign: 'center',
            flexGrow: 1,
          }}
        >
          Add Clipart
        </h5>
        <button
          onClick={closePropertiesSidebar}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '18px',
            fontWeight: 'bold',
            marginLeft: 'auto',
          }}
          aria-label="Close"
        >
          <img src={close} alt="Close" className="closeicon" />
        </button>
      </div>
    <div className="clipart-component">
    

      <div className="search-box">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path
            fill="#aaa"
            d="M23.7 22.3l-5.7-5.7c1.2-1.5 1.9-3.4 1.9-5.4 0-4.7-3.8-8.5-8.5-8.5S2.9 6.5 2.9 11.2 6.7 19.7 11.4 19.7c2 0 3.9-.7 5.4-1.9l5.7 5.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4zM11.4 17c-3.2 0-5.8-2.6-5.8-5.8s2.6-5.8 5.8-5.8 5.8 2.6 5.8 5.8-2.6 5.8-5.8 5.8z"
          />
        </svg>
        <input
          type="text"
          placeholder="Search Categories"
          className="font-search"
        />
      </div>

      {/* Display Categories */}
      {!selectedCategory && (
        <div className="clipart-categories">
          {categories.map((category) => (
            <div key={category.id} className="clipart-category" onClick={() => setSelectedCategory(category)}>
              {category.name}
            </div>
          ))}
        </div>
      )}

      {/* Display Subcategories */}
      {selectedCategory && !selectedSubCategory && (
        <div className="backtomaincategory">
          <button onClick={() => setSelectedCategory(null)} className="donebutton">
            Back to Categories
          </button>

          <div className="clipart-subcategories">
            {loading ? (
              <p>Loading sub-categories...</p> // Loading message or spinner
            ) : (
              subCategories.map((subCategory) => (
                <div key={subCategory.id} className="clipart-category" onClick={() => setSelectedSubCategory(subCategory)}>
                  {subCategory.name}
                </div>
              ))
            )}
          </div>
        </div>
      )}

      {/* Display Cliparts */}
      {selectedSubCategory && (
        <div className="backtomaincategory">
          <button onClick={() => setSelectedSubCategory(null)} className="donebutton">
            Back to Subcategories
          </button>

          <div className="clipart-gallery">
            {loading ? (
              <p>Loading cliparts...</p> // Loading message or spinner
            ) : (
              cliparts.map((clipart, index) => (
                <div key={index} className="clipart-parent">
                  <img
                    src={clipart.image_path}
                    alt={`clipart-${index}`}
                    onClick={() => addClipart(clipart.image_path)}
                    className="clipart-thumbnail"
                  />
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default AddClipartComponent;
