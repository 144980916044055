import React, {useState} from "react";
import "./about.css"; // Importing the updated CSS
import Image from './assets/learninkox.jpg';
import Breadcrumb from "./Breadcrumb.js";
import Sidebar from "./Sidebar.js";
import "./App.css"
import LayoutWrapper from "../../layout/layoutWrapper/LayoutWrapper.jsx";
import { Link } from "react-router-dom";
function About() {
    const [openSections, setOpenSections] = useState(["about", "agreement", "policy", "solution"]);

    const toggleSection = (section) => {
      setOpenSections((prevOpenSections) => {
        if (prevOpenSections.includes(section)) {
          return prevOpenSections.filter((s) => s !== section);
        } else {
          return [...prevOpenSections, section];
        }
      });
    };
  
    const renderIcon = (isOpen) => (
      <svg
        className="mui-icon"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ExpandMoreIcon"
        style={{
          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
        }}
      >
        <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </svg>
    );
  
    return (

    <LayoutWrapper>
        <div className="main-div-other-pages">
            <div className="container">
        <Breadcrumb />
        <div className="inkox-container">
        <Sidebar
              openSections={openSections}
              toggleSection={toggleSection}
              renderIcon={renderIcon}
            />
            {/* Main Content */}
            <div className="main-content-inkox">
                <h1 className="inkox-content-h1">About INKOX</h1>
                <h2 className="inkox-content-h2">More Than Just Threads</h2>
                <p className="inkox-content-para">
                INKOX isn't just a t-shirt company; We're a vibrant team of creatives committed to helping you craft the perfect custom apparel for your group. Our website is packed with cool, user-friendly tools like the Design Lab and backed by a team of artists eager to assist in perfecting your custom designs. Whether it’s printed t-shirts or embroidered caps, one shirt or thousands, for students or small businesses, family gatherings or corporate events, we’re here to stitch your group together in style.
                </p>

                <div className="image-section-inkox">
                    <img src={Image} alt="Customer Support" className="support-image-inkox" />
                </div>

                <h2  className="inkox-content-h2">Roots and Reach</h2>
                <p className="inkox-content-para">
                Launched in 2000 and based in the heart of Northern Virginia, INKOX is a rapidly expanding, forward-thinking gem in the custom apparel industry. With over fifteen retail locations spread across five states and a dynamic online presence, we’re constantly evolving to meet your creative needs. Our community—comprising customers, vendors, and team members—is the fabric of the INKOX story, and we celebrate and respect every thread.
                </p>

                <h2 className="inkox-content-h2">Accolades and Accreditations</h2>
                <p className="inkox-content-para">
                While we’re not ones to boast, we can’t help but share the accolades we’ve earned thanks to our incredible community. We’ve sprinted to the top of Inc. Magazine’s “Inc. 500” list of America’s fastest-growing private companies and have been lauded as a “Great Place to Work” by Washingtonian Magazine. Plus, INKOX grabbed the #4 spot in Entrepreneur Magazine’s 8th Annual Great Place to Work™ Rankings in 2011. We’re proud, and we know it’s all thanks to you!
                </p>

                <h2 className="inkox-content-h2">Get in Touch!</h2>
                <h3 className="inkox-content-h3">Service & Sales:</h3>
                <p className="inkox-content-para">
                Need help navigating INKOX.com or want to chat about your design? Call our Service/Sales Team at 1-800-293-4232 or email us at service@inkox.com.
                </p>

                <h2 className="inkox-content-h2">Payments:</h2>
                    <p className="inkox-content-para">
                    Please send all payments to:
                    <br />
                    Maple Custom Tees Inc.<br />
                    PO BOX 00000<br />
                    Address<br />
                    Attn: Accounts Receivable<br />
                    Fax purchase orders to: 000-000-0000
                </p>

                <h2 className="inkox-content-h2">Partner with Us:</h2>
                <p className="inkox-content-para">
                    Eager to join our network of top-notch vendors? Whether you’re a screen printer, embroiderer, or supplier of other goods, reach out to:
                    <br />
                    Jibran Shamim<br />
                    Vendor Coordinator<br />
                    Email: hennis@inkox.com
                </p>

                <h3 className="inkox-content-h3">Visit Us:</h3>
                <p className="inkox-content-para">
                    Find us just a quick drive from Milner Ave. For directions, click on the link below:
                    <br />
                    Google Maps Driving Directions<br />
                    Maple Custom Tees<br />
                    Address<br />
                    At INKOX, we’re excited to help you create something amazing. Let's print the future together!
                </p>
                <p className="divider-inkox" style={{ borderBottom: "1px solid #aaa" }}>
            </p>
            <div className="browse-buttons-inkox" style={{textAlign:"center" , marginBottom:"18px"}}>
                <Link className="inkox-primary-button" to={`/products`}>Browse All of Our Products</Link>
                <button className="inkox-primary-button">Start Designing in the Lab</button>
            </div>
            </div>
          
        </div>
        </div>
        </div>
        </LayoutWrapper>
    );
}

export default About;
