import React from "react";
import SignUpForm from "../components/forms/signUpForm/SignUpForm";

const Signup = () => {
  return (
    <div className="signUp">
      <SignUpForm />
    </div>
  );
};

export default Signup;
