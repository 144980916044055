import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { GetColorName } from 'hex-color-to-color-name'; // Import the GetColorName function

const PreviewPage = () => {
  const { mainCategory, subCategory } = useParams();
  const [products, setProducts] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);

  // Fetch sizes, colors, and products data (similar to your current implementation)
  useEffect(() => {
    fetch('http://127.0.0.1:8000/api/sizes')
      .then((response) => response.json())
      .then((data) => {
        setSizes(data);
      })
      .catch((error) => console.error('Error fetching sizes data:', error));
  }, []);

  useEffect(() => {
    fetch('http://127.0.0.1:8000/api/colors')
      .then((response) => response.json())
      .then((data) => {
        setColors(data);
      })
      .catch((error) => console.error('Error fetching colors data:', error));
  }, []);

  useEffect(() => {
    fetch('http://127.0.0.1:8000/api/product')
      .then((response) => response.json())
      .then((data) => {
        const filteredProducts = data.filter(
          product =>
            product.category.name === mainCategory &&
            product.sub_category.name === subCategory
        );
        setProducts(filteredProducts);
      })
      .catch((error) => console.error('Error fetching product data:', error));
  }, [mainCategory, subCategory]);

  if (!products.length) {
    return <div>Loading...</div>;
  }

  // Helper function to find size by id
  const findSizeById = (sizeId) => sizes.find(size => size.id === sizeId);

  return (
    <div className="preview-page">
      <h1>{subCategory}</h1>
      {products.map((product) => {
        const productSize = findSizeById(product.size_id?.id);

        // Use GetColorName to dynamically map each hex code to a color name
        const productColors = product.color_id?.color_hex
          ? product.color_id.color_hex.split(',').map(hex => GetColorName(hex.trim())) // Convert each hex to color name
          : [];

        return (
          <div key={product.id} className="product-preview">
            <h2>{product.name}</h2>
            <p>{product.summary}</p>
            <p><strong>Details:</strong> {product.description}</p>
            <p><strong>Price:</strong> {product.price}</p>
            <p><strong>Material:</strong> {product.material}</p>
            <p><strong>Fabric:</strong> {product.Fabric}</p>
            <p><strong>Brand:</strong> {product.brand}</p>
            <p><strong>Weight:</strong> {product.Weight}</p>
            <p><strong>Fit:</strong> {product.Fit}</p>

            {/* Display size and dynamic color names */}
            {productSize && (
              <p><strong>Size:</strong> {productSize.size}</p>
            )}
            {productColors.length > 0 && (
              <p><strong>Colors:</strong> {productColors.join(", ")}</p>
            )}

            <div className="product-images">
              <img src={`/path-to-your-images/${product.productImages.front}`} alt="Front View" />
              <img src={`/path-to-your-images/${product.productImages.back}`} alt="Back View" />
              <img src={`/path-to-your-images/${product.productImages.left}`} alt="Left View" />
              <img src={`/path-to-your-images/${product.productImages.right}`} alt="Right View" />
            </div>
            <a href={`/designer?id=${product.productCanvasId}&pid=${product.id}`}>
              Start Designing
            </a>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default PreviewPage;
