import React, { useEffect, useState, useContext } from 'react';
import { fabric } from 'fabric';
import { ColorContext } from '../context/ColorContext';
import { useParams } from "react-router-dom";

const Loader = () => (
  <div className="loader">
    <div className="spinner"></div>
  </div>
);

const ProductImageComponentThree = ({ canvas, setImage, setSelectedPart, activePart, setActivePart, Canvasid, pid, setWorkingArea }) => {
  const { selectedColor, selectedColorName } = useContext(ColorContext);
  const [imageSrc, setImageSrc] = useState(null);
  const [backgroundLayer, setBackgroundLayer] = useState(null);
  const [availableParts, setAvailableParts] = useState([]);
  const [products, setProducts] = useState([]);
  const [tshirtData, setTshirtData] = useState(null); // State for tshirtDesignData
  const [isLoading, setIsLoading] = useState(true); // For handling loading indicator
  const offsetValue = 0.002;

  const productCanvasID = Canvasid;

  // Fetch t-shirt data
  useEffect(() => {
    setIsLoading(true); // Start loading
    fetch('https://backend.inkox.com/api/tool-apparels')
      .then((response) => response.json())
      .then((data) => {
        const filteredProduct = data.find(product => product.id === parseInt(Canvasid));
        if (filteredProduct) {
          setTshirtData(filteredProduct);
        }
        setIsLoading(false); // End loading when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching t-shirt design data:', error);
        setIsLoading(false); // End loading on error
      });
  }, [Canvasid]);

  // Preload images in the background for better performance
  useEffect(() => {
    if (products) {
      products.forEach((part) => {
        const img = new Image();
        img.src = `https://backend.inkox.com${part.ProductImageComponent3?.image}`;
      });
    }
  }, [products]);

  // Cache image for faster loading
  const cacheImage = (url) => {
    if ('caches' in window) {
      return caches.open('image-cache').then((cache) => {
        return cache.match(url).then((response) => {
          if (response) return response.blob();
          return fetch(url).then((fetchedResponse) => {
            cache.put(url, fetchedResponse.clone());
            return fetchedResponse.blob();
          });
        });
      });
    } else {
      return fetch(url).then((response) => response.blob());
    }
  };

  // Load image from URL
  const loadImage = async (url) => {
    try {
      const blob = await cacheImage(url);
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error loading image, falling back to placeholder:', error);
      return '/path/to/placeholder/image.png'; // Fallback to placeholder image
    }
  };

  useEffect(() => {
    if (tshirtData) {
      const parts = tshirtData.parts ? JSON.parse(tshirtData.parts) : [];
      const part = parts[2];
  
      let description = null;
  
      if (part && part.description) {
        try {
          description = JSON.parse(part.description);
        } catch (error) {
          console.error('Invalid JSON format for description:', error);
        }
      }
  
      const defaultPart = parts.find(part => part.name === activePart) || part;
  
      if (description && description.DesignAreaComponent3) {
        const designArea = description.DesignAreaComponent3;
        const workingArea = description.workingArea;
  
        setWorkingArea(workingArea);
        setSelectedPart(designArea);
  
        if (tshirtData.type === "Masking" && part.ProductImageComponent3?.image) {
          // Apply current logic for "Masking"
          const imageUrl = `https://backend.inkox.com${part.ProductImageComponent3.image}`;
          setImageSrc(imageUrl);
        } else if (tshirtData.type === "Images" && part.ProductImageComponent3?.colors) {
          // Handle "Images" type with selectedColorName
          if (selectedColorName) {
            const colorImage = part.ProductImageComponent2.colors[selectedColorName];
  
            if (colorImage) {
              const imageUrl = `https://backend.inkox.com${colorImage}`;
              setImageSrc(imageUrl);
            } else {
              console.error(`Image not found for color name: ${selectedColorName}`);
            }
          } else {
            console.error('No selected color name provided.');
          }
        }
      }
    }
  }, [tshirtData, selectedColorName, activePart]);
  console.log("ProductImageComponent3",imageSrc);

  useEffect(() => {
    if (!canvas || !imageSrc) {
      console.log('Canvas not initialized or image not set');
      return;
    }

    const setBackgroundImage = async () => {
      try {
        setIsLoading(true); // Start loading
        const imgUrl = await loadImage(imageSrc);
        fabric.Image.fromURL(imgUrl, (img) => {
          if (!img) throw new Error('Image failed to load');
          let bgLayer = backgroundLayer;
          if (!bgLayer) {
            bgLayer = new fabric.Rect({
              fill: selectedColor,
              selectable: false,
              evented: false,
              originX: 'center',
              originY: 'center',
              backgroundLayer: true,
              designElement: false,
            });
            canvas.add(bgLayer);
            setBackgroundLayer(bgLayer);
          } else {
            bgLayer.set({ fill: selectedColor });
          }

          const adjustImageAndLayer = () => {
            const canvasAspect = canvas.width / canvas.height;
            const imgAspect = img.width / img.height;

            if (imgAspect > canvasAspect) {
              img.scaleToHeight(canvas.height);
            } else {
              img.scaleToWidth(canvas.width);
            }

            img.set({
              selectable: false,
              evented: false,
              originX: 'center',
              originY: 'center',
              top: canvas.height / 2,
              left: canvas.width / 2,
              designElement: false,
            });

            const offsetX = img.getScaledWidth() * offsetValue;
            const offsetY = img.getScaledHeight() * offsetValue;

            bgLayer.set({
              width: img.getScaledWidth() - 2 * offsetX,
              height: img.getScaledHeight() - 2 * offsetY,
              top: img.top,
              left: img.left,
              designElement: false,
            });

            if (!canvas.getObjects().includes(img)) {
              canvas.add(img);
            }
            canvas.sendToBack(img);
            canvas.sendToBack(bgLayer);
            canvas.renderAll();
          };

          const productImages = canvas.getObjects().filter(obj => obj.productImage);
          productImages.forEach((obj) => canvas.remove(obj));

          img.set({ productImage: true });
          adjustImageAndLayer();
          setImage(img);

          const adjustImageAndLayerPosition = () => {
            if (!canvas || !img || !bgLayer) {
              console.log('Canvas, image, or background layer is not initialized');
              return;
            }

            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            // Define the minimum width the image can have
            const minImageWidth = 700; // Set this to your desired minimum width in pixels

            // Calculate the scale while respecting the minimum width constraint
            const scale = Math.min(
              Math.max(canvasWidth / img.width, minImageWidth / img.width), 
              canvasHeight / img.height
            );

            img.set({
              scaleX: scale,
              scaleY: scale,
              top: canvasHeight / 2,
              left: canvasWidth / 2,
              designElement: false,
            });

            const offsetX = img.getScaledWidth() * offsetValue;
            const offsetY = img.getScaledHeight() * offsetValue;

            bgLayer.set({
              width: img.getScaledWidth() - 2 * offsetX,
              height: img.getScaledHeight() - 2 * offsetY,
              top: img.top,
              left: img.left,
              designElement: false,
            });

            console.log('Adjusted layer and image positions');
            console.log('Layer position:', { top: bgLayer.top, left: bgLayer.left });
            console.log('Layer size:', { width: bgLayer.width, height: bgLayer.height });
            console.log('Layer fill:', bgLayer.fill);

            if (canvas.contextContainer) {
              canvas.renderAll();
            }
          };

          window.addEventListener('resize', adjustImageAndLayerPosition);

          adjustImageAndLayerPosition();

          setIsLoading(false); // End loading

          return () => {
            window.removeEventListener('resize', adjustImageAndLayerPosition);
          };
        });
      } catch (error) {
        console.error('Error setting background image:', error);
        setIsLoading(false); // End loading
      }
    };

    setBackgroundImage();
  }, [canvas, setImage, imageSrc, selectedColor]);

  useEffect(() => {
    if (backgroundLayer) {
      backgroundLayer.set({ fill: selectedColor });
      canvas.renderAll();
    }
  }, [selectedColor, backgroundLayer, canvas]);

  const handlePartChange = (part) => {
    setActivePart(part.name);
    setSelectedPart(part);
    setImageSrc(part.ProductImageComponentOne?.image);
  };

  return (
    <div>
      {/* Show loader while loading */}
      <p>alu</p>
      {isLoading ? (
        <Loader />
      ) : (
        <div id="canvas-container">
          {/* Canvas will be rendered here */}
        </div>
      )}
    </div>
  );
};

export default ProductImageComponentThree;
