import React, {useState} from "react";
import "./story.css"; // Importing the updated CSS
import Image from './assets/ourstory.jpg';
import Breadcrumb from "./Breadcrumb.js";
import Sidebar from "./Sidebar.js";
import "./App.css";
import LayoutWrapper from "../../layout/layoutWrapper/LayoutWrapper.jsx";
import { Link } from "react-router-dom";
function Story() {
  const [openSections, setOpenSections] = useState(["about", "agreement", "policy", "solution"]);

  const toggleSection = (section) => {
    setOpenSections((prevOpenSections) => {
      if (prevOpenSections.includes(section)) {
        return prevOpenSections.filter((s) => s !== section);
      } else {
        return [...prevOpenSections, section];
      }
    });
  };

  const renderIcon = (isOpen) => (
    <svg
      className="mui-icon"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="ExpandMoreIcon"
      style={{
        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.2s ease",
      }}
    >
      <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </svg>
  );

  return (
    <LayoutWrapper>
    <div className="main-div-other-pages">
      <div className="container">
       <Breadcrumb />
      <div className="inkox-container">
      <Sidebar
              openSections={openSections}
              toggleSection={toggleSection}
              renderIcon={renderIcon}
            />
        {/* Main Content */}
        <div className="inkox-main-content">
          <h1 style={{ color: "#444444 !important", fontSize: "24px" }}>Our Story</h1>
          <h2 className="Inkox-content-h2"> Our Journey Begins </h2>
          <h3 className="Inkox-content-h3"> From Humble Beginnings to Creative Innovations </h3>
          <p className="Inkox-content-para">
            INKOX isn't just a t-shirt company; we're a vibrant team of creatives committed to helping you craft the perfect custom apparel for your group. Our website is packed with cool, user-friendly tools like the Design Lab and backed by a team of artists eager to assist in perfecting your custom designs. Whether it’s printed t-shirts or embroidered caps, one shirt or thousands, for students or small businesses, family gatherings or corporate events, we’re here to stitch your group together in style.
          </p>

          <div className="inkox-image-section">
            <img
              src={Image}
              alt="Customer Support"
              className="inkox-support-image"
            />
          </div>

          <h2 className="Inkox-content-h2"> Expanding Horizons </h2>
          <h3 className="Inkox-content-h3"> A Whirlwind of Growth and Exciting Breakthroughs </h3>
          <p className="Inkox-content-para">  
            INKOX didn’t just grow—we soared! Embracing new technologies and trends, we expanded our horizons and in 2024, we launched a game-changing e-commerce tool. This platform is not just a tool; it’s your new creative playground! It lets you whip up your custom designs effortlessly online, making the process a total breeze and a whole lot of fun.
          </p>

          <h2 className="Inkox-content-h2"> Our Commitment </h2>
          <h3 className="Inkox-content-h3"> Crafting With Care and a Dash of Flair </h3>
          <p className="Inkox-content-para">
            INKOX is about making magic happen. Backed by the robust Malpe Custom Tees Inc., we're dedicated to weaving quality and care into everything we do. Our promise? To deliver not just products, but smiles, unforgettable memories, and a burst of joy with each item that leaves our shop.
          </p>

          <h2 className="Inkox-content-h2"> A Vision for the Future </h2>
          <h3 className="Inkox-content-h3"> Bright, Bold, and Full of Wonder </h3>
          <p className="Inkox-content-para">
            The future at INKOX sparkles with potential. We're on a quest to sprinkle more sustainable practices into our processes, broaden our vibrant product range, and jazz up our digital design tools. Our heart beats for innovation, outstanding quality, and supporting the communities we adore.
          </p>

          <h2 className="Inkox-content-h2"> Join Us </h2>
          <h3 className="Inkox-content-h3"> Step Into Our Creative Wonderland </h3>
          <p className="Inkox-content-para">
            Come along for this exhilarating ride with INKOX! Whether you’re crafting team uniforms, cooking up some promotional flair, or personalizing a project close to your heart, we’re here to help you splash your ideas across fabric canvases. Dive into our website, toy around with our design tools, and discover why thousands have chosen INKOX to dress their dreams. Let’s create something spectacular together!
          </p>

          <h3 className="Inkox-content-h3"> Roots and Reach </h3>
          <p className="Inkox-content-para">
            Launched in 2000 and based in the heart of Northern Virginia, INKOX is a rapidly expanding, forward-thinking gem in the custom apparel industry. With over fifteen retail locations spread across five states and a dynamic online presence, we’re constantly evolving to meet your creative needs. Our community—comprising customers, vendors, and team members—is the fabric of the INKOX story, and we celebrate and respect every thread.
          </p>

          <h3 className="Inkox-content-h3"> Accolades and Accreditations </h3>
          <p className="Inkox-content-para">
            While we’re not ones to boast, we can’t help but share the accolades we’ve earned thanks to our incredible community. We’ve sprinted to the top of Inc. Magazine’s “Inc. 500” list of America’s fastest-growing private companies and have been lauded as a “Great Place to Work” by Washingtonian Magazine. Plus, INKOX grabbed the #4 spot in Entrepreneur Magazine’s 8th Annual Great Place to Work™ Rankings in 2011. We’re proud, and we know it’s all thanks to you!
          </p>

          <h1 style={{ color: "#444444 !important", fontSize: "24px" }}>Get in Touch!</h1>
          <h3 className="Inkox-content-h3"> Service & Sales:</h3>
          <p className="Inkox-content-para">
            Need help navigating INKOX.com or want to chat about your design? Call our Service/Sales Team at 1-800-293-4232 or email us at service@inkox.com.
          </p>

          <h3 className="Inkox-content-h3"> Payments:</h3>
          <p className="Inkox-content-para">
            Please send all payments to:
            Maple Custom Tees Inc.
            PO BOX 00000
            Address
            Attn: Accounts Receivable
            Fax purchase orders to: 000-000-0000
          </p>

          <h3 className="Inkox-content-h3"> Partner with Us:</h3>
          <p className="Inkox-content-para">
            Eager to join our network of top-notch vendors? Whether you’re a screen printer, embroiderer, or supplier of other goods, reach out to:
            Jibran Shamim
            Vendor Coordinator
            Email: hennis@inkox.com
          </p>

          <h3 className="Inkox-content-h3"> Visit Us:</h3>
          <p className="Inkox-content-para">
            Find us just a quick drive from Milner Ave. For directions, click on the link below:
            Google Maps Driving Directions
            Maple Custom Tees
            Address
            At INKOX, we’re excited to help you create something amazing. Let's print the future together!
          </p>
        </div>
      </div>

      <p className="inkox-divider" style={{ borderBottom: "1px solid #aaa" }}></p>

      <div className="inkox-browsebut" style={{ textAlign: "center" }}>
      <Link className="inkox-primary-button" to={`/products`}>Browse All of Our Products</Link>
        <button className="inkox-primary-button">Start Designing in the Lab</button>
      </div>
      </div>
    </div>
    </LayoutWrapper>
  );
}

export default Story;
