import { Col, Form, Input, Row, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";
import bgImage from "../../../assets/images/offer-bnr.png";
import Container from "../../container/Container";
import SimpleButton from "../../simpleButton/SimpleButton";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { postLogin } from "../../../redux_apis/redux_login/thunk";
import { getFromLocalStorage } from "../../../utils/helperFunctions";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { selectUser } from "../../../redux_apis/redux_login/slice";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const ProfileForm = () => {
  const dispatch = useAppDispatch();
  const [imageUrl, setImageUrl] = useState();
  const [number, setNumber] = useState("");
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Success:", values);
    // dispatch(postLogin(values)).then((res) => {
    //   const { payload } = res;
    //   if (payload?.status) {
    //     message.success(payload.message);
    //     navigate("/");
    //     form.setFieldsValue("");
    //   } else {
    //     message.error("Invalid email or password");
    //   }
    // });
  };

  useEffect(() => {
    if (user.avatar) {
      setImageUrl(user.avatar);
    }
    form.setFieldsValue(user);
  }, [user]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // useEffect(() => {
  //   const token = getFromLocalStorage("APP_TOKEN");
  //   if (token) {
  //     navigate("/");
  //   }
  // }, []);
  // const getBase64 = (img, callback) => {
  //   // const reader = new FileReader();
  //   // reader.addEventListener("load", () => callback(reader.result));
  //   // reader.readAsDataURL(img);

  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(img);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };
  // const handleChange = async (info) => {
  //   console.log(info);
  //   if (info.file.status === "uploading") {
  //     setLoading(true);
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     // Get this url from response in real world.
  //     setLoading(false);
  //     await getBase64(info.file.originFileObj, (url) => {
  //       setImageUrl(url);
  //     });
  //   }
  // };
  const uploadButton = (
    <div>
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const dummyRequest = ({ file, onSuccess }) => {
    // console.log(file);
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleImageUpload = (obj) => {
    const { file } = obj;
    getBase64(file.originFileObj, (url) => {
      console.log(url);
      setImageUrl(url);
    });
  };

  return (
    <div className="profileForm">
      <Container>
        <Form
          form={form}
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={[20, 0]} justify={"center"}>
            <Col md={24}>
              <Form.Item
                name="avatar"
                rules={[
                  {
                    required: true,
                    message: "Please upload image",
                  },
                ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-circle"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={dummyRequest}
                  onChange={handleImageUpload}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Phone Number"
                name="number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number",
                  },
                ]}
              >
                <PhoneInput
                  country={"pk"}
                  label={false}
                  value={number}
                  className={"phoneInput"}
                  onChange={(phone, country) => {
                    setNumber({ phone, country });
                    form.setFieldsValue({ country: country.name });
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please input your city",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please input your country",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please input your address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={24}>
              <SimpleButton
                customClass={"fw-700"}
                size={"small"}
                bgColor={"yellow"}
                color={"black"}
              >
                Update
              </SimpleButton>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default ProfileForm;
