import React, { useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Breadcrumb from "../../widgets/breadcrumb/Breadcrumb";
import { getFromLocalStorage } from "../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { getUser } from "../../redux_apis/redux_login/thunk";
import { getCategories } from "../../redux_apis/redux_categories/thunk";
import { getAllProducts } from "../../redux_apis/redux_products/thunk";

const LayoutWrapper = ({ children, search = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   const token = getFromLocalStorage("APP_TOKEN");
  //   if (!token) {
  //     navigate("/");
  //   }
  //   if (token) {
  //   }
  // }, [children]);
  useEffect(() => {
    dispatch(getUser());
    dispatch(getCategories());
    dispatch(getAllProducts());
  }, []);

  return (
    <div>
      <Header />
      <Breadcrumb search={search} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default LayoutWrapper;
