import React, { useEffect, useState, useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import Cookies from "js-cookie";
import { getFromLocalStorage } from "../utils/helperFunctions";
import "./Paymentconfirm.css";
import logo from "../assets/images/logo.png";
import { removeFromLocalStorage } from "../utils/helperFunctions";
import { Link, useLocation, useNavigate } from "react-router-dom";


const MyAccount = () => {
    const navigate = useNavigate();

    const email = Cookies.get("Email_guest");
    const email1 = getFromLocalStorage("Email");
    const [activeTab, setActiveTab] = useState("tab1");
    const [invoicePreview, setInvoicePreview] = useState(null);
    const [ordersData, setOrdersData] = useState([]);
    const [userData, setUserData] = useState({});
    const invoiceRef = useRef();
    const [showLogoutPopup, setShowLogoutPopup] = useState(false); // State for logout popup

    useEffect(() => {
        if (!email && !email1) return;

        const fetchAccountData = (emailToFetch) => {
            fetch(`https://backend.inkox.com/api/user_login_email/${emailToFetch}`)
                .then((response) => response.json())
                .then((data) => setUserData(data[0]))
                .catch((error) => console.error("Error fetching account data:", error));
        };

        const fetchOrderData = (emailToFetch) => {
            fetch(`https://backend.inkox.com/api/place-orders_email/${emailToFetch}`)
                .then((response) => response.json())
                .then((data) => {
                    if (Array.isArray(data)) {
                        setOrdersData(data);
                    } else {
                        console.error("Unexpected orders data format:", data);
                        setOrdersData([]);
                    }
                })
                .catch((error) => console.error("Error fetching orders data:", error));
        };

        if (email) {
            fetchAccountData(email);
            fetchOrderData(email);
        } else if (email1) {
            fetchAccountData(email1);
            fetchOrderData(email1);
        }
    }, [email, email1]);

    const handleTabChange = (tab) => {
        setInvoicePreview(null);
        setActiveTab(tab);
    };

    const handleInvoicePreview = (order) => {
        setInvoicePreview(order);
    };

    const handleBackToOrders = () => {
        setInvoicePreview(null);
    };

    const handleDownload = () => {
        const pdf = new jsPDF("p", "pt", "a4");
        const content = invoiceRef.current;

        pdf.html(content, {
            callback: function (doc) {
                doc.save(`Invoice-${invoicePreview.id}.pdf`);
            },
            x: 0,
            y: 0,
            html2canvas: {
                scale: 1,
                scrollX: 0,
                scrollY: 0,
            },
        });
    };

    const calculateOrderTotal = (cartData) => {
        return cartData.reduce((total, item) => total + parseFloat(item.totalPrice), 0);
    };

    const formatQuantities = (quantities) => {
        return Object.entries(quantities)
            .map(([size, quantity]) => `${size}: ${quantity}`)
            .join(", ");
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleUpdate = () => {
        if (!userData.id) {
            alert("User ID is missing!");
            return;
        }

        const updatedData = {
            name: userData.name,
            email: userData.email,
            pwd: userData.password,
        };

        fetch(`https://backend.inkox.com/api/user_login/${userData.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedData),
        })
            .then((response) => {
                if (response.ok) {
                    alert("Account details updated successfully!");
                    return response.json();
                } else {
                    throw new Error("Failed to update account details");
                }
            })
            .then((data) => {
                console.log("Updated data:", data);
                window.location.reload();
            })
            .catch((error) => {
                console.error("Error updating account details:", error);
                alert("Error updating account details. Please try again.");
            });
    };

    const handleLogout = () => {
        removeFromLocalStorage("Id");
        removeFromLocalStorage("Email");
        removeFromLocalStorage("APP_TOKEN");
        localStorage.clear(); // Clear all localStorage
        setUserData(null); // Reset user data
        setShowLogoutPopup(false); // Close the popup
        navigate("/login");

    };

    
    

    const handleMobileSelectChange = (event) => {
        const selectedTab = event.target.value;
        if (selectedTab === "tab3") {
            setShowLogoutPopup(true); // Show the logout popup
        } else {
            setActiveTab(selectedTab);
        }
    };
    

 console.log("userData",userData[0]);
 

    return (
        <LayoutWrapper>
            <div className="myaccount-page">
                <div className="container">
                    <div className="myaccount-wrapper">
                        <div className="header-wrapper">
    <h1>Welcome Back, {userData.name || "User"}!</h1>
                        </div>

                        {/* Mobile Select Dropdown */}
                        <div className="mobile-select">
                            <select onChange={handleMobileSelectChange} value={activeTab}>
                                <option value="tab1">Order History</option>
                                <option value="tab2">Account Setting</option>
                                <option value="tab3">Logout</option>
                            </select>
                        </div>

                        <div className="property">
                            <div className="sidebar-account-page">
                                <ul className="side-menu">
                                    <li
                                        className={activeTab === "tab1" ? "active" : ""}
                                        onClick={() => handleTabChange("tab1")}
                                    >
                                        Order History
                                    </li>
                                    <li
                                        className={activeTab === "tab2" ? "active" : ""}
                                        onClick={() => handleTabChange("tab2")}
                                    >
                                        Account Setting
                                    </li>
                                    <li
                                        className={activeTab === "tab3" ? "active" : ""}
                                        onClick={() => setShowLogoutPopup(true)}
                                    >
                                        Logout
                                    </li>
                                </ul>
                            </div>

                            <div className="content-account">
                                {activeTab === "tab1" && (
                                    <div>
                                        {!email && !email1 ? (
                                            <div className="no-email">
                                                <p>Please login to preview your placed order details.</p>
                                            </div>
                                        ) : invoicePreview ? (
                                            <div className="main-invoice">
                                                <div className="invoice-container" ref={invoiceRef}>
                                                    <img src={logo} className="logo-invoice" alt="Logo" />
                                                    <div className="invoice-header">
                                                        <h1>INVOICE</h1>
                                                        <h1 className="invoice-number">
                                                            No. {invoicePreview.id}
                                                        </h1>
                                                    </div>
                                                    <div className="invoice-details">
                                                        <div className="invoice-to">
                                                            <h4>INVOICE TO:</h4>
                                                            <p>
                                                                {invoicePreview.deliveryFirstName}{" "}
                                                                {invoicePreview.deliveryLastName}
                                                            </p>
                                                            <p>Phone: {invoicePreview.phone}</p>
                                                        </div>
                                                        <div className="invoice-date">
                                                            <h4>Invoice Date:</h4>
                                                            <p>
                                                                {new Date(
                                                                    invoicePreview.created_at
                                                                ).toDateString()}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <table className="invoice-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Product Name</th>
                                                                <th>Quantity</th>
                                                                <th>Unit Price</th>
                                                                <th>Total Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {invoicePreview.cartData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.productName}</td>
                                                                    <td>
                                                                        {formatQuantities(item.quantities)}
                                                                    </td>
                                                                    <td>${item.unitPrice}</td>
                                                                    <td>${item.totalPrice}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <div className="invoice-summary">
                                                        <h4>
                                                            GRAND TOTAL: $
                                                            {calculateOrderTotal(
                                                                invoicePreview.cartData
                                                            ).toFixed(2)}
                                                        </h4>
                                                    </div>
                                                    <div className="invoice-footer">
                                                        <p className="thank-you">Thank You</p>
                                                        <div className="payment-info">
                                                            <h4>PAYMENT INFORMATION:</h4>
                                                            <p>Bank Name: Wardiere Inc</p>
                                                            <p>Bank No.: 123-456-7890</p>
                                                            <p>Email: hello@reallygreatsite.com</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="button-container">
                                                    <button
                                                        onClick={handleBackToOrders}
                                                        className="back-button"
                                                    >
                                                        Back to Orders
                                                    </button>
                                                    <button
                                                        onClick={handleDownload}
                                                        className="download-button"
                                                    >
                                                        Download / Save PDF
                                                    </button>
                                                </div>
                                            </div>
                                        ) : Array.isArray(ordersData) && ordersData.length === 0 ? (
                                            <div className="no-orders">
                                                <p>You have not placed any orders yet.</p>
                                            </div>
                                        ) : (
                                            <div className="table-container">
                                                <h2>Order History</h2>
                                                <table className="styled-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Sno</th>
                                                            <th>Product</th>
                                                            <th>Design</th>
                                                            <th>Quantity</th>
                                                            <th>Preview</th>
                                                            <th>Delivery Date</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(ordersData) &&
                                                            ordersData.map((order, index) =>
                                                                order.cartData.map((item, itemIndex) => (
                                                                    <tr key={`${index}-${itemIndex}`}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.productName}</td>
                                                                        <td>{item.designName}</td>
                                                                        <td>{formatQuantities(item.quantities)}</td>
                                                                        <td>
                                                                            {item.imagePreviews.map(
                                                                                (img, imgIndex) => (
                                                                                    <img
                                                                                        key={imgIndex}
                                                                                        src={img.preview}
                                                                                        alt={`Preview ${imgIndex + 1}`}
                                                                                        style={{
                                                                                            width: "50px",
                                                                                            height: "50px",
                                                                                            marginRight: "5px",
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.Delivery_date
                                                                                    ?.Standard
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <button className="seeinvoices"
                                                                                onClick={() =>
                                                                                    handleInvoicePreview(
                                                                                        order
                                                                                    )
                                                                                }
                                                                            >
                                                                                See Invoice
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                )}
                                   {activeTab === "tab2" && (
                                    <div className="account-setting">
                                        <h2>Account Setting</h2>
                                        <form className="account-form">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={userData.name || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={userData.email || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={userData.password || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <button type="button" className="update-btn" onClick={handleUpdate}>
                                                Update
                                            </button>
                                        </form>
                                    </div>
                                )}

                                {activeTab === "tab3" && (
                                    <div className="logout-tab">
                                        <h3>Click Logout to Exit</h3>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {showLogoutPopup && (
                    <div className="logout-popup">
                        <div className="popup-content">
                            <h3>Are you sure you want to logout?</h3>
                            <div className="popup-actions">
                                <button onClick={handleLogout} className="confirm-btn">
                                    Yes
                                </button>
                                <button
                                    onClick={() => setShowLogoutPopup(false)}
                                    className="cancel-btn"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </LayoutWrapper>
    );
};

export default MyAccount;
