import React from "react";
import Container from "../../components/container/Container";
import { Col, Row } from "antd";
import BrandCard from "../../components/brandCard/BrandCard";
import Title from "../../components/typography/title/Title";

const BrandDeserves = ({ data }) => {
  return (
    <div className="brandDeserves">
      <Container>
        <Title size={"41"} tag={"h2"} weight={700}>
          Your Brand Deserves the Best
        </Title>
        <Title customClass={"sub-title"} size={"21"} tag={"h2"} weight={500}>
          Why so many choose us for custom apparel
        </Title>
        <Row className="brands-container">
          {data?.map((item, index) => {
            return (
              <Col lg={6} md={12} xs={24}  key={index}>
                <BrandCard data={item} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default BrandDeserves;
