import React, { useState } from "react";
import Container from "../../components/container/Container";
import { Checkbox, Form, Input, Col, Row, Steps, theme, Radio } from "antd";
import Title from "../../components/typography/title/Title";
import Description from "../../components/typography/description/Description";
import ChatTalk from "../chatTalk/ChatTalk";
import productImg from "../../assets/images/cart-item.png";
import { Link } from "react-router-dom";

const ProductDeliveryProcess = () => {
  const steps = [
    {
      title: (
        <Title size={"26"} tag={"h4"} weight={600}>
          Contact
        </Title>
      ),
      content: "First-content",
    },
    {
      title: (
        <Title size={"26"} tag={"h4"} weight={600}>
          Shipping
        </Title>
      ),
      content: "Second-content",
    },
    {
      title: (
        <Title size={"26"} tag={"h4"} weight={600}>
          Payment
        </Title>
      ),
      content: "Last-content",
    },
  ];
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="productDeliveryProcess">
      <Steps className="customStepsForForm" current={current} items={items} />
      <Container>
        <Row>
          <Col xs={24} sm={24} md={8} lg={13}>
            <div className="left-content">
              <Title size={"38"} tag={"h4"} weight={600}>
                Contact
              </Title>
              <Description customClass={"para-one"}>
                This information is used to send proofs of the order, show order
                status, and contact you if we have any questions with the order.
              </Description>

              <Form
                name="contact"
                layout="vertical"
                initialValues={{
                  notification: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={[20, 5]}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      label="First Name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      label="Last Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      label="Organization (e.g. CalTech, Sharks Soccer) "
                      name="organization"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      label="Position (e.g. Social Chair, Assistant) "
                      name="position"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      label="PHONE NUMBER (Mobile Preferred) "
                      name="phone_number"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item name="notification" valuePropName="checked">
                      <Checkbox>
                        Receive helpful text notifications regarding my order{" "}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      label="How did you hear about ? "
                      name="hear_about"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Description customClass={"para-two"}>
                      Did you know that Custom Ink has a platform where you can
                      sell your custom apparel online to raise money?
                    </Description>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item label={false} name="custom_platform">
                      <Radio.Group>
                        <Radio value={"yes"}>Yes</Radio>
                        <Radio value={"no"}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Link to={"#"} className="blueBtn">
                  Continue to Shipping
                </Link>
              </Form>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={{ offset: 2, span: 9 }}>
            <div className="product-info-box">
              <Title
                customClass={"product-name"}
                size={"26"}
                tag={"h4"}
                weight={600}
              >
                Golf Club T-shirt Design
              </Title>
              <div className="product-feature">
                <label>Product:</label>
                <Description>
                  Next Level Women's Tri-Blend Racerback Tank Top
                </Description>
              </div>

              <div className="inline-row">
                <div className="images">
                  <img src={productImg} alt="product-img" />
                  <img src={productImg} alt="product-img" />
                </div>
                <div className="product-size-color">
                  <div>
                    <label>Color:</label>
                    <Description>
                      <span className="color-box"></span>
                      Heather White
                    </Description>
                  </div>
                  <div>
                    <label>Size:</label>
                    <Description>XS-155, S-5, M-5</Description>
                  </div>
                </div>
              </div>
              <ul className="quantity-price">
                <li>
                  <Title
                    customClass={"actual-price"}
                    size={"25"}
                    tag={"h4"}
                    weight={400}
                  >
                    17.22 each
                  </Title>
                  <Title
                    customClass={"discounted-price"}
                    size={"33"}
                    tag={"h4"}
                    weight={600}
                  >
                    14.22 each
                  </Title>
                </li>
                <li>
                  <Title
                    customClass={"total-heading"}
                    size={"43"}
                    tag={"h2"}
                    weight={600}
                  >
                    TOTAL
                  </Title>
                  <div>
                    <Description>165 items</Description>
                    <Description>2,346.30</Description>
                  </div>
                </li>
              </ul>

              <Link to={"#"} className="blueBtn">
                Continue to Shipping
              </Link>
            </div>
          </Col>
        </Row>
        <Row align={"middle"}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Description customClass={"para-three"}>
              Talk to a Real Person 7 Days a Week
            </Description>
            <div className="timings">
              <Description>8am-Midnight ET Mon-Fri</Description>
              <Description>10am-6pm ET Saturday</Description>
              <Description>10am-6pm ET Sunday</Description>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <ChatTalk size={"large"} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductDeliveryProcess;
