import React, { useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Invoice.css"; // Add styles in this file
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import logo from "../assets/images/logo.png";

const Invoice = () => {
  const invoiceRef = useRef();
  const invoiceNumber = "5163"; // Replace this with dynamic data if needed

  const handleDownload = () => {
    const pdf = new jsPDF("p", "pt", "a4"); // A4 size: 595.28 x 841.89 points
    const content = invoiceRef.current;

    // Convert the content to a canvas using html2canvas
    pdf.html(content, {
      callback: function (doc) {
        // Automatically save the generated PDF with a filename
        doc.save(`Invoice-${invoiceNumber}.pdf`);
      },
      x: 0,
      y: 0,
      html2canvas: {
        scale: 1, // Render canvas at 100% scale for clarity
        scrollX: 0,
        scrollY: 0,
      },
    });
  };

  return (
    <LayoutWrapper>
      <div className="main-invoice">
        <div className="invoice-container" ref={invoiceRef}>
          {/* Header Section */}
          <img src={logo} className="logo-invoice" alt="Logo" />
          <div className="invoice-header">
            <h1>INVOICE</h1>
            <h1 className="invoice-number">No. {invoiceNumber}</h1>
          </div>

          {/* Invoice Details */}
          <div className="invoice-details">
            <div className="invoice-to">
              <h4>INVOICE TO:</h4>
              <p>Fauget</p>
              <p>Telp. 123-456-7890</p>
            </div>
            <div className="invoice-date">
              <h4>Invoice Date:</h4>
              <p>8 May 2025</p>
            </div>
          </div>

          {/* Table Section */}
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Item Description</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Graphic Design</td>
                <td>02</td>
                <td>$300</td>
                <td>$300</td>
              </tr>
              <tr>
                <td>Logo Studies</td>
                <td>02</td>
                <td>$200</td>
                <td>$500</td>
              </tr>
              <tr>
                <td>Invoice Design</td>
                <td>02</td>
                <td>$250</td>
                <td>$750</td>
              </tr>
              <tr>
                <td>Logo Design</td>
                <td>01</td>
                <td>$500</td>
                <td>$1250</td>
              </tr>
              <tr>
                <td>Web Design</td>
                <td>01</td>
                <td>$500</td>
                <td>$1750</td>
              </tr>
            </tbody>
          </table>

          {/* Summary Section */}
          <div className="invoice-summary">
            <p>Subtotal: $1750</p>
            <p>Tax (15%): $262.5</p>
            <h4>GRAND TOTAL: $2012.5</h4>
          </div>

          {/* Footer Section */}
          <div className="invoice-footer">
            <p className="thank-you">Thank You</p>
            <div className="payment-info">
              <h4>PAYMENT INFORMATION:</h4>
              <p>Bank Name: Wardiere Inc</p>
              <p>Bank No.: 123-456-7890</p>
              <p>Email: hello@reallygreatsite.com</p>
            </div>
          </div>
        </div>
      </div>

      {/* Download Button */}
      <div className="download-button-container">
        <button onClick={handleDownload} className="download-button">
          Download / Save PDF
        </button>
      </div>
    </LayoutWrapper>
  );
};

export default Invoice;
