import React, { useState, useContext } from 'react';
import { ColorContext } from '../context/ColorContext';
import { useNavigate } from 'react-router-dom';

const ExportButton = ({
  productName,
  totalQuantity,
  canvasOne,       // Front canvas
  canvasTwo,       // Back canvas
  canvasThree,     // Sleeves canvas
  canvasFour,
  extractColorsFromObject,
  selectedFont,
  Canvasid,
  totalPrice,
  basePrice,
  zipCode,
  designName,
  email,
  mcategory,
  uniqueKey,
  selcolor,
  standardDeliveryDate,
  superRushDate,
  rushDate,
  orderType,
  sale_price
}) => {
  const { selectedColor } = useContext(ColorContext);
  const [inkColors, setInkColors] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [clipArtPreviews, setClipArtPreviews] = useState([]);
  const [loading, setLoading] = useState(false);  // Add loading state
 console.log("selcolor:",selcolor);
 const numcolor = localStorage.getItem("numcolor")
 console.log("numcolor",numcolor);
  const navigate = useNavigate();

  const filterColors = (colors) => {
    return colors.filter(color =>
      color !== 'transparent' &&
      color !== 'rgb(0,0,0)' &&
      color !== 'rgba(0,0,0,0)' &&
      color !== 'rgba(150, 150, 150, 1)' &&
      color !== 'none' &&
      color !== selectedColor // Exclude the product color from ink colors
    );
  };



  const extractAndPreviewColors = () => {
    const colorsSet = new Set();

    // Extract colors from objects, but only if the object has non-zero opacity
    [canvasOne, canvasTwo, canvasThree, canvasFour].forEach((canvas) => {
      if (canvas) {
        canvas.getObjects().forEach((obj) => {
          if (obj.opacity !== 0) { // Ensure the object is visible
            extractColorsFromObject(obj).forEach(color => colorsSet.add(color));
          }
        });
      }
    });

    const filteredColors = filterColors(Array.from(colorsSet));
    setInkColors(filteredColors);
  };



  const generateImagePreviews = () => {
    const previews = [];
    const clipArtPreviews = [];

    // Capture both image and clip art objects
    const captureCanvasImage = (canvas, part) => {
      if (canvas && canvas.getObjects().length > 0) {
        // Render the canvas to ensure the latest state
        canvas.renderAll();

        // Capture the canvas image as base64
        const preview = canvas.toDataURL('image/png');
        previews.push({ part, preview });

        // Loop through objects to capture clip art and images separately
        canvas.getObjects().forEach((obj) => {
          if (obj.designElement && obj.clipartPath && obj.opacity !== 0) {
            // Capture clip art
            const clipArtCanvas = document.createElement('canvas');
            clipArtCanvas.width = obj.width * obj.scaleX;
            clipArtCanvas.height = obj.height * obj.scaleY;
            const ctx = clipArtCanvas.getContext('2d');
            obj.render(ctx);
            clipArtPreviews.push({
              clipartPath: obj.clipartPath,
              part,
              preview: clipArtCanvas.toDataURL('image/png')
            });

            console.log('clipArtCanvas',clipArtCanvas);

          
          } else if (obj.type === 'image' && obj.opacity !== 0) {
            // Capture uploaded images
            const imageCanvas = document.createElement('canvas');
            imageCanvas.width = obj.width * obj.scaleX;
            imageCanvas.height = obj.height * obj.scaleY;
            const ctx = imageCanvas.getContext('2d');
            obj.render(ctx);
            previews.push({
              part,
              preview: imageCanvas.toDataURL('image/png')
            });
          }
        });
      } else {
        previews.push({ part, preview: null });
      }
    };

    // Capture all canvas images and clip art
    captureCanvasImage(canvasOne, 'Front');
    captureCanvasImage(canvasTwo, 'Back');
    captureCanvasImage(canvasThree, 'Right');
    captureCanvasImage(canvasFour, 'Left');

    // Set the previews for display
    setImagePreviews(previews);
    setClipArtPreviews(clipArtPreviews);
  };


  const exportJSON = async () => {
    setLoading(true);  // Set loading to true when starting the export

    const colorsSet = new Set();
    const fontsSet = new Set();
    const clipArtData = [];
    const uploadedImageData = [];
    const partColors = {
      Front: new Set(),
      Back: new Set(),
      Right: new Set(),
      Left: new Set(),
    };
    
    [canvasOne, canvasTwo, canvasThree, canvasFour].forEach((canvas, index) => {
      const part = index === 0 ? 'Front' : index === 1 ? 'Back' : index === 2 ? 'Right' : index === 3 ? 'Left' : "";
      console.log("canvasOne",canvasOne.getObjects());
      if (canvas) {
        canvas.getObjects().forEach((obj) => {
          if (obj.opacity !== 0) { // Ensure the object is visible
            // Extract colors and add to the corresponding part
            extractColorsFromObject(obj).forEach((color) => partColors[part].add(color));
    
            // Collect font information for text paths
            if (obj.type === 'path' && obj.fontFamily) {
              fontsSet.add(obj.fontFamily);
            }
    
            // Collect clip art data
            if (obj.type === 'group' && obj.opacity !== 0) {
              console.log("clipartPath",obj.clipartPath);
              clipArtData.push({
                clipartPath: obj.clipartPath,
                part,
                width: obj.width * obj.scaleX,
                height: obj.height * obj.scaleY,
                left: obj.left,
                top: obj.top,
                scaleX: obj.scaleX,
                scaleY: obj.scaleY,
              });
            }
    
            // Collect uploaded image data
            if (obj.type === 'image' && obj.opacity !== 0) {
              uploadedImageData.push({
                imageSrc: obj.getSrc(),
                part,
                width: obj.width * obj.scaleX,
                height: obj.height * obj.scaleY,
                left: obj.left,
                top: obj.top,
                scaleX: obj.scaleX,
                scaleY: obj.scaleY,
              });
            }
          }
        });
      }
    });
    
    // Convert partColors Sets to Arrays for easier manipulation
const structuredColors = Object.fromEntries(
  Object.entries(partColors).map(([part, colorsSet]) => [
    part, 
    filterColors(Array.from(colorsSet)) // Apply the filterColors function here
  ])
);

console.log("Filtered Structured Colors by Part:", structuredColors);
    
    const filteredColors = filterColors(Array.from(colorsSet));

    console.log('filterColorsss',filteredColors);
    const selectedFonts = Array.from(fontsSet); // Convert the Set of fonts to an array
  
    // Retrieve the quantities from local storage
    const storedQuantities = JSON.parse(localStorage.getItem(uniqueKey)) || {};
    
    // Filter out sizes with a quantity of 0
    const filteredQuantities = Object.entries(storedQuantities).reduce((acc, [size, qty]) => {
      if (qty > 0) {
        acc[size] = qty;
      }
      return acc;
    }, {});
  
    // Include all canvas images (front, back, sleeves) in the export data
    const imagePreviews = [
      canvasOne ? { part: 'Front', preview: canvasOne.toDataURL('image/jpg') } : null,
      canvasTwo ? { part: 'Back', preview: canvasTwo.toDataURL('image/jpg') } : null,
      canvasThree ? { part: 'Right', preview: canvasThree.toDataURL('image/jpg') } : null,
      canvasFour ? { part: 'Left', preview: canvasFour.toDataURL('image/jpg') } : null,
    ].filter(Boolean); // Filter out any null values
  
    // Calculate unitPrice
    const unitPrice = totalQuantity > 0 ? (totalPrice / totalQuantity).toFixed(2) : basePrice;
    const userId = localStorage.getItem("Id");
    console.log("imagePreviews",imagePreviews);
    const imageUrls = await uploadImages(imagePreviews);

    console.log("imagePreviews",imagePreviews);
    // Export data with clip art and uploaded images included
    const uploadedImageUrls = await uploadUploadedImages(uploadedImageData);

    const exportData = {
      productName,
      userId,
      garment: mcategory,
      Email: email,
      designName,
      zipCode,
      canvasId: Canvasid,               
      productColor: selcolor,
      selectedFonts,                    
      inkColors: structuredColors,
      quantities: filteredQuantities,   
      totalQuantity,                    
      totalPrice, 
      unitPrice,                        
      basePrice,                        
      imagePreviews: imageUrls,                   
      clipArtData,                      
      uploadedImageData: uploadedImageUrls,
      orderType: orderType,
      sale_price,
      Delivery_date: {
        standard: standardDeliveryDate,
        rush: rushDate,
        superRush: superRushDate
      }              
    };
  
    console.log("rush", rushDate);
    try {
      // Post the exportData to the API
      const response = await fetch('https://backend.inkox.com/api/checkouts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(exportData)
      });
  
      if (response) {
        setLoading(false);  // Set loading to false after the process is done

        navigate("/cart");
      } else {
        console.error('Failed to post data to the API');
        setLoading(false);  // Set loading to false in case of failure

      }
    } catch (error) {
      console.error('Error while posting data to the API:', error);
    }
  

  };
  
  const uploadImages = async (imagePreviews) => {
    const uploadedImages = await Promise.all(imagePreviews.map(async (image) => {
      if (image && image.preview) {
        const compressedImage = await compressImage(image.preview, 20000); // Compress to ~20KB
        const blob = await (await fetch(compressedImage)).blob(); // Convert base64 to blob
        const formData = new FormData();
        formData.append('image', blob, `${image.part}.webp`);
  
        try {
          const response = await fetch('https://backend.inkox.com/api/upload-image', {
            method: 'POST',
            body: formData,
          });
  
          const result = await response.json();
          return { part: image.part, preview: result.imageUrl }; // Return the uploaded image URL
        } catch (error) {
          console.error(`Failed to upload image for ${image.part}:`, error);
          return { part: image.part, preview: null }; // Return null if upload fails
        }
      }
      return { part: image.part, preview: null }; // Return null if no image
    }));
  
    return uploadedImages.filter(img => img.preview !== null); // Filter out any failed uploads
  };

  const uploadUploadedImages = async (uploadedImageData) => {
    const uploadedImages = await Promise.all(uploadedImageData.map(async (image) => {
      if (image && image.imageSrc) {
        try {
          // Compress the image (this is assuming image.imageSrc is a base64 string)
          const compressedImage = await compressImage(image.imageSrc, 1000); // Compress to ~20KB
  
          // Convert base64 string to blob
          const blob = await (await fetch(compressedImage)).blob();
  
          // Create a FormData object and append the image blob
          const formData = new FormData();
          formData.append('image', blob, `${image.part}.webp`);
  
          // Make the API call to upload the image
          const response = await fetch('https://backend.inkox.com/api/upload-image', {
            method: 'POST',
            body: formData,
          });
  
          const result = await response.json();
          
          // Return the uploaded image URL
          return { part: image.part, preview: result.imageUrl };
        } catch (error) {
          console.error(`Failed to upload image for ${image.part}:`, error);
          return { part: image.part, preview: null };
        }
      }
  
      // Return null if no image is found
      return { part: image.part, preview: null };
    }));
  
    return uploadedImages.filter(img => img.preview !== null); // Filter out any failed uploads
  };
  
  

  const compressImage = (base64Str, maxFileSizeInBytes) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";  // Allow cross-origin images to be loaded
      img.src = base64Str;
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        const MAX_WIDTH = 800; // Adjust the max width as needed
        const scaleSize = MAX_WIDTH / img.width;
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleSize;
  
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
        // Extract the base64 string portion
        const base64Image = base64Str.split(',')[1];
  
        if (!base64Image) {
          return reject(new Error('Failed to extract base64 string from the provided image URL.'));
        }
  
        // Log initial image size in KB
        let originalSize = (base64Image.length * (3 / 4)) / 1024; // Approximate size in KB
        console.log(`Original image size: ${originalSize} KB`);
  
        // Compress the image
        let quality = 0.7; // Start with a balance between quality and compression
        let compressedDataUrl = canvas.toDataURL('image/webp', quality);
        let compressedBase64Image = compressedDataUrl.split(',')[1]; // Extract base64 string
        let compressedSize = (compressedBase64Image.length * (3 / 4)) / 1024; // Approximate size in KB
  
        console.log(`Initial compressed size: ${compressedSize} KB`);
  
        // Loop to adjust quality and reduce size
        while (compressedSize > maxFileSizeInBytes / 1024 && quality > 0.1) {
          quality -= 0.1; // Reduce quality for more compression
          compressedDataUrl = canvas.toDataURL('image/webp', quality);
          compressedBase64Image = compressedDataUrl.split(',')[1]; // Extract base64 string
          compressedSize = (compressedBase64Image.length * (3 / 4)) / 1024; // Recalculate size
          console.log(`Compressed size at quality ${quality}: ${compressedSize} KB`);
        }
  
        // If we couldn't reach the desired size, log and reject or resolve with the best possible quality
        if (compressedSize > maxFileSizeInBytes / 1024) {
          console.warn('Could not compress image to desired size.');
          resolve(compressedDataUrl); // Resolve with the smallest possible image
        } else {
          resolve(compressedDataUrl); // Resolve with the properly compressed image
        }
      };
  
      img.onerror = (err) => {
        console.error('Image load error:', err);
        reject(err);
      };
    });
  };
  
  
  
  

  // Function to upload user-uploaded image data


// Function to compress images


  return (
    <div>
      {/* <button onClick={extractAndPreviewColors} className="preview-button">
        Preview Ink Colors
      </button>
      <div className="color-preview">
        {inkColors.map((color, index) => (
          <div
            key={index}
            style={{
              backgroundColor: color,
              width: '30px',
              height: '30px',
              display: 'inline-block',
              margin: '5px',
            }}
            title={color}
          ></div>
        ))}
      </div>
      
      <button onClick={generateImagePreviews} className="preview-button">
        Preview Images & Clip Art
      </button>
      <div className="image-preview">
        {imagePreviews.map((image, index) => (
          <div key={index}>
            <h4>{image.part}</h4>
            <img
              src={image.preview}
              alt={`Preview of ${image.part}`}
              style={{
                width: '100px',
                height: '100px',
                display: 'inline-block',
                margin: '5px',
              }}
            />
          </div>
        ))}
      </div>
      <div className="clipart-preview">
        {clipArtPreviews.map((clipArt, index) => (
          <div key={index}>
            <h4>Clip Art - {clipArt.part}</h4>
            <img
              src={clipArt.preview}
              alt={`Clip Art on ${clipArt.part}`}
              style={{
                width: '100px',
                height: '100px',
                display: 'inline-block',
                margin: '5px',
              }}
            />
          </div>
        ))}
      </div> */}
        {loading ? (
        <div className="loader">   <div className="spinner"></div>Just a moment! We're adding your product to the cart.</div> // You can replace this with an actual loader component or spinner
      ) : (
      
          <button onClick={exportJSON} className="export-button continue-btn">
        Add to Cart
      </button>

)}
    </div>
  );
};

export default ExportButton;
