import React from "react";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import ProductDeliveryProcess from "../widgets/productDeliveryProcess/ProductDeliveryProcess";

const ProductDelivery = () => {
  return (
    <LayoutWrapper>
      <ProductDeliveryProcess />
    </LayoutWrapper>
  );
};

export default ProductDelivery;
