import React, { useState, useEffect } from "react";
import "./ProductColor.css";
import { fabric } from "fabric";
import ObjectColorComponent from "./ObjectColorComponent";
import OutlineSettings from "./OutlineSettings";
import close from "../assets/close.png"
import bringtofront from '../assets/bringtofront.png';

import mirrorvertical from '../assets/vertical.png';

import mirrorhorizontal from '../assets/horizontal.png';
import lock from '../assets/lock.png';
import unlock from '../assets/unlock.png';
import objectcenter from '../assets/objectcenter.png';
import sizeclip from '../assets/sizeclip.svg';
import colormobicon from '../assets/colormobicon.svg';
import outlinefontmob from '../assets/outlinefontmob.svg';
import transformclip from '../assets/transformclip.svg';





export default function DetailClipartBox({
  canvas,
  selectedObject,
  isLocked,
  setIsLocked,
  originalColors,
  rotation,
  clipwidth,
  clipheight,
  onRotationChange,
  calculatePrice,
  addToHistory,
  getCanvasByPartName,
  activeTab,
  selectedObjectColors,
  setSelectedObjectColors,
  selectedColor,
  setSelectedColor,
  showColorPalette,
  setShowColorPalette,
  inkColors,
  setInkColors,
  onColorChange,
  handleFillChange,
  outlineWidth,
  onOutlineWidthChange,
  outlineColor,
  onOutlineColorChange,
  closePropertiesSidebar,
}) {
  const [isSelected, setIsSelected] = useState(false);
 const [isFlippedHorizontal, setIsFlippedHorizontal] = useState(false); // State for flip horizontal
 const [isFlippedVertical, setIsFlippedVertical] = useState(false); // St
 const [isCentered, setIsCentered] = useState(false); 
  const [localRotation, setLocalRotation] = useState(rotation);
  const [isOutlineSettingsOpen, setIsOutlineSettingsOpen] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(null); // Manage active tab for mobile screens
  const isMobileScreen = window.innerWidth <= 900;

  useEffect(() => {
    if (selectedObject) {
      setLocalRotation(rotation);
      setIsFlippedHorizontal(selectedObject.flipX || false);
      setIsFlippedVertical(selectedObject.flipY || false);
      setIsCentered(selectedObject.isCentered || false); 
      setIsSelected(selectedObject.isSelected || false); 
    }
  }, [selectedObject, rotation]);

  const handleCenterObject = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.center();
      selectedObject.setCoords();
      canvas.renderAll();
  
      // Save the center state in the object
      selectedObject.isCentered = true; // Manually store the centered state
    }
  
    // Toggle the selected state for center button
    setIsCentered(!isCentered);
  };
  
  const handleBringToFront = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.bringToFront();
      canvas.renderAll();
  
      // Save the bring-to-front state in the object
      selectedObject.isSelected = true; // Manually store the bring-to-front state
    }
  
    setIsSelected(!isSelected); // Toggle the state
  };
  

 const handleFlipHorizontal = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.toggle('flipX');
      canvas.renderAll();
    }

    // Toggle the selected state for flip horizontal
    setIsFlippedHorizontal(!isFlippedHorizontal); 
  };

  const handleFlipVertical = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.toggle('flipY');
      canvas.renderAll();
    }

    // Toggle the selected state for flip vertical
    setIsFlippedVertical(!isFlippedVertical); 
  };

  const handleToggleLock = () => {
    if (selectedObject) {
      setIsLocked(!isLocked);
      selectedObject.set({
        lockUniScaling: !isLocked,
        lockScalingX: isLocked,
        lockScalingY: isLocked,
      });
      selectedObject.setCoords();
      canvas.renderAll();
    }
  };



  const handleRotationChange = (e) => {
    const newRotation = parseInt(e.target.value, 10);
    setLocalRotation(newRotation);
  
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.set({
        angle: newRotation,
        originX: 'center',  // Set rotation origin to center
        originY: 'center'
      });
      selectedObject.setCoords();
      setLocalRotation(newRotation);
      onRotationChange(newRotation); // Use the handler
      canvas.renderAll();
    }
  };

  const toggleOutlineSettings = () => {
    setIsOutlineSettingsOpen(!isOutlineSettingsOpen);
  };

  const handleOutlineWidthChange = (width) => {
    onOutlineWidthChange(width);
    if (selectedObject) {
      applyOutline(selectedObject, width, outlineColor);
    }
  };

  const handleOutlineColorChange = (color) => {
    onOutlineColorChange(color);
    if (selectedObject) {
      applyOutline(selectedObject, outlineWidth, color);
    }
  };

  const applyOutline = (object, width, color) => {
    if (object.type === "group") {
      object.getObjects().forEach((obj) => {
        obj.set("strokeWidth", width);
        obj.set("stroke", color);
      });
    } else {
      object.set("strokeWidth", width);
      object.set("stroke", color);
    }
    object.setCoords();
    canvas.renderAll();
  };

  const renderTransformControls = () => (
    <div>
       <div className="icon-row">
                      <button title="Center Object" onClick={handleCenterObject}        className={isCentered ? "selected" : ""}
>
                      <img src={objectcenter} alt="bringtofront" className="top-icon"/>
                      </button>
                      <button title="Bring to Front" onClick={handleBringToFront}  className={isSelected ? "selected" : ""}>
                      <img src={bringtofront} alt="bringtofront" className="top-icon"/>
                  
                      </button>
                      <button title="Flip Horizontal" onClick={handleFlipHorizontal}  className={isFlippedHorizontal ? "selected" : ""}>
                      <img src={mirrorhorizontal} alt="bringtofront" className="top-icon"/>

                
                      </button>
                      <button title="Flip Vertical" onClick={handleFlipVertical}        className={isFlippedVertical ? "selected" : ""}
>
                      <img src={mirrorvertical} alt="bringtofront" className="top-icon"/>

               
                      </button>
                      <button title="Lock/Unlock Object" onClick={handleToggleLock}>
  {isLocked ? (
    <img src={lock} alt="Locked" className="top-icon" />
  ) : (
    <img src={unlock} alt="Unlocked" className="top-icon" />
  )}
</button>

                    </div>

      <div className="inner">
        <label style={{ color: "black", fontSize: "12px" }} className="labFont">Rotation</label>
        <input className="range-bar"
          type="range"
          min="0"
          max="360"
          value={localRotation}
          onChange={handleRotationChange}
        />
              <button
                        value="rotation_control" className="resetbtn"
                        onClick={() => handleRotationChange({ target: { value: 0 } })}
                      >
                        Reset
                      </button>
      </div>

    </div>
  );

  const renderOutlineControls = () => (
    <div className="inner">
      <label style={{ color: "black", fontSize: "12px" }} className="labFont">Outline</label>
      <div className="colors-gallery  outline-main" style={{ display: 'flex' }}>
      <button
        onClick={toggleOutlineSettings}
        className="color-box none-btn-outline "
        style={{
          boxSizing:'border-box',

          marginBottom:'0px',
          backgroundColor: outlineColor === "none" ? "transparent" : outlineColor,
          border: outlineColor === "none" ? "0px solid black" : "none",
          color: outlineColor === "none" ? "" : "transparent", 
          width: outlineColor === "none" ? "" : '100% !important', // Text color when 'None' is applied
        }}
      >
        {outlineColor === "none" ? "None" : ""}
      </button>
      </div>
    </div>
  );

  const renderSizeControls = () => (
    <div className="inner">
      <label style={{ color: "black", fontSize: "12px" }} className="labFont">Auto Resize</label>
      <span style={{ color: "black", fontSize: "10px" }}className="labFont"><span className="inches">{clipwidth}</span>in</span>{" "}
      <span style={{ color: "black", fontSize: "10px" }}className="labFont">x</span>{" "}
      <span style={{ color: "black", fontSize: "10px" }}className="labFont"><span className="inches">{clipheight}</span>in</span>
    </div>
  );

  const renderColorControls = () => (
    <div className="inner">
      <ObjectColorComponent
        canvas={getCanvasByPartName(activeTab)}
        selectedObject={selectedObject}
        selectedObjectColors={selectedObjectColors}
        setSelectedObjectColors={setSelectedObjectColors}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
        showColorPalette={showColorPalette}
        setShowColorPalette={setShowColorPalette}
        inkColors={inkColors}
        setInkColors={setInkColors}
        onColorChange={onColorChange}
        handleFillChange={handleFillChange}
        addToHistory={addToHistory}
        calculatePrice={() => calculatePrice(getCanvasByPartName(activeTab))}
      />
    </div>
  );

  const tabs = [
    { 
      label: (
        <div className="tab-label">
          <img src={transformclip} alt="Transform Icon" className="tab-icon" />
          <br />
          Transform
        </div>
      ), 
      content: renderTransformControls 
    },
    { 
      label: (
        <div className="tab-label">
          <img src={outlinefontmob} alt="Outline Icon" className="tab-icon" />
          <br />
          Outline
        </div>
      ), 
      content: renderOutlineControls 
    },
    { 
      label: (
        <div className="tab-label">
          <img src={sizeclip} alt="Size Icon" className="tab-icon" />
          <br />
          Size
        </div>
      ), 
      content: renderSizeControls 
    },
    { 
      label: (
        <div className="tab-label">
          <img src={colormobicon} alt="Color Icon" className="tab-icon" />
          <br />
          Color
        </div>
      ), 
      content: renderColorControls 
    }
  ];
  

  const handleTabChange = (index) => {
    setActiveTabIndex(index);
    if (index === 1) {
      // Ensure OutlineSettings is displayed when selecting the "Outline" tab
      setIsOutlineSettingsOpen(true);
    } else {
      setIsOutlineSettingsOpen(false);
    }
  };

  return (

    <>
        





    <div className="main-cliparts">

      {isMobileScreen ? (
        <>
          {/* Render Tabs */}
          <div className="mobile-tabs-container">
          {activeTabIndex !== null ? (
              <div className="tab-content">        <div className="addcliparttwo"
            
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "3px",
                backgroundColor: "#f6f6f6",
                
              }}
            >
              <h5          // <-- Call the close function on click

                style={{
                  margin: "0 auto",
                  color: "#211e1eaa",
                  fontWeight: "400",
                  textAlign: "center",
                  flexGrow: 1,
                }}
              >
                Edit Clipart
              </h5>
              <button
                onClick={closePropertiesSidebar}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "auto",
                  color: "#aaaa",
                  fontWeight: "300",
                }}
                aria-label="Close" 
              >
                                        <img src={close} alt="Close" className="closeicon"/>
  
              </button>
            </div>
          
           
            <div className="main-clipart">

                {isOutlineSettingsOpen ? (
                  <OutlineSettings
                    outlineWidth={outlineWidth}
                    onOutlineWidthChange={handleOutlineWidthChange}
                    onOutlineColorChange={handleOutlineColorChange}
                    onClose={toggleOutlineSettings}
                    outlineColor={outlineColor}
                  />
                ) : (
                  tabs[activeTabIndex].content()
                )}
                      </div>
              </div>
            ) : (
              <div className="tab-placeholder"></div>
            )}

            <ul className="tab-list">
              {tabs.map((tab, index) => (
                <li
                  key={index}
                  className={activeTabIndex === index ? "active" : ""}
                  onClick={() => handleTabChange(index)}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
     
            {/* Render Active Tab Content */}
 
          </div>
        </>
      ) : (
        // Render regular layout for larger screens
        <>
          {isOutlineSettingsOpen ? (
            <OutlineSettings
              outlineWidth={outlineWidth}
              onOutlineWidthChange={handleOutlineWidthChange}
              onOutlineColorChange={handleOutlineColorChange}
              onClose={toggleOutlineSettings}
              outlineColor={outlineColor}
            />
          ) : showColorPalette  ? (
            <div>
              <div style={{ backgroundColor: "rgb(246 246 246)", padding: "4px" }}>
                <h5
                  style={{
                    textAlign: "center",
                    margin: "0 auto",
                    padding: "4px",
                    margin: "0px",
                    width: "80%",
                    display: "inline",
                  }}
                >
                Edit Color
                </h5>
                <button
                   onClick={closePropertiesSidebar}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginLeft: "auto",
                    color: "#aaaa",
                    fontWeight: "300",
                    float: "right",
                    width: "10%",
                  }}
                  aria-label="Close"
                >
                X
                </button>
              </div>
              <div>
                <p style={{ paddingTop: "10px", paddingLeft: "10px" }}>Select Color</p>
              </div>
              <ObjectColorComponent
                canvas={getCanvasByPartName(activeTab)}
                selectedObject={selectedObject}
                selectedObjectColors={selectedObjectColors}
                setSelectedObjectColors={setSelectedObjectColors}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                showColorPalette={showColorPalette}
                setShowColorPalette={setShowColorPalette}
                inkColors={inkColors}
                setInkColors={setInkColors}
                onColorChange={onColorChange}
                handleFillChange={handleFillChange}
                addToHistory={addToHistory}
                calculatePrice={() => calculatePrice(getCanvasByPartName(activeTab))}
              />
             
            </div>
          ) :(
            <>        <div className="addcliparttwo"
            
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "3px",
              backgroundColor: "#f6f6f6",
              
            }}
          >
            <h5
              style={{
                margin: "0 auto",
                color: "#211e1eaa",
                fontWeight: "400",
                textAlign: "center",
                flexGrow: 1,
              }}
            >
              Edit Clipart 
            </h5>
            <button
                             onClick={closePropertiesSidebar}

              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: "18px",
                fontWeight: "bold",
                marginLeft: "auto",
                color: "#aaaa",
                fontWeight: "300",
              }}
              aria-label="Close"
            >
                                      <img src={close} alt="Close" className="closeicon"/>

            </button>
          </div>
          <div className="main-clipart">
       
              {renderTransformControls()}
              {renderOutlineControls()}
              {renderSizeControls()}
              {renderColorControls()}
              </div>
            </>
          )}
        </>
      )}
    </div>
    </>
  );
}
