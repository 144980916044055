import React, { createContext, useState, useEffect } from 'react';
import { colorHexMap } from '../colorlibrarygarments'; // Import colorHexMap from the file

export const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
  const [selectedColor, setSelectedColor] = useState('#B2BEB5'); // Default color code (Ash Grey)
  const [selectedColorName, setSelectedColorName] = useState('Ash Grey'); // Default color name
  const [selectedSizes, setSelectedSizes] = useState([]); // Track the sizes for the selected color

  // Fetch color data based on URL params or other sources
  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const colorId = params.get('colorId'); // Extract colorId from URL

    if (colorId) {
      // Check if colorId exists in the colorHexMap
      const matchedColor = Object.keys(colorHexMap).find(
        (color) => colorHexMap[color]?.id === colorId
      );

      if (matchedColor) {
        // Set the hex and color name for the selected color
        setSelectedColor(colorHexMap[matchedColor].hex);
        setSelectedColorName(matchedColor);
      }
    }
  }, []);

  const value = {
    selectedColor,
    selectedColorName,
    setSelectedColor,
    setSelectedColorName,
    setSelectedSizes,
    selectedSizes,
  };

  return (
    <ColorContext.Provider value={value}>
      {children}
    </ColorContext.Provider>
  );
};
