import { Col, Row } from "antd";
import React from "react";
import SimpleButton from "../../components/simpleButton/SimpleButton";
import Title from "../../components/typography/title/Title";
import free from "../../assets/images/free.png";
import rush from "../../assets/images/rush.png";
import email from "../../assets/images/email.png";

// Function to calculate future date
const calculateFutureDate = (daysToAdd) => {
  const date = new Date();
  date.setDate(date.getDate() + daysToAdd);
  return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
};

// Calculated delivery dates
const standardDeliveryDate = calculateFutureDate(14); // Standard delivery in 14 days
const rushDate = calculateFutureDate(7); // Rush delivery in 7 days
const superRushDate = calculateFutureDate(4); // Super rush delivery in 4 days

const NewArrival = ({ data }) => {
  return (
    <div className="newArrival shippling-arrival"style={{  backgroundColor: '#f9f9f9'}}>
      <div className="shipping" style={{ padding: '20px', paddingTop: '13px',  borderRadius: '0px', marginBottom: '0px', marginTop: '0px',  borderBottom: '1px solid #1cc0d9' }}>
        <Row gutter={[25, 20]}>
          <Col md={8} sm={24}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left' }}>
              <img 
                src={free} 
                alt="Free Shipping" 
                style={{ width: '70px', height: 'auto', marginRight: '20px' }} 
              />
              <div>
                <Title size={"16"} weight={700}>
                  FREE SHIPPING
                </Title>
                <p>
                  Estimated Ready by <strong style={{ color: '#1cc0d9' }}>{standardDeliveryDate}</strong>
                </p>
                <p>(14 Business Days)</p>
              </div>
            </div>
          </Col>
          
          <Col md={8} sm={0} xs={0} className="need-sooner">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left' }}>
              <img 
                src={rush}  
                alt="Rush Delivery" 
                style={{ width: '70px', height: 'auto', marginRight: '20px' }} 
              />
              <div>
                <Title size={"16"} weight={700}>
                  RUSH DELIVERY
                </Title>
                <p>
                  Estimated Ready by <strong style={{ color: '#1cc0d9' }}>{rushDate}</strong>
                </p>
                <p>(7 Business Days)</p>
              </div>
            </div>
          </Col>

          <Col md={8} sm={0} xs={0} className="need-sooner">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left' }}>
              <img 
                src={email}  
                alt="Super Rush Delivery" 
                style={{ width: '70px', height: 'auto', marginRight: '20px' }} 
              />
              <div>
                <Title size={"16"} weight={700}>
                  NEED SOONER?
                </Title>
                <p>
                  Need your order sooner? Contact us by <strong style={{ color: '#1cc0d9' }}></strong>
                </p>
{/*                 
                <span>{superRushDate}</span> */}
                <p><a href="mailto:orders@inkox.com" style={{ color: '#1cc0d9', fontWeight: 'bold' }}>orders@inkox.com</a></p>
              </div>
            </div>
          </Col>
        </Row>
      </div>

    
    </div>
  );
};

export default NewArrival;
