import { useEffect, useState } from 'react';
import { fabric } from 'fabric';
import deleteIconSrc from '../assets/deleteicon.png';
import duplicateIconSrc from '../assets/duplicateicon.png';
import resizeIconSrc from '../assets/resizeicon.png';

// Icon Loader
const useIconLoader = () => {
  const [icons, setIcons] = useState({ deleteIcon: null, duplicateIcon: null, resizeIcon: null });

  useEffect(() => {
    const loadIcon = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
      });
    };

    const preloadIcons = async () => {
      const deleteIcon = await loadIcon(deleteIconSrc);
      const duplicateIcon = await loadIcon(duplicateIconSrc);
      const resizeIcon = await loadIcon(resizeIconSrc);
      setIcons({ deleteIcon, duplicateIcon, resizeIcon });
    };

    preloadIcons();
  }, []);

  return icons;
};

// Utility Functions
export const handleIconMouseDown = (iconType, pressedIconRef) => () => {
  pressedIconRef.current = iconType;
};

export const handleIconMouseUp = (iconType, action, hoverRectRef, pressedIconRef) => (eventData, transform) => {
  if (pressedIconRef.current === iconType) {
    action(eventData, transform, hoverRectRef);
  }
  pressedIconRef.current = null;
};

export const deleteObject = (eventData, transform, hoverRectRef) => {
  const target = transform.target;
  const canvas = target.canvas;
  const partName = target.partName; // Ensure each object has a part name

  if (target.type === 'activeSelection') {
    target.getObjects().forEach((obj) => {
      removeFromLocalStorage(partName, obj.id);
      canvas.remove(obj);
    });
    canvas.discardActiveObject();
  } else {
    removeFromLocalStorage(partName, target.id);
    canvas.remove(target);
  }

  if (hoverRectRef.current) {
    hoverRectRef.current.set('visible', false);
  }

  canvas.requestRenderAll();
  canvas.fire('object:modified'); // Trigger any additional actions like price recalculation
};

// Utility function to remove an object from local storage by ID
const removeFromLocalStorage = (partName, objectId) => {
  const existingDataJson = localStorage.getItem(partName);
  if (existingDataJson) {
    const existingCanvasData = JSON.parse(existingDataJson);
    const updatedCanvasData = existingCanvasData.filter(obj => obj.id !== objectId);
    localStorage.setItem(partName, JSON.stringify(updatedCanvasData));
  }
};

export const duplicateObject = (eventData, transform) => {
  const target = transform.target;
  const canvas = target.canvas;

  if (target.type === 'activeSelection') {
    const objectsToDuplicate = target.getObjects();
    const clonedObjects = [];

    objectsToDuplicate.forEach((obj) => {
      obj.clone((cloned) => {
        const newLeft = obj.left + 20;
        const newTop = obj.top + 20;
        cloned.set({
          left: newLeft,
          top: newTop,
          designElement: true, // Ensure duplicates are marked as design elements
        });
        canvas.add(cloned);
        clonedObjects.push(cloned);
      });
    });

    if (clonedObjects.length > 1) {
      const newActiveSelection = new fabric.ActiveSelection(clonedObjects, {
        canvas: canvas,
      });
      newActiveSelection.setCoords();
      canvas.setActiveObject(newActiveSelection);
    }
  } else {
    target.clone((clone) => {
      const newLeft = target.left + 20;
      const newTop = target.top + 20;
      clone.set({
        left: newLeft,
        top: newTop,
        designElement: true, // Ensure duplicates are marked as design elements
      });
      canvas.add(clone);
      canvas.setActiveObject(clone);
    });
  }
  
  canvas.discardActiveObject();
  canvas.requestRenderAll();
  canvas.fire('object:modified'); // Trigger price recalculation
};


export const renderIcon = (icon, size, opacity) => {
  return function render(ctx, left, top, styleOverride, fabricObject) {
    ctx.save();
    ctx.globalAlpha = opacity;
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(icon, -size / 2, -size / 2, size, size);
    ctx.restore();
  };
};

export default useIconLoader;
