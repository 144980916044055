import React, { useState, useEffect, useContext } from 'react';
import { ColorContext } from '../context/ColorContext';
import { ColorProvider } from '../context/ColorContext';
import ExportButton from './ExportButton';
import Cookies from 'js-cookie';
import zipcodes from 'zipcodes'; // Import the zipcodes library
import imagesshirt from "../assets/map.png";
import crossicon from "../assets/crossicon.png";
import backiconbtn from "../assets/backiconbtn.png";
import backiconbtnone from "../assets/truk (1).png";
import backiconbtntwo from "../assets/truk (2).png";
import backiconbtnthree from "../assets/truk (3).png";
import backiconbtnfour from "../assets/truk (4).png";
import freedelivery from "../assets/freedelivery.png";
import needsooner from "../assets/needsooner.png";
import bringtofrontfive from '../assets/truk (5).png';
import { colorHexMap } from '../colorlibrarygarments';
import { Row } from 'antd';

// Utility function to calculate future dates
const calculateFutureDate = (daysToAdd) => {
  const date = new Date();
  date.setDate(date.getDate() + daysToAdd);
  return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
};
const PriceSidebar = ({
  show,
  handleClose,
  quantity,
  deselectAllObjects,
  handleQuantityChange,
  priceDisplay,
  setPriceDisplay,
  thumbnails,
  selectedColor,
  productData,
  name,
  setZipCode,
  switchBar,

  setEmail,
  setDesignName,
  zipCode,
  designName,
  email,
  productName,
  canvasOne,
  canvasTwo,
  canvasThree,
  canvasFour,
  extractColorsFromObject,
  selectedFont,
  Canvasid,
  basePrice,
  totaledQuantity,
  canvasPrices,
  designId,
  uniqueKey,
  colors,
  selcolor,
  retailprice,
  numcolor,
  sale_price
}) => {


  const [allPartsBlank, setAllPartsBlank] = useState(false);
  const [partColors, setPartColors] = useState({
    Front: new Set(),
    Back: new Set(),
    Right: new Set(),
    Left: new Set(),
  });
  const [imagePreviews, setImagePreviews] = useState([]);
  const [loading, setLoading] = useState(true);

  const generateImagePreviews = () => {
    const previews = [];
  
    const captureCanvasImage = (canvas, part) => {
      if (canvas && canvas.getObjects().length > 0) {
        canvas.renderAll(); // Ensure the canvas is rendered with the latest changes
        const preview = canvas.toDataURL('image/png');
        previews.push({ part, preview });
      } else {
        console.warn(`${part} canvas is empty or not ready`);
        previews.push({ part, preview: null });
      }
    };
  
    // Only capture the "Front" part
    captureCanvasImage(canvasOne, 'Front');
  
    setImagePreviews(previews.filter((img) => img.preview !== null)); // Filter out null previews
    setLoading(false); // Stop the loader
  };
  

  useEffect(() => {
    const timer = setTimeout(() => {
      generateImagePreviews();
    }, 1000); // Delay by 1 minute (60000 ms)

    return () => clearTimeout(timer); // Cleanup the timeout
  }, [canvasOne, canvasTwo, canvasThree, canvasFour]);

  useEffect(() => {
    if (imagePreviews.length > 0) {
      localStorage.setItem('imagePreviews', JSON.stringify(imagePreviews));
    }
  }, [imagePreviews]);

  
  const filterColors = (colors) => {
    const excludedColors = [
      'transparent',
      'rgb(0,0,0)',
      'rgba(0,0,0,0)',
      'rgba(150, 150, 150, 1)',
      'none',
    ];
    return colors.filter((color) => !excludedColors.includes(color));
  };

  const previewColorsInConsole = () => {
    const colors = {
      Front: new Set(),
      Back: new Set(),
      Right: new Set(),
      Left: new Set(),
    };

    [canvasOne, canvasTwo, canvasThree, canvasFour].forEach((canvas, index) => {
      const part =
        index === 0
          ? "Front"
          : index === 1
          ? "Back"
          : index === 2
          ? "Right"
          : index === 3
          ? "Left"
          : "";

      if (canvas) {
        canvas.getObjects().forEach((obj) => {
          if (obj.opacity !== 0) {
            const extractedColors = extractColorsFromObject(obj);
            const validColors = filterColors(extractedColors);
            validColors.forEach((color) => colors[part].add(color));
          }
        });
      }
    });

    setPartColors(colors);

    // Check if all parts are blank
    const allBlank = Object.values(colors).every((colorSet) => colorSet.size === 0);
    setAllPartsBlank(allBlank);

    Object.entries(colors).forEach(([part, colors]) => {
      console.log(`${part} Colorspricesidebar:`, Array.from(colors));
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const areCanvasesReady =
        canvasOne &&
        canvasTwo &&
        canvasThree &&
        canvasFour &&
        (canvasOne.getObjects().length > 0 ||
          canvasTwo.getObjects().length > 0 ||
          canvasThree.getObjects().length > 0 ||
          canvasFour.getObjects().length > 0);

      if (areCanvasesReady) {
        previewColorsInConsole();
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [canvasOne, canvasTwo, canvasThree, canvasFour]);

  const [sizes, setSizes] = useState([]);
  const [matchedSize, setMatchedSize] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [emailSaved, setEmailSaved] = useState(false);
  const [isValidZipCode, setIsValidZipCode] = useState(true);
  const [addressPreview, setAddressPreview] = useState(""); // New state for address preview
  const { selectedColorName, selectedSizes } = useContext(ColorContext);
    const queryString = window.location.search; // Gets the query string, e.g., "?id=ts-rn-hs-02"
  const params = new URLSearchParams(queryString); 
  const colorId = params.get('colorId');
  const [pricey, setPricey] = useState('Unavailable');
  const [unitPrice, setUnitPrice] = useState(0); // Define unit price state here
  const standardDeliveryDate = calculateFutureDate(14); // Standard 14 days for example
  const superRushDate = calculateFutureDate(4); // Super Rush is 4 days from today
  const rushDate = calculateFutureDate(7); // Rush is 7 days from today


  console.log("priceSidebar:",colorId);
  console.log("retailprice:",retailprice);
  console.log("numcolor:",numcolor);
  

  // const uniqueKey = `inputs_${designId || name}`;

  // const { selectedColorName } = useContext(ColorContext);



  // Initialize size input values
  const initialValues =
    matchedSize?.size.split(', ').reduce((acc, size) => {
      acc[size] = 0;
      return acc;
    }, {}) || {};

  const [inputValues, setInputValues] = useState(initialValues);

  useEffect(() => {
    // Find the color in colorHexMap using the colorId
    const foundColor = Object.values(colorHexMap).find(color => color.id === colorId);

    if (foundColor) {
    
console.log('foundColor',foundColor);
      // Ensure colors and hexCode are available before trying to find the sizes
      if (colors && colors.length > 0) {
        // Find the matching color in colors prop using the hexCode
        const matchedColor = colors.find(color => color.hexCode === foundColor.hex);

        if (matchedColor && matchedColor.sizes) {
          setSizes(matchedColor.sizes || []); // Set sizes if available
        } else {
          setSizes([]); // No sizes available
        }
      } else {
        setSizes([]); // No colors data
      }
    } else {

      setSizes([]); // Reset sizes if no matching color found
    }
  }, [colorId, colors]);

  
  useEffect(() => {
    // Load saved inputs from localStorage
    const savedInputs = JSON.parse(localStorage.getItem(uniqueKey)) || initialValues;
    setInputValues(savedInputs);

    // Update total quantity
    const totalQuantity = Object.values(savedInputs).reduce((sum, value) => sum + value, 0);
    handleQuantityChange({ target: { value: totalQuantity } });

    // Load saved email from cookies
    const storedEmail = localStorage.getItem('Email');
    const storeEmail = Cookies.get('Email_guest');
    if (storedEmail || storeEmail) {
      setEmailSaved(true);
      setEmail(storedEmail || storeEmail);
    }
  }, [ uniqueKey]);

  useEffect(() => {
    // Calculate the total price whenever inputValues or canvasPrices changes
    let totalPrice = 0;
    let sizePrice = 0;
    let totalQuantity = 0;
    let retailPrices = {};
    
    // Check if canvasPrices has valid data before attempting to calculate
    if (canvasPrices && canvasPrices.length > 0) {
      const totalCanvasPrice = canvasPrices.reduce((acc, price) => acc + price, 0);
      retailPrices = JSON.parse(retailprice);
      
      for (let size in inputValues) {
        console.log("Sizes", size);
        const sizeQuantity = inputValues[size] || 0;
        totalQuantity += sizeQuantity;  // Sum up total quantity
        sizePrice = retailPrices[size] || 0;
        const priceForSize1 = parseFloat(sizePrice);
        const priceForSize2 = parseFloat(totalCanvasPrice);
        const priceForSize = priceForSize1 + priceForSize2;
        const totalForSize = priceForSize * sizeQuantity;
        console.log("priceForSize:", priceForSize);
        totalPrice += totalForSize;
        console.log("sizePricecanvas:", sizePrice);
        console.log("totalCanvasPricess:", totalCanvasPrice);
  
        console.log("sizeQuantity:", sizeQuantity);
        console.log("totalPrice:", totalPrice);
      }
  
      // Calculate unit price by dividing total price by total quantity, if totalQuantity > 0
      const unitPrice = totalQuantity > 0 ? totalPrice / totalQuantity : 0;
      console.log("unitPrice:", unitPrice);
      
      setPricey(totalPrice);
      setUnitPrice(unitPrice); // Assuming setUnitPrice is defined for setting unit price
    }
  }, [inputValues, canvasPrices, retailprice]); // Adding dependencies
  
  // const filterColors = (colors) => {
  //   const excludedColors = [
  //     'transparent',
  //     'rgb(0,0,0)',
  //     'rgba(0,0,0,0)',
  //     'rgba(150,150,150,1)',
  //     'none',
  //   ];
  
  //   return colors.filter(color => !excludedColors.includes(color));
  // };
  
  // const previewColorsInConsole = () => {
  //   const partColors = {
  //     Front: new Set(),
  //     Back: new Set(),
  //     Right: new Set(),
  //     Left: new Set(),
  //   };
  
  //   [canvasOne, canvasTwo, canvasThree, canvasFour].forEach((canvas, index) => {
  //     const part =
  //       index === 0
  //         ? "Front"
  //         : index === 1
  //         ? "Back"
  //         : index === 2
  //         ? "Right"
  //         : index === 3
  //         ? "Left"
  //         : "";
  
  //     if (canvas) {
  //       canvas.getObjects().forEach((obj) => {
  //         if (obj.opacity !== 0) {
  //           // Ensure the object is visible
  //           const extractedColors = extractColorsFromObject(obj);
  //           const validColors = filterColors(extractedColors); // Exclude unwanted colors
  //           validColors.forEach((color) => partColors[part].add(color));
  //         }
  //       });
  //     }
  //   });
  
  //   // Log the filtered colors to the console
  //   Object.entries(partColors).forEach(([part, colors]) => {
  //     console.log(`${part} Colorspricesidebar:`, Array.from(colors));
  //   });
  // };
  
  // // Trigger the function when canvases update
  // useEffect(() => {
  //   previewColorsInConsole();
  // }, [canvasOne, canvasTwo, canvasThree, canvasFour]);
  
  
  

  const handleZipCodeChange = (e) => {
    let zipCodeInput = e.target.value.replace(/\s/g, ""); // Remove spaces
  
    // Allow only alphanumeric characters
    zipCodeInput = zipCodeInput.replace(/[^A-Za-z0-9]/g, "");
  
    // Restrict to max of 6 characters without the space
    if (zipCodeInput.length > 6) {
      zipCodeInput = zipCodeInput.slice(0, 6);
    }
  
    // Insert space after the third character
    if (zipCodeInput.length > 3) {
      zipCodeInput = zipCodeInput.slice(0, 3) + " " + zipCodeInput.slice(3);
    }
  
    // Update the input value
    setZipCode(zipCodeInput);
  
    // Validate the format of the postal code
    const canadianZipCodePattern = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
  
    if (canadianZipCodePattern.test(zipCodeInput)) {
      setIsValidZipCode(true); // Valid postal code
  
      // Lookup the zip code and get location data
      const locationData = zipcodes.lookup(zipCodeInput.replace(" ", "").toUpperCase());
      if (locationData) {
        // Use regex to remove any text inside parentheses from the city name
        const cleanedCity = locationData.city.replace(/\s*\(.*?\)\s*/g, '');
        const address = `${cleanedCity}, ${locationData.state}`; // Format as "City, State"
        setAddressPreview(address); // Set the address for preview
      } else {
        setAddressPreview("Address not found");
      }
    } else {
      setIsValidZipCode(false); // Invalid postal code
      setAddressPreview(""); // Clear the address preview if invalid
    }
  };
  
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;  // 'name' is the size (S, M, L, etc.)
    const intValue = parseInt(value, 10) || 0;
    console.log("abcone",canvasPrices);
    setInputValues((prevValues) => {
      
      // Update the input values with the new quantity for the selected size
      const updatedValues = { ...prevValues, [name]: intValue };
  
      // Get the total quantity by summing the values of all sizes
      const newQuantity = Object.values(updatedValues).reduce((sum, val) => sum + val, 0);
      
      // Parse retail price from JSON string before using it
      let retailPrices = {};
      try {
        retailPrices = JSON.parse(retailprice);  // Convert string to object
      } catch (error) {
        console.error("Error parsing retail prices:", error);
        return prevValues;  // Exit early if parsing fails
      }

      // Calculate total price based on the retail price of the changed size
      let totalPrice = 0;
  
      // Iterate over the updated quantities and calculate total price
      for (let size in updatedValues) {
        console.log("abc",canvasPrices);
        const sizeQuantity = updatedValues[size];  // Get the quantity for this size
        const totalCanvasPrice = canvasPrices.reduce((acc, price) => acc + price, 0);
        // Ensure the size matches the retail price key (size could be S, M, etc.)
        const sizePrice = retailPrices[size] || 0;  // Ensure it matches correctly in retailPrices
        
        // If retailPrices doesn't have the size, it will fallback to 0 or handle error
        if (sizePrice === 0) {
          console.warn(`Size ${size} not found in retail prices`);
        }
     
        // Add to total price (quantity * retail price for that size)
        totalPrice += (totalCanvasPrice + sizePrice) * sizeQuantity;
      }
  
      console.log(`Total Price after change for ${name}:`, totalPrice);
  
      // Update the parent component with the total quantity
      handleQuantityChange({ target: { value: newQuantity } });
  
      // Update the price display
      setPricey(totalPrice);
  
      // Save the updated input values in local storage
      localStorage.setItem(uniqueKey, JSON.stringify(updatedValues));
  
      return updatedValues;
    });
};

  
  

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailBlur = () => {
    // Save email to cookie with a 72-hour expiration
    Cookies.set('Email_guest', email, { expires: 3 }); // 3 days (72 hours)
  };

  

  return (
    <ColorProvider>
      <div className="price-sidebar">
        <div className="closebtnaaa">
          <button
            className="back-btn closebtn-pricesidebar"
            onClick={handlePrevStep}
            disabled={currentStep === 1}
            style={{
              opacity: currentStep === 1 ? 0.5 : 1,
              cursor: currentStep === 1 ? 'not-allowed' : 'pointer',
            }}
          >
            <img src={backiconbtn} alt="Back" />
          </button>

          <button className="close-btn" onClick={handleClose}>
            <img src={crossicon} alt="Close" />
          </button>
        </div>

        {currentStep === 1 && (
          <div className="price-sidebar-content">
       
            <div className="main-img">
              <div style={{ width: "30%" }} className="first-img">
              <div className="image-preview">
  {loading ? (
    <div className="loader">
      <p>Loading front preview, please wait...</p>
    </div>
  ) : (
    <div className="image-preview">
      {imagePreviews.map((image, index) => (
        <div key={index}>
          {image.preview ? (
            <img
              src={image.preview}
              alt={`Preview of ${image.part}`}
              className="thumbnail-image"
              style={{ width: 'auto', height: '65px', margin: '0px' }}
            />
          ) : (
            <p>No preview available for {image.part}</p>
          )}
        </div>
      ))}
    </div>
  )}
</div>

              </div>
              <div style={{ width: "70%" }}className="second-content">
                <h3>{name}</h3>
                <p>
                  Color: {selectedColorName} <a href="#">Add another color</a>
                </p>
                <p>
                  <a href="#">View Sizing Guide</a>
                </p>
              </div>
              
            </div>
            <div className="size-inputs">
  {/* If selectedSizes has values, show selectedSizes, otherwise fallback to sizes */}
  {selectedSizes && selectedSizes.length > 0 ? (
    selectedSizes.map((size) => (
      <div key={size} className="label-input-pair">
        <label>{size}</label>
        <input
          type="number"
          name={size}
          value={inputValues[size] || ''}
          onChange={handleInputChange}
          min="0"
          placeholder="0"
        />
      </div>
    ))
  ) : sizes && sizes.length > 0 ? (
    sizes.map((size) => (
      <div key={size} className="label-input-pair">
        <label>{size}</label>
        <input
          type="number"
          name={size}
          value={inputValues[size] || ''}
          onChange={handleInputChange}
          min="0"
          placeholder="0"
        />
      </div>
    ))
  ) : (
    <p>No sizes available for this color</p>
  )}
</div>



            <div className="total-Quantity">
              <label>
                Total Quantity:
                <input type="number" value={quantity} readOnly />
              </label>
              <button className="continue-btn" onClick={handleNextStep}>
                Continue
              </button>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className="price-sidebar-content shipping-step">
            <h6 className="shipping-title">Enter your zip code to place your order!</h6>

            <div className="input-group-unique">
              <input
                type="text"
                id="zipCode"
                className={`styled-input-unique ${isValidZipCode ? "" : "invalid-zip"}`}
                value={zipCode}
                onChange={handleZipCodeChange}
                placeholder="A1A 1A1"
                maxLength={7}
              />
              <a href="#" className="outside-link">
                Outside the U.S. or Canada?
              </a>
            </div>
            {!isValidZipCode && zipCode && (
              <p className="error-message">
                Invalid Zip Code: Must follow the Canadian format A1A 1A1
              </p>
            )}
            {isValidZipCode && addressPreview && (
              <p className="address-preview">Address: {addressPreview}</p>
            )}

            <button
              className="continue-btsss continue-btnzip"
              onClick={handleNextStep}
              disabled={!isValidZipCode}
            >
              Continue to Pricing
            </button>
            <img src={imagesshirt} className="mapimage" alt="Map" />

            <p className="weareshipping">
              We're shipping across Canada and gearing up to bring our custom products to the USA
              soon—stay tuned for exciting updates!
            </p>
          </div>
        )}

        {currentStep === 3 && (
          <>
          <div className='price-sidebar-content price-sidebar-content-for-price '>

            <div className='inner-pricesidebarcontent'>
            <div className='main-pricebar'>
            <div className='text-pricebartagline'><p>1 color</p>
            <p>1 print area</p>
            <p>{quantity} total item </p>
            </div>
            {allPartsBlank ? (
            <p className="price-sidebar-pricingss">
              <p>
                <strong>You cannot purchase blank products in our studio.</strong>
              </p>
              <p>
                <button className='click-here' onClick={handleClose} >Click Here</button> to add a design to your product.
              </p>
            </p>
          ) : (
            <div className="price-details">
            {/* <h4>Product Details:</h4>
            <p>Product: {name}</p>
            <p>Total Price: ${pricey}</p>
            <p>unit price ${unitPrice}</p> */}
            <p className='price-sidebar-pricing'><span className='unit'>${unitPrice.toFixed(2)}</span><b> each</b><br></br>
            <span className='total-pricey'>${pricey.toFixed(2)}</span> total with Discount</p>
          </div>
 
          )}
   
            </div>
            <div className='table-prcing'>
 
              
                      <div className="standard-delivery">
                   
                       
          <div className="free-label"><img src={freedelivery}/></div>
          <span>Have it ready by<strong>{standardDeliveryDate}</strong></span>
          <p>   <img src={backiconbtnfour}/>shipping to                {addressPreview}</p>
     
       
        </div>
   

            <div className="faster-options">
          <h5><img src={needsooner}/></h5>
          <div className='faster-option-main'>
          <div className="super-rush">
            <span><img src={backiconbtntwo}/> Have it ready by <strong>{superRushDate}</strong></span>
          </div>
          <div className="rush">
            <span><img src={backiconbtnthree}/> Have it ready by <strong>{rushDate}</strong></span>
          </div>
          </div>
        </div>
      


        </div>
       <h5 className='your-order-name'>Your order Name</h5>
            <div className="main-img">
              
              <div style={{ width: "30%" }} className="first-img">
                <img style={{ width: "100%" }} src={thumbnails.front} alt="Product Thumbnail" />
              </div>
              <div style={{ width: "70%" }}className="second-content">
                <h3>{name}</h3>
                <p>
                  Color: {selectedColorName} 
                </p>
             
              </div>
            </div>
            </div>
            
            <div className="total-Quantity">
              <label>
                Total Quantity:
                <input type="number" value={quantity} readOnly />
              </label>
              <button className="continue-btn" onClick={handleNextStep}>
                Continue
              </button>
            </div>
            </div>
            
          </>
        )}

        {currentStep === 4 && (
          <div className="design-save-step unique-save-cart-container">
            <h2 className="save-cart-title">Save & Add to Cart</h2>
            <p className="save-cart-description">
              Save a final version of your design before you add to cart.
            </p>
            <div className="flex-buttonprice">
              <div className="input-group-extreme">
                <label htmlFor="designName" className="input-label-extreme">
                  Design Name
                </label>
                <input
                  type="text"
                  id="designName"
                  className="styled-input-extreme"
                  value={designName}
                  placeholder="Design Name"
                  maxLength="10"
                  onChange={(e) => setDesignName(e.target.value)}
                />
                <small className="input-helper-text-extreme">
                  10 characters max, no spaces or symbols.
                </small>
              </div>

              {!emailSaved ? (
                <div className="input-group-extreme">
                  <label htmlFor="email" className="input-label-extreme">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="styled-input-extreme"
                    value={email}
                    placeholder="Email"
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                  />
                </div>
              ) : (
                <p></p>
              )}
            </div>
            <div className="step-buttons">
              <ExportButton
                className="continue-btn"
                productName={productName}
                totalQuantity={totaledQuantity}
                canvasOne={canvasOne}
                canvasTwo={canvasTwo}
                canvasThree={canvasThree}
                canvasFour={canvasFour}
                extractColorsFromObject={extractColorsFromObject}
                selectedFont={selectedFont}
                Canvasid={Canvasid}
                totalPrice={pricey}
                basePrice={basePrice}
                zipCode={zipCode}
                designName={designName}
                email={email}
                uniqueKey={uniqueKey}
                selcolor={selcolor}
                standardDeliveryDate={standardDeliveryDate}
                superRushDate={superRushDate}
                rushDate={rushDate}
                orderType={switchBar}
                sale_price={sale_price}
               

              />
            </div>
          </div>
        )}
      </div>
    </ColorProvider>
  );
};

export default PriceSidebar;