import {
  createSlice,
  current,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { asynchronouslyGetFromLocal } from "../../utils/helperFunctions";

import { getCategories } from "./thunk";

const thunks = [getCategories];

const initialState = {
  status: "idle",
  Categories: {},
};

export const slice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    // setEmail: (state, email) => {
    //   state.userEmail = email;
    // },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(postLogin.fulfilled, (state, action) => {
      //   state.status = "idle";
      //   state.Login = action.payload;
      //   console.log(state);
      // })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.Categories = action.payload;
      })
      .addMatcher(isPending(...thunks), (state) => {})
      .addMatcher(isFulfilled(getCategories), (state) => {})
      .addMatcher(isRejected(...thunks), (state, action) => {});
  },
});

// export action from slice
// export const { setEmail } = slice.actions;

export const getProductCategories = (state) => state.categories.Categories;
// export const selectUser = (state) => state.login.userDetail;
// export const selectEmail = (state) => state.login.userEmail;

export default slice.reducer;
