import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric'; // Ensure fabric is imported correctly
import tshirtDesign from '../json/tshirtDesign.json';

const WorkingAreaComponent = ({ canvas, image, workingArea }) => {
  const workingAreaRef = useRef(null);
  const [leftPercent, setLeftPercent] = useState(null);
  const [topPercent, setTopPercent] = useState(null);
  const [widthPercent, setWidthPercent] = useState(null);
  const [heightPercent, setHeightPercent] = useState(null);

  useEffect(() => {
    const workingAreaData = workingArea;
    console.log("workingAreaData :", workingAreaData);
    if (workingAreaData) {
      setLeftPercent(parseInt(workingAreaData.leftPercent));
      setTopPercent(parseInt(workingAreaData.topPercent));
      setWidthPercent(parseInt(workingAreaData.widthPercent));
      setHeightPercent(parseInt(workingAreaData.heightPercent));
    }
  }, []);

  console.log("Dimensions :",leftPercent,topPercent,widthPercent,heightPercent);

  useEffect(() => {
    if (!canvas || !image || leftPercent === null || topPercent === null || widthPercent === null || heightPercent === null) return;

    const calculateWorkingArea = () => {
      const workingAreaWidth = image.width * image.scaleX * (widthPercent / 100);
      const workingAreaHeight = image.height * image.scaleY * (heightPercent / 100);

      // Calculate left and top position in pixels based on percentage
      const left = image.left - (image.width * image.scaleX) / 2 + (image.width * image.scaleX) * (leftPercent / 100);
      const top = image.top - (image.height * image.scaleY) / 2 + (image.height * image.scaleY) * (topPercent / 100);

      return { left, top, width: workingAreaWidth, height: workingAreaHeight };
    };

    const createWorkingArea = () => {
      const { left, top, width, height } = calculateWorkingArea();

      const workingArea = new fabric.Rect({
        left,
        top,
        width,
        height,
        fill: 'transparent',
        stroke: 'transparent',
        strokeWidth: 0,
        selectable: false,
        evented: false,
        fromWorkingArea: true, // Custom property to identify this object
      });

      canvas.add(workingArea);
      workingAreaRef.current = workingArea;
      console.log('Working area created:', workingArea);
    };

    const updateWorkingArea = () => {
      if (!workingAreaRef.current) return;

      const { left, top, width, height } = calculateWorkingArea();

      workingAreaRef.current.set({
        left,
        top,
        width,
        height,
      });

      workingAreaRef.current.setCoords();
      canvas.renderAll();
      console.log('Working area updated:', workingAreaRef.current);
    };

    createWorkingArea();
    updateWorkingArea();

    // Add listeners for image changes
    image.on('modified', updateWorkingArea);
    image.on('scaling', updateWorkingArea);
    image.on('moving', updateWorkingArea);
    window.addEventListener('resize', updateWorkingArea);

    return () => {
      image.off('modified', updateWorkingArea);
      image.off('scaling', updateWorkingArea);
      image.off('moving', updateWorkingArea);
      window.removeEventListener('resize', updateWorkingArea);
      if (workingAreaRef.current) {
        canvas.remove(workingAreaRef.current);
      }
    };
  }, [canvas, image, leftPercent, topPercent, widthPercent, heightPercent]);

  return null;
};

export default WorkingAreaComponent;
