import React, {useState} from "react";
import "./passion.css"; // Importing the updated CSS
import Image from './assets/passion.jpg';
import Breadcrumb from "./Breadcrumb.js";
import Sidebar from "./Sidebar.js";
import "./App.css";
import LayoutWrapper from "../../layout/layoutWrapper/LayoutWrapper.jsx";
import { Link } from "react-router-dom";
function Passion() {
    const [openSections, setOpenSections] = useState(["about", "agreement", "policy", "solution"]);

    const toggleSection = (section) => {
      setOpenSections((prevOpenSections) => {
        if (prevOpenSections.includes(section)) {
          return prevOpenSections.filter((s) => s !== section);
        } else {
          return [...prevOpenSections, section];
        }
      });
    };
  
    const renderIcon = (isOpen) => (
      <svg
        className="mui-icon"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ExpandMoreIcon"
        style={{
          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
        }}
      >
        <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </svg>
    );
  
    return (
        <LayoutWrapper>
        <div className="main-div-other-pages">
             <div className="container">
             <Breadcrumb />
            <div className="inkox-container">
            <Sidebar
              openSections={openSections}
              toggleSection={toggleSection}
              renderIcon={renderIcon}
            />
                {/* Main Content */}
                <div className="passion-main-content">
                <h1 style={{ color: "#444444 !important", fontSize: "24px" }}>Inked with Passion at INKOX</h1>

                    <h2 className="inkox-content-h2"> Crafting Perfection with Every Print </h2>
                    <p className="inkox-content-para">
                    At INKOX, we’re fueled by a dual love affair with the art of printing and the joy of bringing your creative visions to life. After nearly two decades of refining our techniques, we’ve mastered the craft. We tailor our approach with multiple printing technologies, selecting the perfect one for each unique project to ensure flawless results every time.
                    </p>

                    <div className="passion-image-section">
                        <img
                            src={Image}
                            alt="Customer Support"
                            className="passion-support-image"
                        />
                    </div>
                    <h2 className="inkox-content-h2"> Screen Printing - The Classic Craft </h2>
                    <p className="inkox-content-para">
                    The majority of our creations, over 90%, spring to life through screen printing. We’ve turned this traditional method into a fine art. Our skilled printers craft a stencil for each design, layering vibrant inks meticulously by hand. This technique isn’t just for tees; we can adorn uneven surfaces like can coolers, water bottles, and mugs, making it ideal for standout, colorful designs and specialty items, despite its need for a bit more prep and a minimum order.
                    </p>
                    <h2 className="inkox-content-h2"> Digital Printing - The Detail Dynamo </h2>
                    <p className="inkox-content-para">
                    When your design demands intricate details, digital printing is the way to go. This tech-savvy method transfers your image directly from digital to fabric with precision and ease, perfect for capturing every detail without the fuss of setups or minimum orders. Look for our “no-minimum” badge on select items, ensuring you get the best in detail without compromise.
                    </p>

                    <h2 className="inkox-content-h2"> Embroidery - Threaded with Style</h2>
                    <p className="inkox-content-para">
                    For a touch of tradition and texture, our embroidery service weaves your design into fabrics with vibrant threads, giving your garments a handcrafted, classy vibe. With minimal order requirements, it’s easy to outfit your team or group with a polished, professional look in no time.
                    </p>
                    <h2 className="inkox-content-h2"> Laser Engraving and Debossing - Make Your Mark</h2>
                    <p className="inkox-content-para">
                    Elevate your promotional products with a touch of luxury. Our laser engraving etches your design into the material, creating a distinguished look by either burning away the top layer or revealing a contrasting underlayer. Debossing, on the other hand, impresses your image into the material for a lasting impression. These premium methods are available on a select range of products, perfect for making your brand’s mark.
                    </p>
                    <h2 className="inkox-content-h2"> Your Creative Partner</h2>
                    <p className="inkox-content-para">
                    At INKOX, we believe in the magic of custom apparel and the powerful expression it enables. That’s why we make designing custom gear for your group not just easy, but a blast—with cool tools, endless inspiration, and unwavering support every step of the way. Let’s print something amazing together!
                    </p>
                </div>
            </div>
            <p className="passion-divider" style={{ borderBottom: "1px solid #aaa" }}></p>
            <div className="passion-browsebut" style={{textAlign:"center"}}>
            <Link className="inkox-primary-button" to={`/products`}>Browse All of Our Products</Link>
                <button className="inkox-primary-button">Start Designing in the Lab</button>
            </div>
            </div>
        </div>
        </LayoutWrapper>
    );
}

export default Passion;
