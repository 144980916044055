import {
  createSlice,
  current,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { asynchronouslyGetFromLocal } from "../../utils/helperFunctions";

import { getUser, postLogin } from "./thunk";

const thunks = [postLogin, getUser];

const initialState = {
  status: "idle",
  Login: {},
  userDetail: {},
  userEmail: "",
};

export const slice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    // setEmail: (state, email) => {
    //   state.userEmail = email;
    // },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(postLogin.fulfilled, (state, action) => {
      //   state.status = "idle";
      //   state.Login = action.payload;
      //   console.log(state);
      // })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.status = "idle";
        state.Login = action.payload; // Assuming the payload has a data property
        state.userEmail = ""; // Replace this with the actual property name for the user email
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.userDetail = action.payload;
      })
      .addMatcher(isPending(...thunks), (state) => {})
      .addMatcher(isFulfilled(postLogin), (state) => {})
      .addMatcher(isRejected(...thunks), (state, action) => {});
  },
});

// export action from slice
// export const { setEmail } = slice.actions;

// export const selectUser = (state) => state.login.userDetail;
export const selectUser = (state) => state.login.userDetail;
export const selectEmail = (state) => state.login.userEmail;

export default slice.reducer;
