import React from "react";
import DateTimeDisplay from "./DateTimeDisplay";

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <DateTimeDisplay value={days} type={"days"} isDanger={days <= 3} />
      <DateTimeDisplay value={hours} type={"hours"} isDanger={false} />
      <DateTimeDisplay value={minutes} type={"min"} isDanger={false} />
      <DateTimeDisplay value={seconds} type={"sec"} isDanger={false} />
    </div>
  );
};

export default ShowCounter;
