import React, { useState, useEffect } from 'react';
import './ProductColor.css'; // Import CSS file for styling
import SelectedCategoryFont from './SelectedCategoryFont';

const FontCategories = ({ onCategorySelect, onClose, handleCategoryChange, selectedCategory, onFontFamilyChange, text }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [fontCategories, setFontCategories] = useState([]);
  const [isFontSelectorOpen, setIsFontSelectorOpen] = useState(false);

  useEffect(() => {
    // Fetch the font categories from the provided API
    fetch("https://backend.inkox.com/api/fontCategories")
      .then((response) => response.json())
      .then((data) => {
        setFontCategories(data); // Set the categories in the state
      })
      .catch((error) => {
        console.error("Error fetching font categories:", error);
      });
  }, []);

  // Filter categories based on the search term
  const filteredCategories = fontCategories.filter(category =>
    category.name && category.name.toLowerCase().includes(searchTerm.toLowerCase()) // Check if category.name exists
  );

  const toggleFontSelector = () => {
    setIsFontSelectorOpen(!isFontSelectorOpen);
  };

  return (
    <div className="font-selector">
      {isFontSelectorOpen ? (
        <>
          <SelectedCategoryFont
            onFontFamilyChange={onFontFamilyChange}
            onClose={onClose}
            text={text}
            selectedCategory={selectedCategory}
          />
        </>
      ) : (
        <>
          <div className="search-container">
            <div className="search-box">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="#aaa" d="M23.7 22.3l-5.7-5.7c1.2-1.5 1.9-3.4 1.9-5.4 0-4.7-3.8-8.5-8.5-8.5S2.9 6.5 2.9 11.2 6.7 19.7 11.4 19.7c2 0 3.9-.7 5.4-1.9l5.7 5.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4zM11.4 17c-3.2 0-5.8-2.6-5.8-5.8s2.6-5.8 5.8-5.8 5.8 2.6 5.8 5.8-2.6 5.8-5.8 5.8z" />
              </svg>
              <input
                type="text"
                placeholder="Search Categories"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="font-search"
              />
            </div>
          </div>

          <div className='inner' style={{ marginTop: "10px" }}>
            {/* You can add a back button here if needed */}
          </div>

          <div className="clipart-categories">
            {filteredCategories.map(category => (
              <div
                key={category.id}
                className="clipart-category"
                onClick={() => {
                  handleCategoryChange(category.name);
                  toggleFontSelector();
                }}
              >
                {/* <img src={category.category_icon} alt={category.name} className="category-icon" /> */}
                <label>{category.name}</label>
              </div>
            ))}
          </div>

          <button className='button-to-text' style={{ marginTop: "10px", float: 'right' }} onClick={onClose}>Done</button>
        </>
      )}
    </div>
  );
};

export default FontCategories;
