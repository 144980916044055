import { Col, Form, Input, Row, message } from "antd";
import React, { useEffect } from "react";
import logo from "../../../assets/images/logo.png";
import bgImage from "../../../assets/images/offer-bnr.png";
import Container from "../../container/Container";
import SimpleButton from "../../simpleButton/SimpleButton";
import { Link, useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../../utils/helperFunctions";
import axios from "axios"; // Import axios
import Cookies from 'js-cookie';

const LoginForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();


  const removeCookie = () => {
    // Remove cookie named 'userToken'
    Cookies.remove('Email_guest');
};
  // Handle form submission
  const onFinish = async (values) => {
    console.log('values : ', values);
    try {
      const response = await axios.post("https://backend.inkox.com/api/login", {
        email: values.email,
        password: values.password // Plain-text password sent to the server
      });
      console.log('Response : ', response);
      const { data } = response;
      if (data?.status) {
        message.success(data.message);
        
        // Save token to local storage if provided
        removeCookie();
        localStorage.setItem("Id", data.id);
        localStorage.setItem("Name", data.name);
        localStorage.setItem("APP_TOKEN", data.pwd);
        navigate("/");
      } else {
        message.error(data?.message || "Invalid email or password");
      }
    } catch (error) {
      console.error("Login error:", error);
      message.error("Login failed. Please try again.");
    }
  };
  
  

  // Handle form submission failure
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Check if the user is already logged in (has a token)
  useEffect(() => {
    const token = getFromLocalStorage("APP_TOKEN");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="loginForm">
      <div className="left-content">
        <Container>
          <img src={logo} alt="logo" className="logo" />
          <Row justify={"center"}>
            <Col md={16}>
              <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  className="password"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Link className="create-account-btn" to={"/signup"}>
                  Create an account
                </Link>
                <SimpleButton
                  customClass={"fw-700"}
                  size={"small"}
                  bgColor={"yellow"}
                  color={"black"}
                  htmlType="submit" // Make sure the button submits the form
                >
                  Login
                </SimpleButton>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="right-content">
        <img src={bgImage} alt="bg-image" />
      </div>
    </div>
  );
};

export default LoginForm;
