// import storage from "redux-persist/lib/storage";
import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";

import loginReducer from "../redux_apis/redux_login/slice";
import productCategoriesReducer from "../redux_apis/redux_categories/slice";
import productsReducer from "../redux_apis/redux_products/slice";
// import layoutReducer from "../redux_apis/redux_auth/slice";
// import workReducer from "../redux_apis/redux_work/slice";
// import perspectiveReducer from "../redux_apis/redux_perspectives/slice";
// import perspectiveDetailReducer from "../redux_apis/redux_ind_pers/slice";
// import workDetailReducer from "../redux_apis/redux_ind_work/slice";
// import homeReducer from "../redux_apis/redux_home/slice";
// import studioReducer from "../redux_apis/redux_studio/slice";
// import contactReducer from "../redux_apis/redux_contact/slice";
// import jobReducer from "../redux_apis/redux_job/slice";
// import dashboardReducer from "../redux_apis/redux_dashboard/slice";

const reducers = combineReducers({
  login: loginReducer,
  categories: productCategoriesReducer,
  products: productsReducer,
  // layout: layoutReducer,
  // work: workReducer,
  // perspective: perspectiveReducer,
  // perspectiveDetail: perspectiveDetailReducer,
  // workDetail: workDetailReducer,
  // home: homeReducer,
  // studio: studioReducer,
  // contact: contactReducer,
  // job: jobReducer,
  // dashboard: dashboardReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware({
    // serializableCheck: {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
    serializableCheck: false,
  }),
});

export let persisStore = store;
