import React, { Fragment } from "react";
import Title from "../../components/typography/title/Title";
import { Rating } from "react-simple-star-rating";
import { Col, Row } from "antd";
import checkSign from "../../assets/images/check-sign.png";

const Reviews = ({ data }) => {
  return (
    <div className="reviews" id="review">
      <div className="heading-container">
        <Title size={"41"} tag={"h2"} weight={700}>
          Review
        </Title>
        <Title size={"21"} tag={"h6"} weight={500}>
          Gildan Ultra Cotton Long Sleeve T-shirt
        </Title>
        <div className="review-row">
          <Rating emptyColor="#D1CECE" size={30} readonly initialValue={4} />
          <Title size={"18"} tag={"h6"} weight={600}>
            548 reviews
          </Title>
        </div>
      </div>
      <div className="border-container">
        <div className="reviews-container">
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <div className="review-box">
                  <Row>
                    <Col xs={24} md={6}>
                      <div className="flex-container">
                        <Title size={"26"} tag={"h5"} weight={500}>
                          {item.user}
                        </Title>
                        <Title
                          customClass={"time d-mobile"}
                          size={"26"}
                          tag={"h5"}
                          weight={500}
                        >
                          {item.time}
                        </Title>
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Rating
                        emptyColor="#D1CECE"
                        size={30}
                        readonly
                        initialValue={item.rating}
                      />
                    </Col>
                    <Col xs={12} md={14}>
                      <div
                        className="flex-container"
                        style={{ justifyContent: "flex-end" }}
                      >
                        {item.verified ? (
                          <div className="verified-box">
                            <Title size={"26"} tag={"h5"} weight={500}>
                              Verified Buyer
                            </Title>
                            <img
                              src={checkSign}
                              alt="checkSign"
                              className="checkSign"
                            />
                          </div>
                        ) : (
                          <div className="verified-box">
                            <Title
                              customClass={"unverified-title"}
                              size={"26"}
                              tag={"h5"}
                              weight={500}
                            >
                              Unverified
                            </Title>
                          </div>
                        )}
                        <Title
                          customClass={"time d-desktop"}
                          size={"26"}
                          tag={"h5"}
                          weight={500}
                        >
                          {item.time}
                        </Title>
                      </div>
                    </Col>
                    <Col md={24}>
                      <Title
                        customClass={"review"}
                        size={"22"}
                        tag={"h5"}
                        weight={600}
                      >
                        {item.review}
                      </Title>
                    </Col>
                  </Row>
                </div>
                {item.replyes.length > 0 &&
                  item.replyes.map((reply, i) => {
                    return (
                      <div className="reply-box">
                        <Row>
                          <Col xs={24} md={8}>
                            <div className="flex-container">
                              <Title size={"26"} tag={"h5"} weight={500}>
                                {reply.user}
                              </Title>
                              <Title
                                customClass={"time d-mobile"}
                                size={"26"}
                                tag={"h5"}
                                weight={500}
                              >
                                {reply.time}
                              </Title>
                            </div>
                          </Col>
                          <Col xs={24} md={16}>
                            <div className="flex-container">
                              <Title size={"26"} tag={"h5"} weight={500}>
                                {reply.department}
                              </Title>
                              <Title
                                customClass={"time d-desktop"}
                                size={"26"}
                                tag={"h5"}
                                weight={500}
                              >
                                {reply.time}
                              </Title>
                            </div>
                          </Col>
                          <Col md={24}>
                            <Title
                              customClass={"review"}
                              size={"22"}
                              tag={"h5"}
                              weight={600}
                            >
                              {reply.reply}
                            </Title>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
