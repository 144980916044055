import React, {useState} from "react";
import "./solution.css"; // Importing the updated CSS
import Image from './assets/image.jpg';
import Breadcrumb from "./Breadcrumb.js";
import Sidebar from "./Sidebar.js";
import "./App.css";
import LayoutWrapper from "../../layout/layoutWrapper/LayoutWrapper.jsx";
import { Link } from "react-router-dom";
function Solution() {
    const [openSections, setOpenSections] = useState(["about", "agreement", "policy", "solution"]);

    const toggleSection = (section) => {
      setOpenSections((prevOpenSections) => {
        if (prevOpenSections.includes(section)) {
          return prevOpenSections.filter((s) => s !== section);
        } else {
          return [...prevOpenSections, section];
        }
      });
    };
  
    const renderIcon = (isOpen) => (
      <svg
        className="mui-icon"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ExpandMoreIcon"
        style={{
          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
        }}
      >
        <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </svg>
    );
  
    return (
        <LayoutWrapper>
        <div className="main-div-other-pages">
              <div className="container">
            <Breadcrumb />
            <div className="inkox-container">
            <Sidebar
              openSections={openSections}
              toggleSection={toggleSection}
              renderIcon={renderIcon}
            />
                {/* Main Content */}
                <div className="inkox-main-content">
                    <h1 className="inkox-content-h1">INKOX Business Solutions Terms and Conditions</h1>
                    <h2 className="inkox-content-h2"> Effective Date: 5/10/23</h2>

                    <h3 className="inkox-content-h3"> Introduction </h3>
                    <p className="inkox-content-para">
                    These INKOX Business Solutions Terms and Conditions ("Business Solutions Terms") establish a legal agreement between INKOX, LLC ("INKOX") and the individuals or entities using INKOX's online platform (the "Site"), purchasing products, and utilizing the services offered by INKOX (collectively referred to as "Services"). Throughout this document, you, the user of these Services, will be referred to as "You," "Your," or "Client." By engaging with the Site, purchasing products, or using the Services, You agree to be bound by these Business Solutions Terms, along with the User Agreement & Policies, Privacy Policy, INKOX Content Standards, and all other applicable rules on the Site (collectively referred to as the "Terms & Conditions"). These documents together form a binding and enforceable agreement (the "Agreement"). You agree to use the Services strictly in accordance with this Agreement.
                    </p>

                    {/* <div className="inkox-image-section">
                        <img
                            src={Image}
                            alt="Customer Support"
                            className="inkox-support-image"
                        />
                    </div> */}
                    <h2 className="inkox-content-h2"> Parties to the Agreement </h2>
                    <p className="inkox-content-para">You and INKOX are each a "Party" and collectively referred to as the "Parties."</p>
                    
                    <h2 className="inkox-content-h2">Nature of Services</h2>
                    <ol style={{lineHeight: "23px"}}>
                        <li className="inkox-lab"><strong>Product Printing:</strong> INKOX will handle the printing and distribution of products customized with your provided or created content, including designs, layouts, artwork, taglines, trademarks, logos, and other assets ("Client Content"). All Client Content must comply with the INKOX Content Standards.</li>
                        <br/>
                        <li className="inkox-lab"><strong>Design Services:</strong>Upon request, INKOX will provide design services and may create materials for such services ("Design Service Materials"), which may be included on the customized products alongside or without Client Content.</li>
                        <br/>
                        <li className="inkox-lab"><strong>Online Store Hosting, Inventory, and Fulfillment: </strong> If You opt to operate an online store, INKOX will host this store on the Site and include your selected customized products ("Store"). INKOX will manage all customer orders through the Store, accepting orders from Client's designated contact on a rolling basis ("Client Orders"). All returns and cancellations will adhere to the Terms & Conditions.</li>
                        <br/>
                        <li className="inkox-lab"><strong>Inventory Management: </strong> Should You request inventory management services, INKOX may hold your purchased customized products in inventory before delivery, subject to additional charges and the terms of the Agreement. You are liable for all associated costs and risks once these products are delivered to the carrier.</li>
                    </ol>

                    <h2 className="inkox-content-h2"> Promo Code Voucher:</h2>
                    <p className="inkox-content-para"> Occasionally, You may offer promotional codes or vouchers allowing discounts on products through the Store. The terms of these discounts will be mutually agreed upon, with the understanding that You are responsible for subsidizing these discounts. INKOX will collect payment from You for these applied discounts on all orders.</p>

                    <h2 className="inkox-content-h2">Client Responsibilities</h2>
                    <ol style={{lineHeight: "23px"}}>
                        <li className="inkox-lab"><strong>Account Registration:</strong> To use the Services, You must register an account on the Site ("Account") and provide all necessary information, including your full legal name and valid email address. INKOX reserves the right to reject or cancel any account at its discretion.</li>
                        <br/>
                        <li className="inkox-lab"><strong>Design: </strong> You may create or arrange Client Content to be incorporated into the customized products. If INKOX provides Design Service Materials, you may integrate certain Client Content.</li>
                        <br/>
                        <li className="inkox-lab"><strong>License: </strong> You grant INKOX a non-exclusive license to use the Client Content and your trademarks or logos in connection with the production and promotion of the customized products. Any third-party content included must be properly licensed by You.</li>
                        <br/>
                        <li className="inkox-lab"><strong>Payment: </strong> Payment for products and services will be collected as per the arrangements specified in any applicable Order Form. INKOX may offer credit terms at its discretion.</li>

                    </ol>

                    <h2 className="inkox-content-h2"> Representations and Warranties  </h2>
                    <p className="inkox-content-para">Each Party guarantees its authority to enter this Agreement and asserts that its obligations hereunder do not conflict with any other obligations. You specifically warrant that the Client Content does not violate any laws or third-party rights.</p>
                    <h2 className="inkox-content-h2"> Indemnification </h2>
                    <p className="inkox-content-para">Each Party agrees to defend, indemnify, and hold harmless the other from any claims arising from breaches of warranties or misrepresentations.</p>
                    <h2 className="inkox-content-h2"> Limitation of Liability </h2>
                    <p className="inkox-content-para"> Neither Party will be liable for consequential losses. INKOX's liability is capped at the amount retained from sales over a twelve-month period.</p>
                    <h2 className="inkox-content-h2"> Ownership </h2>
                    <p className="inkox-content-para">You retain all rights to Client Content. INKOX owns all rights to the Site, Store, and any materials it creates or provides, including Design Service Materials.</p>
                    <h2 className="inkox-content-h2"> 
                        Term and Termination  
                    </h2>
                    <p className="inkox-content-para">
                        This Agreement remains effective until terminated by either Party. It may be terminated for convenience or due to breach, with conditions for remedy.
                    </p>

                    <h2 className="inkox-content-h2"> 
                    General Provisions   
                    </h2>
                    <p className="inkox-content-para">
                    This Agreement will be governed by Virginia law and subject to arbitration for disputes. The terms here represent the full agreement between the Parties, superseding any prior agreements.
                    </p>
                    <h2 className="inkox-content-h2"> 
                    Order of Precedence  
                    </h2>
                    <p className="inkox-content-para">
                    In case of inconsistencies among documents, the order of precedence will be: Business Solutions Terms, any Order Form, then other Terms & Conditions as applicable.
                    </p>
                    <h2 className="inkox-content-h2">
                    Conclusion   
                    </h2>
                    <p className="inkox-content-para">
                    By using INKOX's Services, registering for an account, or engaging in transactions on the Site, you acknowledge and agree to all terms set forth in this Agreement and the accompanying documents.
                    </p>
                </div>
            </div>
            <p className="inkox-divider">
            </p>
            <div className="inkox-browsebut">
            <Link className="inkox-primary-button" to={`/products`}>Browse All of Our Products</Link>
                <button className="inkox-primary-button">Start Designing in the Lab</button>
            </div>
            </div>
        </div>
        </LayoutWrapper>
    );
}

export default Solution;
