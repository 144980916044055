import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ openSections, toggleSection, renderIcon }) => {
  return (
    <div className="inkox-sidebar">
      <h3 className="inkox-sidebar-title">Help Center</h3>
      <div className="inkox-help-center">
        {/* Collapsible About INKOX */}
        <div className="main-otherpagesub">
          <div className="inkox-menu-item" onClick={() => toggleSection("about")}>
            About INKOX {renderIcon(openSections.includes("about"))}
          </div>
          {openSections.includes("about") && (
            <div className="inkox-submenu">
              <Link to="/about" className="inkox-submenu-item">Learn About Us</Link>
              <Link to="/explore" className="inkox-submenu-item">Explore the Fun at INKOX!</Link>
              <Link to="/passion" className="inkox-submenu-item">Inked with Passion at INKOX</Link>
              <Link to="/story" className="inkox-submenu-item">Learn Our Story</Link>
            </div>
          )}
        </div>

        {/* Collapsible Agreement */}
        <div className="main-otherpagesub">
          <div className="inkox-menu-item" onClick={() => toggleSection("agreement")}>
            User Agreement & Policies {renderIcon(openSections.includes("agreement"))}
          </div>
          {openSections.includes("agreement") && (
            <div className="inkox-submenu">
              <Link to="/agreement" className="inkox-submenu-item">Read the Agreement</Link>
            </div>
          )}
        </div>

        {/* Collapsible Policy */}
        <div className="main-otherpagesub">
          <div className="inkox-menu-item" onClick={() => toggleSection("policy")}>
            Privacy Policy {renderIcon(openSections.includes("policy"))}
          </div>
          {openSections.includes("policy") && (
            <div className="inkox-submenu">
              <Link to="/policy" className="inkox-submenu-item">View Privacy Policy</Link>
            </div>
          )}
        </div>

        {/* Collapsible Solution */}
        <div className="main-otherpagesub">
          <div className="inkox-menu-item" onClick={() => toggleSection("solution")}>
            INKOX Business Solutions {renderIcon(openSections.includes("solution"))}
          </div>
          {openSections.includes("solution") && (
            <div className="inkox-submenu">
              <Link to="/solution" className="inkox-submenu-item">Explore Solutions</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
