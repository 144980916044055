import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import Container from "../../components/container/Container";
import SimpleCard from "../../components/simpleCard/SimpleCard";
import Title from "../../components/typography/title/Title";

const TrendingProducts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`https://backend.inkox.com/api/product-categories`);
        if (!response.ok) {
          throw new Error("Failed to fetch categories");
        }
        const categories = await response.json();
        const trendingCategories = categories.filter((category) => category.trending_category === 1);

        const mappedCategories = trendingCategories.map((category) => ({
          image: `https://backend.inkox.com/storage/${category.icon_path}`,
          subTitle: "NEW ARRIVALS",
          title: category.name,
          link: `products/category/${category.id}`, // Create the link for navigation
        }));

        setData(mappedCategories);
      } catch (error) {
        setError(error.message); // Set the error message
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchCategories();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Show error message
  }

  return (
    <div className="trendingProducts">
      <Container>
        <Title customClass={"text-center"} size={"41"} tag={"h2"} weight={700}>
          Shop Our Most Loved Categories
        </Title>
        <Title customClass={"sub-title"} size={"21"} tag={"h2"} weight={500}>
        Dive into the collections that keep our customers coming back for more  </Title>
        
        <Row gutter={[20, 0]}>
          {data.map((item, i) => (
            <Col xs={12} lg={6} md={12} xl={5} sm={12} key={i} className="column-homepage-product">
              <SimpleCard cardType={"categoryCard"} data={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default TrendingProducts;
