import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFromLocalStorage,
  setInLocalStorage,
} from "../../utils/helperFunctions";
import {
  postLogin as postLoginAPI,
  postUpdate as postUpdateAPI,
  postChangePassword as postChangePasswordAPI,
  postResetPassword as postResetPasswordAPI,
  getUser as getUserDetailAPI,
  postSignUp as postSignUpAPI,
} from "./service";

export const postLogin = createAsyncThunk("post/login", async (payload) => {
  // const token = getFromLocalStorage("APP_TOKEN");
  // const params = {
  //   headers: {
  //     Authorization: token,
  //   },
  // };
  // const response = await postLoginAPI(payload, params);
  const response = await postLoginAPI(payload);
  const { data } = response;
  if (data && data.token) {
    const token = data.token;
    setInLocalStorage("APP_TOKEN", token);
  }
  return response;
});

export const postSignUp = createAsyncThunk("post/signup", async (payload) => {
  // const token = getFromLocalStorage("APP_TOKEN");
  // const params = {
  //   headers: {
  //     Authorization: token,
  //   },
  // };
  // const response = await postLoginAPI(payload, params);
  const response = await postSignUpAPI(payload);
  const { data } = response;
  if (data && data.token) {
    const token = data.token;
    setInLocalStorage("APP_TOKEN", token);
  }
  return response;
});

export const getUser = createAsyncThunk("get/userDetail", async (payload) => {
  const token = getFromLocalStorage("APP_TOKEN");
  const params = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await getUserDetailAPI(payload, params);
  return response;
});

export const postUpdate = createAsyncThunk(
  "post/updatePassword",
  async (payload) => {
    const token = getFromLocalStorage("APP_TOKEN");
    const user = getFromLocalStorage("USER_ID");
    const params = {
      headers: {
        Authorization: token,
        "user-id": user,
      },
    };
    const response = await postUpdateAPI(payload, params);
    return response.data;
  }
);

export const postChangePassword = createAsyncThunk(
  "post/changePassword",
  async (payload) => {
    const token = getFromLocalStorage("APP_TOKEN");
    const params = {
      headers: {
        Authorization: token,
      },
    };
    const response = await postChangePasswordAPI(payload, params);
    return response.data;
  }
);

export const postResetPassword = createAsyncThunk(
  "post/resetPassword",
  async (payload) => {
    const token = getFromLocalStorage("APP_TOKEN");
    const params = {
      headers: {
        Authorization: token,
      },
    };
    const response = await postResetPasswordAPI(payload, params);
    return response.data;
  }
);
