import React from "react";
import Slider from "react-slick";
import banner1 from "../../assets/images/bannerimg.png";
import banner2 from "../../assets/images/bannerimg.png";
import banner3 from "../../assets/images/bannerimgold.png";
import girlimage from  "../../assets/images/12.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    fade: true, // Enable fade transition
  };

  return (
    <div className="banner-new">
      {/* <Slider {...settings}>
        <div>
          <img src={banner1} alt="Banner 1" style={{ width: "100%", height: "auto" }} />
        </div>
        <div>
          <img src={banner2} alt="Banner 2" style={{ width: "100%", height: "auto" }} />
        </div>
        <div>
          <img src={banner3} alt="Banner 3" style={{ width: "100%", height: "auto" }} />
        </div>
      </Slider> */}

      <div className="bannermain-div">
        <div className="bannerinner-div container">

          <div className="col-md-inner-one">
            <div className="side-sectoion-banner">

            <h1>Design Your Perfect Look!</h1>
            <hr className="line-banner"></hr>
            <p>Bring your ideas to life with our online design tools. Personalize your favorite apparel today!</p>
           <a href="/designer?id=24&pid=73&colorId=700007"> <button>DESIGN NOW</button></a>
          </div>


          </div>

          <div className="col-md-inner-two">

<img src={girlimage}/>
</div>
       





        </div>
      </div>
    </div>
  );
};

export default Banner;
