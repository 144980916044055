import React from "react";
import Title from "../typography/title/Title";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { colorHexMap } from "../../view/colorlibrarygarments";
const SimpleCard = (props) => {
  const { data, cardType, colors, handleColorClick  } = props;
  // console.log(colors);
  // console.log(data);
  const slug = data?.link?.toLowerCase();
  return cardType === "filterCard" ? (
    <Link to={slug || "#"}>
      <div className={`simpleCard ${cardType && cardType}`}>
        <div className="card-image">
          {data.topSeller && (
            <span className="topSeller">
              <Title size={"10"} color={"white"} tag={"h6"} weight={700}>
                Top Seller
              </Title>
            </span>
          )}
          <img src={data?.image} alt="card-img" />
        </div>
        <div className="card-body">
          {data?.title && (
            <Title customClass={"view-btn"} size={"19"} tag={"h5"} weight={600}>
              {data?.title}
            </Title>
          )}   
            {colors && colors.length > 0 && (
            <div className="color-preview">
              {colors.map((color, index) => (
                <button
                  key={index}
                  className="color-swatch"
                  style={{
                    backgroundColor: colorHexMap[color]?.hex || "#ffffff", // Use colorHexMap for hex codes
                    border: "1px solid #ddd", // Optional styling
                  }}
                  title={`${color}`}
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent navigation bubbling up to the parent
                    event.preventDefault();  // Prevent the default <Link> behavior
                    handleColorClick(data.id, color);
                  }}
                  onMouseEnter={() => handleColorClick(data.id, color)} // Trigger on hover
	
                ></button>
              ))}
              </div>
              )}
          <div className="size-price-container">
            {data?.size && (
              <Title customClass={"size"} size={"14"} tag={"h6"} weight={600}>
                S - {data?.size}
              </Title>
            )}

         
            {data?.price && (
              <Title customClass={"size"} size={"14"} tag={"h6"} weight={600}>
                | {data?.price}
              </Title>
            )}
          </div>
          <div className="reviewsContainer">
            {data?.rating && (
              <Rating
                emptyColor="#D1CECE"
                size={22}
                readonly
                initialValue={data?.rating}
              />
            )}
            {data?.reviews && (
              <Title customClass={"size"} size={"14"} tag={"h6"} weight={600}>
                {data?.reviews} reviews
              </Title>
            )}
          </div>
        </div>
      </div>
    </Link>
  ) : cardType === "categoryCard" ? (
    <Link to={slug || "#"}>
      <div className={`simpleCard ${cardType && cardType}`}>
        <div className="card-image">
          {data?.image && <img src={data?.image} alt="card-img" />}
        </div>
        <div className="card-content">
          {data?.title && (
            <Title customClass={"view-btn"} size={"20"} tag={"h5"} weight={600}>
              {data?.title}
            </Title>
          )}
        </div>
      </div>
    </Link>
  ) : (
    <Link to={slug || "#"}>
      <div className="simpleCard">
        <div className="card-image">
          <img src={data?.image} alt="card-img" />
          {data?.title && (
            <Title customClass={"view-btn"} size={"17"} tag={"h5"} weight={700}>
              View Products
            </Title>
          )}
        </div>
        <div className="card-content">
          {data?.category && (
            <Title customClass={"category"} size={"17"} tag={"h5"} weight={700}>
              {data?.category}
            </Title>
          )}
          {data?.title && (
            <Title
              customClass={"card-title"}
              size={"17"}
              tag={"h5"}
              weight={700}
            >
              {data?.title}
            </Title>
          )}
          {(data?.startingPrice || data?.endingPrice) && (
            <Title customClass={"price"} size={"17"} tag={"h5"} weight={700}>
              ${data?.startingPrice}
               {/* - ${data?.endingPrice} */}
            </Title>
          )}
        </div>
      </div>
    </Link>
  );
};

export default SimpleCard;
