import React from "react";
import americanExpIcon from "../../assets/images/american-express.png";
import mastroIcon from "../../assets/images/maestro.png";
import masterIcon from "../../assets/images/master-card.png";
import paypalIcon from "../../assets/images/paypal.png";
import visaIcon from "../../assets/images/visa.png";
const PaymentIcons = ({ data }) => {
  return (
    <ul className="paymentIcons">
      <li>
        <img src={visaIcon} alt="visa-icon" />
      </li>
      <li>
        <img src={masterIcon} alt="master-card-icon" />
      </li>
      <li>
        <img src={mastroIcon} alt="maestro-icon" />
      </li>
      <li>
        <img src={americanExpIcon} alt="american-express-icon" />
      </li>
      <li>
        <img src={paypalIcon} alt="paypal-icon" />
      </li>
    </ul>
  );
};

export default PaymentIcons;
