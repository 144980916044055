import React from "react";
import Title from "../../components/typography/title/Title";
import chat from "../../assets/images/chat.png";
import headphone from "../../assets/images/head.png";

const ChatTalk = ({ size }) => {
  return (
    <div className={`chatTalk ${size && size}`}>
      <div className="wayOfContact">
        <div className="box">
          <img src={headphone} alt="headphone" />
          <div className="text-box">
            {/*<Title tag={"h6"} size={"12"} weight={400}>
              TALK TO A REAL PERSON
            </Title>*/}
            <Title tag={"h6"} color={"red"} size={"16"} weight={600}>
              +1 0000 0000
            </Title>
          </div>
        </div>
        <div className="box">
          <img src={chat} alt="chat" />
          <div className="text-box">
  {/*          <Title tag={"h6"} size={"12"} weight={400}>
              CHAT WITH A REAL PERSON
            </Title>*/}
            <Title tag={"h6"} color={"red"} size={"16"} weight={600}>
              CHAT NOW
            </Title>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatTalk;
