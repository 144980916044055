import { message } from "antd";
import axios from "axios";
import { interceptor } from "./interceptor";

const instance = axios.create({
  baseURL: "https://printqfly-fe.techkoder.com/admin/printqfly/public/api",
  timeout: 60000,
  // withCredentials: true,
  headers: {},
});
// Changed the admin base URL to panel URL

//call axios interceptor
interceptor(instance);

// axios.defaults.headers.common = { Authorization: `bearer ${token}` };
const request = async ({ method, url, data, headers, skipAuth }) => {
  const params = {
    headers: { ...headers },
  };

  if (method === "delete") {
    data = { data };
  }

  if (method === "get") {
    data = params;
  }

  const promise = instance[method](url, data, params);

  try {
    const response = await promise;
    const payload = response.data;
    if (headers) {
      return {
        data: payload,
        headers: response.headers,
      };
    }
    return payload;
  } catch (err) {
    console.log(err);
    // message.error(err?.message);
  }
};

export const get = (url, params) => request({ method: "get", url, ...params });
export const post = (url, data, params) =>
  request({ method: "post", url, data, ...params });
export const put = (url, data, params) =>
  request({ method: "put", url, data, ...params });
export const del = (url, data) => request({ method: "delete", url, data });
export const patch = (url, data, params) =>
  request({ method: "patch", url, data, ...params });
